import { nopeResolver } from '@hookform/resolvers/nope';
import { Button, DialogTitle, Grid, InputAdornment, SvgIcon, TextField, Typography } from '@mui/material';
import api from 'api';
import { useContextRedirection, useNotification } from 'hooks';
import { EmailUpdateProps } from 'models/profile.model';
import Nope from 'nope-validator';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ReactComponent as IconEmail } from 'resources/icons/messagerie.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeLeftDrawer } from 'store/actions/navMenu.actions';
import { clearAllPrescriptionProfile } from 'store/actions/prescriptionProfile.actions';
import { logoutUser } from 'store/actions/session.actions';
import RoutePaths from 'utils/RoutePaths';

enum EmailError {
  emailUsed = 'the_email_is_already_used',
}

const EmailUpdate: FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const { t } = useTranslation();

  const UpdateEmailSchema = Nope.object().shape({
    email: Nope.string().email('security.validation.email.default').required('security.validation.email.required'),
    emailConfirmation: Nope.string()
      .oneOf([Nope.ref('email')])
      .required(),
  });

  const { register, handleSubmit, formState } = useForm<EmailUpdateProps>({
    resolver: nopeResolver(UpdateEmailSchema),
  });
  const { notification } = useNotification();
  const dispatch = useDispatch();
  const navigate = useContextRedirection();

  const onSubmit: SubmitHandler<EmailUpdateProps> = useCallback(
    (data) => {
      api.users
        .updateEmail(data)
        .then(() => {
          closeModal();
          notification(t('profile.updateEmail.notification.success'), 'success');
          dispatch(closeLeftDrawer());
          dispatch(clearAllPrescriptionProfile());
          dispatch(logoutUser());
          navigate(RoutePaths['LANDING_PAGE']);
        })
        .catch((e) => {
          const error = e?.response?.data?.message;
          const errorCode = error ? (error.toLowerCase().replaceAll(' ', '_') as EmailError) : undefined;

          if (errorCode === EmailError.emailUsed) {
            notification(t('profile.updateEmail.notification.email_used_error'), 'error');
          }
        });
    },
    [closeModal, navigate, dispatch, notification, t],
  );

  return (
    <>
      <DialogTitle sx={{ p: 0, pb: 1 }}>{t('profile.update.actions.modify.email')}</DialogTitle>
      <Typography pb={1} variant='body2'>
        {t('profile.update.actions.confirm.email')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0} flexDirection='column'>
          <Grid item xs={12} py={1}>
            <TextField
              variant='filled'
              fullWidth
              {...register('email')}
              error={formState.errors?.email !== undefined}
              label={t('profile.updateEmail.form.newEmail')}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SvgIcon component={IconEmail} sx={{ fontSize: 24 }} />
                  </InputAdornment>
                ),
                autoComplete: 'email',
              }}
            />
          </Grid>
          <Grid item xs={12} py={1}>
            <TextField
              variant='filled'
              fullWidth
              {...register('emailConfirmation')}
              error={formState.errors?.emailConfirmation !== undefined}
              label={t('profile.updateEmail.form.confirmNewEmail')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SvgIcon component={IconEmail} sx={{ fontSize: 24 }} />
                  </InputAdornment>
                ),
                autoComplete: 'email',
              }}
              required
            />
          </Grid>
          <Grid item xs={12} mt={2} textAlign='right'>
            <Button onClick={closeModal} sx={{ mr: 1 }}>
              {t('common.action.cancel')}
            </Button>
            <Button type='submit'> {t('profile.update.actions.save.email')}</Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EmailUpdate;
