export enum AppointmentItemKind {
  Center = 'CENTER',
  PatienAddress = 'PATIENT_ADDRESS',
}
type AppointmentItemBase = {
  id: number;
  date: string;
  patientsNames: string[];
  appointment_kind: AppointmentItemKind;
};

type AppointmentItemCenter = AppointmentItemBase & {
  appointment_kind: AppointmentItemKind.Center;
  address_center: {
    name: string;
    address: string;
  };
};

export type AppointmentItemPatientAddress = AppointmentItemBase & {
  appointment_kind: AppointmentItemKind.PatienAddress;
  address: {
    number: string;
    street: string;
    zip_code: string;
    city_name: string;
    country_iso: string;
    type: 'home' | 'work';
  };
};

export type AppointmentItem = AppointmentItemCenter | AppointmentItemPatientAddress;

/**
 * @deprecated Use AppointmentItem instead
 */
export type AppointmentItem2023 = {
  id: number;
  patientsNames: string[];
  address: string;
  date: string;
};
