export enum SortKey {
  samplingTime = 'sampling_time',
  doctor = 'doctor',
}
export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

export type SortState = { key: SortKey; direction: SortDirection };
