import { Box, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import { useIsMobileView, useNotification } from 'hooks';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import DesktopResults from './components/DesktopResults';
import MobileResults from './components/MobileResults';
import { useTranslation } from 'react-i18next';
import { FilterResultsFormValues, ResultNew, ResultStatus } from 'models/result.model';
import ResultStatusTabs from './components/ResultStatusTabs';
import ResultsFilterDrawer from './components/ResultsFilterDrawer';
import { defaultValues, getFilterDateValues } from './utils/filters';
import ConfirmDialog from 'components/confirm-dialog';
import ResultsFilterBar from './components/ResultsFilterBar';
import useHideRedesign from 'hooks/useHideRedesign';
import { OfficeMedicalStaff, PatientTag } from 'models/record.model';
import { useSearchParams } from 'react-router-dom';
import TagDialog from './components/TagDialog';
import { SortDirection, SortKey, SortState } from './types';

const Results: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchStatus = searchParams.get('status') as ResultStatus | null;

  const isMobileView = useIsMobileView();
  const { hideRedesign, toggleRedesign: toggleHideRedesign } = useHideRedesign();
  const [results, setResults] = useState<any[]>([]);
  const [tags, setTags] = useState<PatientTag[]>([]);
  const [doctors, setDoctors] = useState<OfficeMedicalStaff[]>([]);
  const [refetchCounter, setRefetchCounter] = useState<number>(0);
  const [procResultsCount, setProcResultsCount] = useState<number>(0);
  const [compResultsCount, setCompResultsCount] = useState<number>(0);

  const [open, setOpen] = useState<boolean>(false);
  const [addedTagResult, setAddedTagResult] = useState<ResultNew | null>(null);
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(true);
  const [sort, setSort] = useState<SortState>({ key: SortKey.samplingTime, direction: SortDirection.desc });
  const [selectedTab, setSelectedTab] = useState<ResultStatus>(searchStatus || ResultStatus.partial);
  const [filterData, setFilterData] = useState<FilterResultsFormValues | null>(null);
  const paginatorData: GenericPaginator = {
    total: 0,
    size: 5,
    page: 1,
  };
  const [paginator, setPaginator] = useState(paginatorData);
  const { t } = useTranslation();
  const { notification } = useNotification();
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const [isLoading, setLoading] = useState(true);
  const [isTagsLoading, setTagsLoading] = useState(true);

  useEffect(() => {
    setTagsLoading(true);

    Promise.all([api.results.getTags(), api.results.getDoctors()])
      .then(([tags, doctors]) => {
        setTags(tags);
        setDoctors(doctors);
      })
      .finally(() => setTagsLoading(false));
  }, [refetchCounter]);

  useEffect(() => {
    setLoading(true);

    const { date, datesRangeFrom, datesRangeTo, doctors: doctorIds, tags: tagIds } = filterData || defaultValues;
    const dateFilters = date && getFilterDateValues(date, datesRangeFrom, datesRangeTo);
    const sortString = `${sort.key}.${sort.direction}`;

    currentUser &&
      api.results
        .getResults({ size: paginator.size, page: paginator.page, patientId: currentUser.id, status: !hideRedesign ? selectedTab : undefined, doctorIds, tagIds, sort: sortString, ...dateFilters })
        .then((res) => {
          // setCompResultsCount(0);
          // setProcResultsCount(0);

          setResults(res.results);
          setPaginator({
            total: res.total,
            size: res.size,
            page: res.page,
          });
          setLoading(false);
        })
        .catch((err) => {
          notification(t('homepage.results.notification.error'), 'error');
        });
  }, [notification, paginator.size, paginator.page, t, currentUser, filterData, selectedTab, refetchCounter, isMobileView, hideRedesign, defaultValues, sort]);

  const handlePaginationChange = (pagination: GenericPaginator) => {
    setPaginator(pagination);
  };

  const handleChangeTab = (tab: ResultStatus) => {
    setSelectedTab(tab);
    setSearchParams({ status: tab });
    setFilterData(null);
  };
  const handleSort = (key: SortKey) => {
    if (key === SortKey.samplingTime) {
      setSort((prev) =>
        prev.key === SortKey.samplingTime
          ? { ...prev, direction: prev.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc }
          : { key: SortKey.samplingTime, direction: SortDirection.desc },
      );
      return;
    }
    if (key === SortKey.doctor) {
      setSort((prev) =>
        prev.key === SortKey.doctor ? { ...prev, direction: prev.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc } : { key: SortKey.doctor, direction: SortDirection.asc },
      );
      return;
    }
  };

  const resultsBody = () => (
    <div style={{ padding: hideRedesign ? 0 : '24px' }}>
      <Box display='flex' alignItems='flex-start' justifyContent='space-between'>
        {!hideRedesign && (
          <Typography variant='h2' fontWeight='600'>
            {t('common.results')}
          </Typography>
        )}
        <FormControlLabel
          control={<Switch sx={{ m: 1 }} />}
          checked={hideRedesign === null ? true : !hideRedesign}
          sx={{ flexDirection: 'row-reverse', mb: 2 }}
          onChange={(e, checked) => toggleHideRedesign(!checked)}
          label={
            <Typography variant='body1' fontWeight='500' mr={1}>
              {t('result_doctor.enable_reports')}
            </Typography>
          }
        />
      </Box>
      <Grid container justifyContent='center'>
        {!isMobileView && !hideRedesign && (
          <ResultStatusTabs selectedTab={selectedTab} setSelectedTab={handleChangeTab} results={results} processResultsCounter={procResultsCount} completeResultsCounter={compResultsCount} />
        )}
        {selectedTab === ResultStatus.complete && !isMobileView && !hideRedesign && (
          <ResultsFilterBar
            open={open}
            setOpen={setOpen}
            filterData={filterData}
            sortFromNewToOld={sort.key === SortKey.samplingTime && sort.direction === SortDirection.desc}
            setSortFromNewToOld={() =>
              setSort((prev) =>
                prev.key === SortKey.samplingTime
                  ? { ...prev, direction: prev.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc }
                  : { key: SortKey.samplingTime, direction: SortDirection.desc },
              )
            }
            setFilterData={(data) => setFilterData(data)}
            doctors={doctors}
            tags={tags}
          />
        )}
        <Grid item xs={12}>
          {!isMobileView ? (
            <DesktopResults
              onSort={handleSort}
              setAddedTagResult={setAddedTagResult}
              hideRedesign={!!hideRedesign}
              results={results}
              title={t('result_doctor.table_title')}
              selectedStatus={selectedTab}
              pagination={paginator}
              isLoading={isLoading || isTagsLoading}
              onPaginationChange={handlePaginationChange}
            />
          ) : (
            <MobileResults results={results} isLoading={isLoading || isTagsLoading} />
          )}
        </Grid>
      </Grid>
      {open && (
        <ResultsFilterDrawer
          tags={tags}
          doctors={doctors}
          results={results}
          open={open}
          onClose={() => setOpen(false)}
          onApply={(data) => {
            setFilterData(data);
            setOpen(false);
          }}
          defaultFilters={filterData || defaultValues}
          onClear={() => {
            setFilterData(null);
          }}
        />
      )}
      {hideRedesign === null && (
        <ConfirmDialog
          hideCancel
          title={t('result_doctor.new_screen_dialog.title')}
          subtitle={t('result_doctor.new_screen_dialog.description')}
          submitTitle={t('common.action.understood')}
          open={openInfoModal}
          onClose={() => {
            setOpenInfoModal(false);
            toggleHideRedesign(false);
          }}
          onSubmit={() => toggleHideRedesign(false)}
        />
      )}
      {addedTagResult && (
        <TagDialog
          open
          onClose={() => setAddedTagResult(null)}
          onSubmit={() => {
            setAddedTagResult(null);
            setRefetchCounter((prev) => prev + 1);
          }}
          result={addedTagResult}
          tags={tags}
        />
      )}
    </div>
  );

  if (hideRedesign) {
    return <BodyTemplate title={t('common.results')}>{resultsBody()}</BodyTemplate>;
  }

  return resultsBody();
};

export default Results;
