import theme, { redesignColors } from 'resources/theme/theme.colors';

export const resultTagBackgrounds = [
  '#F2FAFD',
  '#FECDD2',
  '#F8BBD0',
  '#E1BEE7',
  '#D1C4E9',
  '#C5CAE9',

  '#BBDEFB',
  '#B2EBF2',
  '#C8E6C9',
  '#FFF9C4',
  '#FFE0B2',
  '#FFCCBC',

  '#7A9099',
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',

  '#2196F3',
  '#00BCD4',
  '#4CAF50',
  '#FFEB3B',
  '#FF9800',
  '#FF5722',
];

export const resultTagColors: Record<string, string> = {
  '#F2FAFD': redesignColors.dark,
  '#FECDD2': redesignColors.dark,
  '#F8BBD0': redesignColors.dark,
  '#E1BEE7': redesignColors.dark,
  '#D1C4E9': redesignColors.dark,
  '#C5CAE9': redesignColors.dark,

  '#BBDEFB': redesignColors.dark,
  '#B2EBF2': redesignColors.dark,
  '#C8E6C9': redesignColors.dark,
  '#FFF9C4': redesignColors.dark,
  '#FFE0B2': redesignColors.dark,
  '#FFCCBC': redesignColors.dark,

  '#7A9099': theme.palette.common.white,
  '#F44336': theme.palette.common.white,
  '#E91E63': theme.palette.common.white,
  '#9C27B0': theme.palette.common.white,
  '#673AB7': theme.palette.common.white,
  '#3F51B5': theme.palette.common.white,

  '#2196F3': theme.palette.common.white,
  '#00BCD4': theme.palette.common.white,
  '#4CAF50': theme.palette.common.white,
  '#FFEB3B': theme.palette.common.white,
  '#FF9800': theme.palette.common.white,
  '#FF5722': theme.palette.common.white,
};
export const resultStatusColors = {
  partial: redesignColors.orange,
  complete: redesignColors.green.dark,
};
