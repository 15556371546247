import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, List, Pagination, Typography } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { Center, CenterIncludedFile, CenterIncludedService, CenterIncludedUpdate } from 'models/centers.model';
import { FC, useState } from 'react';
import CenterDetails from './CenterDetails';
import { useTranslation } from 'react-i18next';
import { getStatus, getStatusDescription } from '../utils';
import CenterListItem from './CenterListItem';
import { Holiday } from 'models/holiday.model';

interface Props {
  centersInfo: {
    center: Center;
    file: CenterIncludedFile;
    services: CenterIncludedService[];
    updates: CenterIncludedUpdate[];
  }[];
  handleOpenMapMarker?: (index: number) => void;
  holidays: Holiday[];
}

const CentersList: FC<Props> = ({ centersInfo, handleOpenMapMarker, holidays }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  return (
    <>
      {!isMobileView && (
        <List sx={{ pt: 0, height: 474, overflowX: 'auto' }}>
          {centersInfo.map((info, i) => {
            const status = getStatus(info.center, holidays);
            const statusDescription = getStatusDescription(status, info.center.attributes.field_prelevement_horaires, t);

            return (
              <CenterListItem
                key={`center-${i}`}
                sxTitle={{ paddingRight: 2 }}
                subtitle={`${info.center.attributes.field_prelevement_numero_rue}, ${info.center.attributes.field_prelevement_rue} ${info.center.attributes.field_prelevement_code_postal} ${info.center.attributes.field_prelevement_ville}`}
                title={info.center.attributes.title}
                onClick={handleOpenMapMarker ? () => handleOpenMapMarker((page - 1) * 5 + i) : undefined}
                status={status}
                statusDescription={statusDescription}
              />
            );
          })}
        </List>
      )}
      {isMobileView &&
        centersInfo.slice((page - 1) * 5, (page - 1) * 5 + 5).map((info, i) => {
          return (
            <Accordion key={`center-${i}`}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant='h2'>{info.center.attributes.title}</Typography>
                  <Typography variant='body1'>
                    {`${info.center.attributes.field_prelevement_numero_rue}, ${info.center.attributes.field_prelevement_rue} ${info.center.attributes.field_prelevement_code_postal} ${info.center.attributes.field_prelevement_ville}`}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ mt: 0 }}>
                <CenterDetails centerInfo={info} hideHeader holidays={holidays} />
              </AccordionDetails>
            </Accordion>
          );
        })}

      {isMobileView && <Pagination page={page} count={Math.ceil(centersInfo.length / 5)} onChange={handleChange} sx={{ mb: 1, '> ul': { justifyContent: 'center' } }} />}
    </>
  );
};

export default CentersList;
