import MenuIcon from '@mui/icons-material/Menu';
import { Box, ButtonBase, Divider, IconButton, SvgIcon, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useAuthenticated, useIsMobileView } from 'hooks';
import { FC, useCallback, useState } from 'react';
import { ReactComponent as IconQrCode } from 'resources/icons/qr_code.svg';
import { useDispatch } from 'react-redux';
import Logo from 'resources/images/logo.svg';
import { openLeftDrawer } from 'store/actions/navMenu.actions';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';
import QrCodeModal from './QrCodeModal';

const HeaderLeftBloc: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const isAuthenticated = useAuthenticated();
  const isMobileView = useIsMobileView();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleDrawerOpen = useCallback(() => {
    dispatch(openLeftDrawer());
  }, [dispatch]);

  return (
    <>
      {isAuthenticated ? (
        <>
          {isMobileView && (
            <IconButton
              onClick={handleDrawerOpen}
              sx={{
                marginRight: { xs: '0px', sm: '32px' },
                display: { sm: 'flex' },
                ...(isOpen && { display: 'none' }),
              }}
            >
              <MenuIcon sx={{ fontSize: 24 }} />
            </IconButton>
          )}
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: { xs: 'center', md: 'initial' },
              alignItems: 'center',
            }}
            display='flex'
          >
            <ContextLink to='HOME'>
              <Box component='img' alt='MyLab_Logo' src={Logo} sx={{ height: '36px' }} />
            </ContextLink>
            <Divider orientation='vertical' variant='middle' flexItem sx={{ borderColor: redesignColors.grey.grey3, ml: isMobileView ? 1 : 2 }} />
            <ButtonBase sx={{ margin: `-5px 0 0 ${isMobileView ? '8px' : '16px'}` }} onClick={() => setIsOpenModal(true)}>
              <SvgIcon fontSize='medium' component={IconQrCode} />
              {!isMobileView && <Typography sx={{ ml: 0.5, color: redesignColors.blue.dark, fontWeight: '500' }}>{t('navigation.header.bionext_card')}</Typography>}
            </ButtonBase>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: { xs: 'center', md: 'initial' },
            alignItems: 'center',
          }}
          display='flex'
        >
          <ContextLink to='HOME'>
            <Box component='img' alt='MyLab_Logo' src={Logo} sx={{ height: '36px' }} />
          </ContextLink>
        </Box>
      )}
      {isOpenModal && <QrCodeModal onClose={() => setIsOpenModal(false)} />}
    </>
  );
};

export default HeaderLeftBloc;
