import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Address } from 'models/profile.model';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

const countries = ['LU', 'FR', 'DE', 'BE'];

type Props = {
  value?: Address;
  isWork?: boolean;
  labelOnAdress?: boolean;
  register: any;
  control: any;
  formState: any;
};

const AddressForm: FC<Props> = ({ register, formState, control, value, isWork = false, labelOnAdress = false }) => {
  const { t } = useTranslation();

  return (
    <>
      {value ? (
        <>
          {!isWork && (
            <>
              <Grid item xs={12} sm={2}>
                <TextField
                  variant='filled'
                  defaultValue={value?.number || ''}
                  {...register('addresses.home.number')}
                  error={formState.errors?.addresses?.home?.number !== undefined}
                  label={`${t('appointment.book.steps.place.address.number')}`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} alignSelf={labelOnAdress ? 'end' : 'start'}>
                <TextField
                  variant='filled'
                  defaultValue={value?.street || ''}
                  {...register('addresses.home.street')}
                  // required
                  error={formState.errors?.addresses?.home?.street !== undefined}
                  label={`${t('appointment.book.steps.place.address.street')}`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField variant='filled' defaultValue={value?.complement || ''} {...register('addresses.home.complement')} label={t('appointment.book.steps.place.address.complement')} fullWidth />
              </Grid>
              <Grid item sx={{ display: { sx: 'none' } }} sm={2} />
              <Grid item xs={12} sm={2}>
                <TextField
                  name='addresses.home.zip_code'
                  defaultValue={value?.zip_code || ''}
                  variant='filled'
                  {...register('addresses.home.zip_code')}
                  error={formState.errors?.addresses?.home?.zip_code !== undefined}
                  label={`${t('appointment.book.steps.place.address.zip_code')}`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name='addresses.home.city'
                  defaultValue={value?.city || ''}
                  variant='filled'
                  {...register('addresses.home.city')}
                  error={formState.errors?.addresses?.home?.city !== undefined}
                  label={`${t('appointment.book.steps.place.address.city')}`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  rules={{ required: true }}
                  defaultValue={value?.country_iso || ''}
                  render={({ field }) => (
                    <TextField
                      error={formState.errors?.addresses?.home?.country_iso !== undefined}
                      select
                      {...field}
                      label={t('appointment.book.steps.place.address.country')}
                      variant='filled'
                      fullWidth
                    >
                      {countries.map((country, i) => (
                        <MenuItem value={country} key={`country-${i}`}>
                          {/*@ts-ignore*/}
                          {t(`countries.${country}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  name='addresses.home.country_iso'
                  control={control}
                />
              </Grid>
            </>
          )}
          {isWork && (
            <>
              <Grid item xs={12} md={3}>
                <TextField variant='filled' defaultValue={value?.company || ''} {...register('addresses.work.company')} label={`${t('appointment.book.steps.place.address.company')}`} fullWidth />
              </Grid>
              <Grid item md={9} display={{ xs: 'none', md: 'block' }} />

              <Grid item xs={12} md={2}>
                <TextField variant='filled' defaultValue={value?.number || ''} {...register('addresses.work.number')} label={`${t('appointment.book.steps.place.address.number')}`} fullWidth />
              </Grid>
              <Grid item xs={12} md={3} alignSelf={labelOnAdress ? 'end' : 'start'}>
                <TextField variant='filled' defaultValue={value?.street || ''} {...register('addresses.work.street')} label={`${t('appointment.book.steps.place.address.street')}`} fullWidth />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField variant='filled' defaultValue={value?.complement || ''} {...register('addresses.work.complement')} label={t('appointment.book.steps.place.address.complement')} fullWidth />
              </Grid>
              <Grid item md={3} display={{ xs: 'none', md: 'block' }} />

              <Grid item xs={12} sm={2}>
                <TextField
                  name='addresses.work.zip_code'
                  defaultValue={value?.zip_code || ''}
                  variant='filled'
                  {...register('addresses.work.zip_code')}
                  label={`${t('appointment.book.steps.place.address.zip_code')}`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  name='addresses.work.city'
                  defaultValue={value?.city || ''}
                  variant='filled'
                  {...register('addresses.work.city')}
                  label={`${t('appointment.book.steps.place.address.city')}`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  defaultValue={value?.country_iso || ''}
                  render={({ field }) => (
                    <TextField select {...field} label={t('appointment.book.steps.place.address.country')} variant='filled' fullWidth>
                      {countries.map((country, i) => (
                        <MenuItem value={country} key={`country-${i}`}>
                          {/*@ts-ignore*/}
                          {t(`countries.${country}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  name='addresses.work.country_iso'
                  control={control}
                />
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          {isWork && (
            <Grid item xs={12}>
              <TextField variant='filled' {...register('address.company')} label={`${t('appointment.book.steps.place.address.company')}`} fullWidth />
            </Grid>
          )}
          {labelOnAdress && (
            <Grid item xs={12}>
              <Grid item xs={12} mb={-2}>
                <Typography variant='body1'>{t('preregistration.myData.form.personalAddress')}</Typography>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={3}>
            <TextField
              variant='filled'
              {...register('address.number')}
              error={formState.errors?.address?.number !== undefined}
              label={`${t('appointment.book.steps.place.address.number')}`}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={9} alignSelf={labelOnAdress ? 'end' : 'start'}>
            <TextField
              variant='filled'
              {...register('address.street', { required: true })}
              required
              error={formState.errors?.address?.street !== undefined}
              label={`${t('appointment.book.steps.place.address.street')}`}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField variant='filled' {...register('address.complement')} label={t('appointment.book.steps.place.address.complement')} fullWidth />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name='address.zip_code'
              variant='filled'
              {...register('address.zip_code', { required: true })}
              required
              error={formState.errors?.address?.zip_code !== undefined}
              label={`${t('appointment.book.steps.place.address.zip_code')}`}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              name='address.city_name'
              variant='filled'
              {...register('address.city_name', { required: true })}
              required
              error={formState.errors?.address?.city !== undefined}
              label={`${t('appointment.book.steps.place.address.city')}`}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              rules={{ required: true }}
              render={({ field }) => (
                <TextField error={formState.errors?.address?.country_iso !== undefined} select required {...field} label={t('appointment.book.steps.place.address.country')} variant='filled' fullWidth>
                  {countries.map((country, i) => (
                    <MenuItem value={country} key={`country-${i}`}>
                      {/*@ts-ignore*/}
                      {t(`countries.${country}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              name='address.country_iso'
              control={control}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default AddressForm;
