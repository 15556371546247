import api from 'api';
import { useLocale } from 'hooks';
import { AppointmentMedicalAct } from 'models/appointment.medical.act.model';
import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { MedicalActsActionType } from 'store/actions/medicalActs.actions';

type ReturnParams = {
  medicalActs: AppointmentMedicalAct[];
  isLoading: boolean;
};

export const useMedicalActs = (): ReturnParams => {
  const locale = useLocale();
  const { getState, dispatch } = useStore();

  const [medicalActs, setMedicalActs] = useState<AppointmentMedicalAct[]>([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const { medicalActs: medicalActsStore } = getState().medicalActs;

    if (!medicalActsStore.length) {
      api.appointments.getMedicalActs(locale).then((res) => {
        dispatch({ type: MedicalActsActionType.ADD, medicalActs: res });
        setMedicalActs(res);

        setLoading(false);
      });
    } else {
      setMedicalActs(medicalActsStore);
      setLoading(false);
    }
  }, []);

  return { medicalActs, isLoading };
};
