export enum AnalysisType {
  test = 'test',
  insimination = 'insimination',
}

export type FertitlityStep = {
  title: string;
  component: JSX.Element;
  type: string;
  nextButtonLabel?: string;
};

export enum FertilityStepType {
  address = 'address',
  introduction = 'introduction',
  information = 'information',
  availableSlot = 'available_slot',
  prescriptions = 'prescriptions',
  summary = 'summary',
}
