import { useState } from 'react';
import { Box, Card, CardContent, Divider, Grid, Stack, styled, TextField, TextFieldProps, ToggleButton, Typography } from '@mui/material';
import PhoneInput from 'components/phone-input';
import { Gender, Person } from 'models/profile.model';
import { getConfirmEmailHelperText, getEmailHelperText, isValidEmail } from 'pages/patient/new-appointment/components/PatientInfoCard';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';
import dayjs from 'dayjs';
import { convertDate } from 'utils/func/Date.func';

const StyledTextField = styled((props: TextFieldProps) => <TextField {...props} variant='filled' />)(({ theme }) => ({
  width: '100%',
  borderRadius: '8px',
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
  '.MuiFormLabel-root': {
    padding: 0,
  },
}));

const getAge = (birthdate: string) => {
  return dayjs().diff(dayjs(birthdate), 'year');
};
const formatCNS = (cns: string) => {
  cns = cns.replace(/\D/g, '');

  return `${cns.slice(0, 4)} ${cns.slice(4, 8)} ${cns.slice(8, 11)} ${cns.slice(11, 13)}`;
};

type PatientFilledCardProps = {
  patient: Person;
  phone: string;
  setPhone: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
  emailConfirm: string;
  setEmailConfirm: (value: string) => void;
};

const PatientFilledCard = ({ patient, email, setEmail, emailConfirm, setEmailConfirm, phone, setPhone }: PatientFilledCardProps) => {
  const { t } = useTranslation();

  const emailHelperText = getEmailHelperText(email);
  const emailConfirmHelperText = getConfirmEmailHelperText(email, emailConfirm);

  const date = convertDate(patient.birth_date, false, 'yyyy-dd-MM');
  const rows = [
    {
      title: t('fertility.information.gender'),
      value: patient.gender === Gender.MALE ? t('common.sex.man') : t('common.sex.woman'),
    },
    {
      title: t('fertility.information.date_of_birth'),
      value: `${date} (${getAge(date)} years)`,
    },
    {
      title: t('fertility.information.cns'),
      value: formatCNS(patient.cns),
    },
  ];

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography sx={{ fontWeight: 600 }} variant='h6'>
              {patient.first_name} {patient.last_name}
            </Typography>
            <Divider />
          </Stack>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box mb={1}>
                  {rows.map((row) => (
                    <Box display='flex'>
                      <Box width='150px' color={redesignColors.grey.grey2}>
                        {row.title}:
                      </Box>
                      <Box>{row.value}</Box>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  required
                  type='email'
                  error={!!email && !isValidEmail(email)}
                  helperText={emailHelperText ? t(emailHelperText) : false}
                  label={t('new_appointment.stepper.steps.information.email')}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  required
                  type='email'
                  error={!!emailConfirm && (!isValidEmail(emailConfirm) || emailConfirm !== email)}
                  helperText={emailConfirmHelperText ? t(emailConfirmHelperText) : false}
                  label={t('new_appointment.stepper.steps.information.confirm_email')}
                  value={emailConfirm}
                  onChange={(e) => {
                    setEmailConfirm(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <PhoneInput
                  sx={{
                    width: '100%',
                    backgroundColor: '#F2FAFD',
                    borderRadius: '8px',
                    '& .MuiInputBase-root': {
                      borderRadius: '8px',
                      padding: '5px 16px',
                    },
                    '.MuiFormLabel-root': {
                      padding: 0,
                    },
                  }}
                  label={t('new_appointment.stepper.steps.information.phone')}
                  required
                  value={phone}
                  onChange={(e) => {
                    setPhone(e as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={{ color: '#7A9099', fontSize: '16px', lineHeight: '100%', fontWeight: '400' }} variant='button'>
                  {t('new_appointment.stepper.steps.information.phone_info')}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PatientFilledCard;
