import { Box, Button, Grid, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { ArrowDownward as ArrowDownwardIcon } from '@mui/icons-material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { redesignColors } from 'resources/theme/theme.colors';
import { useTranslation } from 'react-i18next';
import { FilterResultsFormValues } from 'models/result.model';
import { OfficeMedicalStaff, PatientTag } from 'models/record.model';
import CustomChip from 'components/custom-chip';
import { resultTagColors } from '../utils/colors';
import { defaultValues } from '../utils/filters';

type ResultsFilterBarProps = {
  open: boolean;
  tags: PatientTag[];
  setOpen: (value: boolean) => void;
  setSortFromNewToOld: () => void;
  sortFromNewToOld: boolean;
  doctors: OfficeMedicalStaff[];
  filterData: FilterResultsFormValues | null;
  setFilterData: (value: FilterResultsFormValues | null) => void;
};

const ResultsFilterBar: FC<ResultsFilterBarProps> = ({ open, filterData, sortFromNewToOld, setSortFromNewToOld, setOpen, setFilterData, doctors, tags: tagsProps }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const tagChips = () => {
    if (!filterData) {
      return null;
    }
    const { tags } = filterData;
    return (
      <>
        {tags.map((id) => {
          const tag = tagsProps.find((doc) => doc.id === id);
          if (!tag) {
            return null;
          }
          return (
            <CustomChip
              variant='outlined'
              color='secondary'
              label={tag.name}
              onDelete={() => setFilterData({ ...filterData, tags: tags.filter((docId) => docId !== id) })}
              sx={{ m: 1, background: tag.color, color: resultTagColors[tag.color] }}
            />
          );
        })}
      </>
    );
  };
  const doctorChips = () => {
    if (!filterData) {
      return null;
    }
    const { doctors: doctorsIds } = filterData;
    return (
      <>
        {doctorsIds.map((id) => {
          const doctor = doctors.find((doc) => doc.medical_staff.id === id);
          if (!doctor) {
            return null;
          }
          return (
            <CustomChip
              variant='outlined'
              color='secondary'
              label={`${doctor.medical_staff.first_name} ${doctor.medical_staff.last_name}`}
              onDelete={() => setFilterData({ ...filterData, doctors: doctorsIds.filter((docId) => docId !== id) })}
              sx={{ m: 1 }}
            />
          );
        })}
      </>
    );
  };
  const isFiltersApplied = useMemo(() => filterData && JSON.stringify(filterData) !== JSON.stringify(defaultValues), [filterData]);
  return (
    <Grid item xs={12}>
      <Box display='flex' alignItems='center' flexWrap='wrap'>
        <Button
          variant='outlined'
          color={open ? 'secondary' : 'primary'}
          sx={{ width: 80, fontSize: '14px', position: 'relative', border: `1px solid ${redesignColors.grey.grey4}` }}
          startIcon={<FilterAltIcon sx={{ color: open ? theme.palette.common.white : redesignColors.blue.main }} />}
          onClick={() => setOpen(true)}
        >
          {isFiltersApplied && <div style={{ position: 'absolute', top: -5, right: -5, width: '14px', height: '14px', background: redesignColors.red, borderRadius: '50%' }} />}
          {t('centers.filter')}
        </Button>
        <Button
          variant='outlined'
          startIcon={<ArrowDownwardIcon sx={{ transform: `rotate(${sortFromNewToOld ? '0deg' : '-180deg'})`, transition: 'all .3s', color: redesignColors.blue.main }} />}
          color='primary'
          sx={{ ml: 2, fontSize: '14px', border: `1px solid ${redesignColors.grey.grey4}` }}
          onClick={() => setSortFromNewToOld()}
        >
          {sortFromNewToOld ? t('result_doctor.from_new_to_old') : t('result_doctor.from_old_to_new')}
        </Button>
        {tagChips()}
        {doctorChips()}

        {filterData?.date && (
          <CustomChip
            variant='outlined'
            color='secondary'
            label={t(`result_doctor.filter_drawer.${filterData?.date}`)}
            onDelete={() => {
              const { date, datesRangeFrom, datesRangeTo, ...otherValues } = filterData;
              setFilterData({ ...(otherValues as any) });
            }}
            sx={{ m: 1 }}
          />
        )}
        {isFiltersApplied && (
          <Button
            variant='text'
            startIcon={<CloseIcon sx={{ transform: `rotate(${sortFromNewToOld ? '0deg' : '-180deg'})`, transition: 'all .3s' }} />}
            color='primary'
            sx={{ ml: 2 }}
            onClick={() => setFilterData(null)}
          >
            {t('result_doctor.clear_filters')}
          </Button>
        )}
      </Box>
    </Grid>
  );
};

export default ResultsFilterBar;
