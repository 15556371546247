import { Box, Button, Divider, Grid, SvgIcon, Typography } from '@mui/material';
import { useAuthenticated, useIsMobileView } from 'hooks';
import EmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import { CenterInfo } from 'pages/patient/centers/types';
import { ReactComponent as ParkingIcon } from 'resources/icons/parking.svg';
import { ReactComponent as AccessabilityIcon } from 'resources/icons/accessability.svg';
import { redesignColors } from 'resources/theme/theme.colors';
import formatCenterAddress from 'pages/patient/centers/utils/formarCenterAddress';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import getMarkerIcon from 'pages/patient/centers/utils/getMarkerIcon';
import MapMarker from 'pages/patient/centers/components/MapMarker';
import { mapStyle } from 'resources/theme/GoogleMapStyle';
import ConnectAccountDialog from 'components/connect-account-dialog';
import RoutePaths from 'utils/RoutePaths';

const defaultMapZoom = 18;

const AddressStep: FC<{
  handleIsActiveNextButton: () => void;
  centerInfo: CenterInfo | null;
}> = ({ centerInfo, handleIsActiveNextButton }) => {
  const { t } = useTranslation();
  useEffect(() => {
    handleIsActiveNextButton();
  }, []);

  return (
    <Grid xs={12} mt={3} item>
      {centerInfo?.center ? (
        <AddressBody centerInfo={centerInfo} />
      ) : (
        <Grid xs={12} item sx={{ margin: '15% auto', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Typography>{t('fertility.address.empty_title')}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

const AddressBody = ({ centerInfo }: { centerInfo: CenterInfo }) => {
  const isAuthenticated = useAuthenticated();

  const { isLoaded: isGoogleLoaded } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey || '',
  });

  const [map, setMap] = useState<google.maps.Map>();
  const handleLoad = useCallback(function onLoad(mapInstance: google.maps.Map) {
    setMap(mapInstance);
  }, []);
  const isMobileView = useIsMobileView();
  const { center, file } = centerInfo;
  const centerLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${center.attributes.title} ${formatCenterAddress(center)}`)}`;

  useEffect(() => {
    if (map) {
      google.maps.event.trigger(map, 'resize');
    }
  }, [map]);

  return (
    <Grid container spacing={3} sx={{ mb: '7vh' }}>
      {!isAuthenticated && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <ConnectAccountDialog redirect={RoutePaths['HOME']} />
        </Grid>
      )}
      <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: isMobileView ? 'center' : 'flex-end' }}>
        {isGoogleLoaded && (
          <GoogleMap
            onLoad={handleLoad}
            zoom={defaultMapZoom}
            center={{
              lat: parseFloat(center.attributes.field_prelevement_latitude.replaceAll(',', '.')),
              lng: parseFloat(center.attributes.field_prelevement_longitude.replaceAll(',', '.')),
            }}
            mapContainerStyle={{ height: '170px', width: isMobileView ? '100%' : '90%', maxWidth: '348px' }}
            options={{
              draggable: false,
              disableDefaultUI: true,
              zoomControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              styles: mapStyle,
            }}
            onClick={() => window.open(centerLink, '_blank')}
          >
            <MapMarker
              position={{
                lat: parseFloat(center.attributes.field_prelevement_latitude.replaceAll(',', '.')),
                lng: parseFloat(center.attributes.field_prelevement_longitude.replaceAll(',', '.')),
              }}
              opened={false}
              handleOpen={() => null}
              icon={getMarkerIcon(center.attributes.field_type_de_laboratoire, false)}
            />
          </GoogleMap>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box sx={{ borderBottom: `1px solid ${redesignColors.grey.grey3}` }} pb={1}>
          <Typography variant='body1' fontWeight='600'>
            {centerInfo?.center?.attributes?.title}
          </Typography>
          {(center.attributes.field_prelevement_handicape || center.attributes.field_prelevement_parking) && (
            <Box display='flex' mt={2}>
              {center.attributes.field_prelevement_handicape && (
                <Typography>
                  <SvgIcon fontSize='medium' component={ParkingIcon} />
                </Typography>
              )}
              {center.attributes.field_prelevement_parking && (
                <Typography>
                  <SvgIcon fontSize='medium' component={AccessabilityIcon} />
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Divider />
        {file && (
          <Box
            component={'img'}
            src={`${config.websiteBionextApiUrl}${file.attributes.uri.url.replace('files/', 'files/styles/medium/public/')}`}
            alt={center.relationships.field_prelevement_photo.data.meta.alt}
            sx={{
              mt: 2,
              width: '164px',
              borderRadius: '8px',
            }}
          />
        )}
        <Typography variant='body1' fontWeight='600' my={1}>
          {formatCenterAddress(center)}
        </Typography>

        <Button variant='link' startIcon={<EmailIcon sx={{ fill: redesignColors.blue.main }} />} href={`mailto:${center.attributes.field_prelevement_email}`}>
          {center.attributes.field_prelevement_email}
        </Button>
        <Box>
          <Button variant='link' startIcon={<PhoneIcon sx={{ fill: redesignColors.blue.main }} />} href={`tel:${center.attributes.field_prelevement_telephone}`}>
            {center.attributes.field_prelevement_telephone}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddressStep;
