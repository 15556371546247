import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, SvgIcon, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QuestionIcon } from 'resources/icons/result-info/question.svg';
import { ReactComponent as ClinicalIcon } from 'resources/icons/result-info/clinical.svg';
import { ReactComponent as TipsIcon } from 'resources/icons/result-info/tips.svg';
import { ReactComponent as StatisticsIcon } from 'resources/icons/result-info/statistics.svg';
import { ReactComponent as BionextTvIcon } from 'resources/icons/result-info/bionext-tv.svg';
import { redesignColors } from 'resources/theme/theme.colors';

const infoAccordions = [
  {
    icon: QuestionIcon,
    title: 'result.doctor.info.what_why',
    children: `<b>What?</b>
    <p>
        The hemoglobin (HGB) blood test measures the level of hemoglobin in the blood, and is part of a complete blood count (CBC). 
        Hemoglobin is a protein that contains iron, and is found in red blood cells, giving them their characteristic red color. 
        Hemoglobin also helps carry oxygen to the body's organs and tissues, and it carries carbon dioxide back to the lungs from organs and tissues.
    </p>

    <b>Why?</b>
    <p>
        The hemoglobin (HGB) blood test is performed to check on overall health and to identify or monitor conditions, such as anemia.
    </p>

    <b>How?</b>
    <p>
        If your blood is only being tested for hemoglobin (HGB), you can eat and drink normally before the test. 
        However, if your blood sample will be used for additional tests, you may need to fast before the test. 
        Your doctor will give you specific instructions. Ensure that your healthcare provider knows about all of the 
        medications, herbs, vitamins, and supplements you are taking.
    </p>`,
  },
  {
    icon: ClinicalIcon,
    title: 'result.doctor.info.clinical',
    children: `<b>What?</b>
    <p>
        The hemoglobin (HGB) blood test measures the level of hemoglobin in the blood, and is part of a complete blood count (CBC). 
        Hemoglobin is a protein that contains iron, and is found in red blood cells, giving them their characteristic red color. 
        Hemoglobin also helps carry oxygen to the body's organs and tissues, and it carries carbon dioxide back to the lungs from organs and tissues.
    </p>

    <b>Why?</b>
    <p>
        The hemoglobin (HGB) blood test is performed to check on overall health and to identify or monitor conditions, such as anemia.
    </p>

    <b>How?</b>
    <p>
        If your blood is only being tested for hemoglobin (HGB), you can eat and drink normally before the test. 
        However, if your blood sample will be used for additional tests, you may need to fast before the test. 
        Your doctor will give you specific instructions. Ensure that your healthcare provider knows about all of the 
        medications, herbs, vitamins, and supplements you are taking.
    </p>`,
  },
  {
    icon: TipsIcon,
    title: 'result.doctor.info.tips',
    children: `<b>What?</b>
    <p>
        The hemoglobin (HGB) blood test measures the level of hemoglobin in the blood, and is part of a complete blood count (CBC). 
        Hemoglobin is a protein that contains iron, and is found in red blood cells, giving them their characteristic red color. 
        Hemoglobin also helps carry oxygen to the body's organs and tissues, and it carries carbon dioxide back to the lungs from organs and tissues.
    </p>

    <b>Why?</b>
    <p>
        The hemoglobin (HGB) blood test is performed to check on overall health and to identify or monitor conditions, such as anemia.
    </p>

    <b>How?</b>
    <p>
        If your blood is only being tested for hemoglobin (HGB), you can eat and drink normally before the test. 
        However, if your blood sample will be used for additional tests, you may need to fast before the test. 
        Your doctor will give you specific instructions. Ensure that your healthcare provider knows about all of the 
        medications, herbs, vitamins, and supplements you are taking.
    </p>`,
  },
  {
    icon: StatisticsIcon,
    title: 'result.doctor.info.statistics',
    children: `<b>What?</b>
    <p>
        The hemoglobin (HGB) blood test measures the level of hemoglobin in the blood, and is part of a complete blood count (CBC). 
        Hemoglobin is a protein that contains iron, and is found in red blood cells, giving them their characteristic red color. 
        Hemoglobin also helps carry oxygen to the body's organs and tissues, and it carries carbon dioxide back to the lungs from organs and tissues.
    </p>

    <b>Why?</b>
    <p>
        The hemoglobin (HGB) blood test is performed to check on overall health and to identify or monitor conditions, such as anemia.
    </p>

    <b>How?</b>
    <p>
        If your blood is only being tested for hemoglobin (HGB), you can eat and drink normally before the test. 
        However, if your blood sample will be used for additional tests, you may need to fast before the test. 
        Your doctor will give you specific instructions. Ensure that your healthcare provider knows about all of the 
        medications, herbs, vitamins, and supplements you are taking.
    </p>`,
  },
  {
    icon: BionextTvIcon,
    title: 'result.doctor.info.bionext_tv',
    children: `<b>What?</b>
    <p>
        The hemoglobin (HGB) blood test measures the level of hemoglobin in the blood, and is part of a complete blood count (CBC). 
        Hemoglobin is a protein that contains iron, and is found in red blood cells, giving them their characteristic red color. 
        Hemoglobin also helps carry oxygen to the body's organs and tissues, and it carries carbon dioxide back to the lungs from organs and tissues.
    </p>

    <b>Why?</b>
    <p>
        The hemoglobin (HGB) blood test is performed to check on overall health and to identify or monitor conditions, such as anemia.
    </p>

    <b>How?</b>
    <p>
        If your blood is only being tested for hemoglobin (HGB), you can eat and drink normally before the test. 
        However, if your blood sample will be used for additional tests, you may need to fast before the test. 
        Your doctor will give you specific instructions. Ensure that your healthcare provider knows about all of the 
        medications, herbs, vitamins, and supplements you are taking.
    </p>`,
  },
];

const InformationBlock = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box sx={{ width: { xs: '100%', md: '50%' } }}>
      <Typography variant='h2' mb={2} fontWeight='500'>
        {t('result.doctor.info.information')}
      </Typography>
      {infoAccordions.map((accordion, index) => (
        <Accordion defaultExpanded={index === 0} sx={{ marginBottom: 2, borderRadius: '8px', background: theme.palette.common.white }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: theme.spacing(1, 2) }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <SvgIcon
                sx={{
                  fontSize: 20,
                  color: `${redesignColors.blue.main} !important`,
                  mr: 1,
                }}
                component={accordion.icon}
              />
              <Typography variant='h3' fontWeight='500'>
                {t(accordion.title)}
              </Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails
            sx={{ margin: 0, padding: theme.spacing(2, 2, 3, 2), borderTop: `1px solid ${redesignColors.grey.grey4}`, '& > p': { marginTop: 0 } }}
            dangerouslySetInnerHTML={{ __html: accordion.children }}
          />
        </Accordion>
      ))}
    </Box>
  );
};

export default InformationBlock;
