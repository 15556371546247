import { CircularProgress } from '@mui/material';
import { DateCalendar, DateCalendarProps } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FC } from 'react';
import { LocalizationProvider, deDE, frFR, ptBR, enUS } from '@mui/x-date-pickers';
import CustomCalendarHeader from './custom-calendar-header';
import { redesignColors } from 'resources/theme/theme.colors';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import ptLocale from 'date-fns/locale/pt';
import { useLocale } from 'hooks';

const localeMap: { [key: string]: Locale } = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
  pt: ptLocale,
};

const localeTextMap: { [key: string]: any } = {
  en: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
  fr: frFR.components.MuiLocalizationProvider.defaultProps.localeText,
  de: deDE.components.MuiLocalizationProvider.defaultProps.localeText,
  pt: ptBR.components.MuiLocalizationProvider.defaultProps.localeText,
};

const CustomDateCalendar: FC<DateCalendarProps<Date>> = (props) => {
  const locale = useLocale();

  return (
    <LocalizationProvider adapterLocale={{ ...localeMap[locale], options: { ...localeMap[locale].options, weekStartsOn: 0 } }} dateAdapter={AdapterDateFns} localeText={localeTextMap[locale]}>
      <DateCalendar
        {...props}
        sx={{
          width: 416,
          maxHeight: 'none !important',
          '& .MuiPickersDay-root': {
            borderRadius: '8px',
            width: 48,
            height: 48,
            fontSize: '16px',
            fontWeight: '500',
            color: redesignColors.dark,
            '&:hover': {
              backgroundColor: `${redesignColors.grey.grey4} !important`,
            },
            '&:focus': {
              backgroundColor: `${redesignColors.blue.main} !important`,
            },
          },
          '& .MuiPickersYear-yearButton': {
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: `${redesignColors.grey.grey4} !important`,
            },
            '&:focus': {
              backgroundColor: `inherit`,
            },
          },
          '& .MuiDayCalendar-weekDayLabel': {
            color: redesignColors.dark,
            width: 48,
            height: 48,
            fontSize: '16px',
          },
          '& .MuiPickersSlideTransition-root': {
            height: 310,
          },
          '& .MuiPickersDay-today': {
            border: `2px solid ${redesignColors.blue.main} !important`,
          },
          '& .Mui-selected': {
            backgroundColor: `${redesignColors.blue.main} !important`,
            border: `2px solid ${redesignColors.blue.main} !important`,
          },
          '& .Mui-disabled:not(.Mui-selected)': {
            color: `${redesignColors.grey.grey3} !important`,
          },
          '& .MuiPickersArrowSwitcher-button': {
            color: redesignColors.dark,
          },
          '& .MuiDayCalendar-header': {
            borderBottom: `1px solid ${redesignColors.grey.grey2}`,
          },
          ...props.sx,
        }}
        slots={{
          ...props.slots,
          calendarHeader: CustomCalendarHeader,
        }}
        slotProps={{
          previousIconButton: {
            size: 'medium',
          },
          nextIconButton: {
            size: 'medium',
          },
          leftArrowIcon: {
            fontSize: 'medium',
          },
          rightArrowIcon: {
            fontSize: 'medium',
          },
          ...props.slotProps,
        }}
        renderLoading={() => <CircularProgress />}
      />
    </LocalizationProvider>
  );
};

export default CustomDateCalendar;
