import theme_color, { redesignColors } from 'resources/theme/theme.colors';

import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            backgroundColor: theme_color.palette.common.white,
            padding: '4px 24px',
            fontWeight: 700,
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            padding: '4px',
            height: '40px',
            borderRadius: '40px',
            border: `1px solid ${redesignColors.grey.grey4}`,
            fontSize: '16px',
            fontWeight: '500',
            backgroundColor: redesignColors.blue.blue2,
            '& .MuiChip-deleteIcon': {
              width: '24px',
              height: '24px',
              fill: 'white',
            },
          },
        },
      ],
    },
  },
});

export default theme;
