import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import api from 'api';
import TimeSlots, { defaultSlots } from 'components/slot-picker';
import dayjs from 'dayjs';
import { useIsMobileView, useNotification } from 'hooks';
import { useMedicalActs } from 'hooks/useMedicalActs';
import { AppointmentSlot, AppointmentSlots } from 'models/appointment.slots.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';
import { NEW_APPOINTMENT_SLOTS_MEDICAL_ACT_CODE } from 'utils/Constants';
import utc from 'dayjs/plugin/utc';
import CustomDateCalendar from 'components/custom-date-calendar';

dayjs.extend(utc);

interface AvailableSlotStepProps {
  activeSubStep?: string;
  setNextButtonActive: (state: boolean) => void;
  setNextSubStep?: () => void;
  setPrevSubStep?: () => void;
}

type GetSlotsParams = {
  endDate: Date;
  startDate?: Date;
  lat: number;
  long: number;
  medicalActId: number;
};

const getSlots = ({ startDate = new Date(), endDate, lat, long, medicalActId }: GetSlotsParams) => api.appointments.getSlots(startDate, endDate, lat, long, medicalActId);

const getDisabledSlots = (daySlots: AppointmentSlot[]) => {
  return defaultSlots.filter((defSlot) => {
    const [hours, minutes] = defSlot.split(':').map(Number);

    const existedSlot = daySlots.find((slot) => {
      return dayjs(slot.start).utc().hour() === hours && dayjs(slot.start).utc().minute() === minutes;
    });
    if (existedSlot) {
      return !existedSlot.available;
    }
    return true;
  });
};

const AvailableSlotStep: FC<AvailableSlotStepProps> = ({ activeSubStep, setNextButtonActive, setNextSubStep, setPrevSubStep }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  const { medicalActs, isLoading: isLoadingMedicalActs } = useMedicalActs();
  const { getState, dispatch } = useStore();
  const { address } = getState().newAppointmentFlowReducer;
  const { notification } = useNotification();
  const [date, setDate] = useState<Date>(new Date());
  const [slots, setSlots] = useState<AppointmentSlots | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [slot, setSelectedSlot] = useState<string>('');
  const [daySlots, setDaySlots] = useState<AppointmentSlot[]>([]);

  useEffect(() => {
    setSelectedSlot('');
    dispatch({
      type: NewAppointmentFlowActionType.DELETE_SLOT,
    });
    setNextButtonActive(false);
  }, [date, setSelectedSlot, setNextButtonActive]);

  useEffect(() => {
    setNextButtonActive(false);

    const storage = getState().newAppointmentFlowReducer;

    if (!storage) return;
    setSelectedSlot(dayjs(storage.slot).format('HH:mm'));
  }, [setNextButtonActive, getState]);

  useEffect(() => {
    if (!address.longLat.long || !address.longLat.lat) {
      notification(t('new_appointment.stepper.steps.available_slots.error'), 'error');
      return;
    }
  }, [address, notification, t]);

  useEffect(() => {
    setLoading(true);
    const newAppointmentMedicalAct = medicalActs.find((act) => act.code === NEW_APPOINTMENT_SLOTS_MEDICAL_ACT_CODE);
    if (newAppointmentMedicalAct) {
      getSlots({
        endDate: dayjs().add(30, 'day').toDate(),
        lat: address.longLat.lat,
        long: address.longLat.long,
        medicalActId: newAppointmentMedicalAct.id,
      })
        .then((res) => setSlots(res))
        .finally(() => setLoading(false));
    }
  }, [medicalActs]);

  const handleSelectSlot = (slot: string) => {
    const daySlot = daySlots.find((dSlot) => dayjs(dSlot.start).format('HH:mm') === slot);
    if (daySlot) {
      setNextButtonActive(true);
      setSelectedSlot(slot);

      dispatch({
        type: NewAppointmentFlowActionType.SET_SLOT,
        slot: daySlot,
      });
    } else {
      notification(t('new_appointment.stepper.steps.available_slots.error'), 'error');
    }
  };

  return (
    <Stack spacing={2} justifyContent='center' width='100%'>
      {loading || isLoadingMedicalActs ? (
        <Box display='flex' justifyContent='center' alignItems='center' flex={1} mt='15%'>
          <CircularProgress size={50} />
        </Box>
      ) : activeSubStep === 'date' ? (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' flex={1}>
          <Typography alignSelf='center' variant='h3' sx={{ fontWeight: 700 }}>
            {t('new_appointment.stepper.steps.available_slots.title')}
          </Typography>
          <CustomDateCalendar
            value={date}
            onChange={(newDate) => {
              setDate(newDate as Date);

              const currentDate = dayjs(newDate);
              const slotKey = currentDate.format('YYYY-MM-DD');
              const dateSlots = ((slots && slots[slotKey]) || []) as AppointmentSlot[];

              if (!dateSlots?.length) {
                const newAppointmentMedicalAct = medicalActs.find((act) => act.code === NEW_APPOINTMENT_SLOTS_MEDICAL_ACT_CODE);
                if (!newAppointmentMedicalAct) {
                  return;
                }
                setLoading(true);
                getSlots({
                  startDate: currentDate.toDate(),
                  endDate: currentDate.add(1, 'day').toDate(),
                  lat: address.longLat.lat,
                  long: address.longLat.long,
                  medicalActId: newAppointmentMedicalAct.id,
                })
                  .then((res) => {
                    setSlots((prev) => ({ ...prev, ...res }));
                    const newSlots = { ...slots, ...res };
                    const dateSlots = (newSlots ? newSlots[slotKey] : []) as AppointmentSlot[];
                    if (!dateSlots.length) {
                      return;
                    }
                    setDaySlots(dateSlots);
                    setNextSubStep?.();
                  })
                  .finally(() => setLoading(false));

                return;
              }
              setDaySlots(dateSlots);
              setNextSubStep?.();
              setNextButtonActive(true);
            }}
            minDate={new Date()}
            views={['day']}
            shouldDisableDate={(date) => {
              const slotKey = dayjs(date).format('YYYY-MM-DD');

              const dateSlots = slots && slots[slotKey];
              const isSunday = dayjs(date).day() === 0;

              return !!dateSlots ? dateSlots.every((slot) => !slot?.available) : isSunday;
            }}
            loading={false}
          />
        </Box>
      ) : (
        <TimeSlots
          sx={{ width: isMobileView ? '220px' : '470px !important', margin: '0 auto' }}
          title={t('fertility.available_slot.avalible_slots')}
          selectedSlot={slot}
          setSelectedSlot={handleSelectSlot}
          date={date}
          disabledSlots={getDisabledSlots(daySlots)}
          handleBack={() => {
            setDaySlots([]);
            setPrevSubStep?.();
          }}
        />
      )}
    </Stack>
  );
};

export default AvailableSlotStep;
