import api from 'api';
import { CenterInfo } from 'pages/patient/centers/types';
import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { FertitilityCenterActionType } from 'store/actions/fertilityCenter.actions';

type ReturnParams = {
  center: CenterInfo | null;
  isLoading: boolean;
};

export const centerID = '70c803c1-d231-4701-abec-a4261b18d970';

export const useFertilityCenter = (): ReturnParams => {
  const { getState, dispatch } = useStore();

  const [center, setCenter] = useState<CenterInfo | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const { center: fertilityCenter } = getState().fertilityCenter;

    if (!fertilityCenter) {
      api.bnl.getCenter(centerID).then((res) => {
        dispatch({ type: FertitilityCenterActionType.ADD, center: res });
        setCenter(res);

        setLoading(false);
      });
    } else {
      setCenter(fertilityCenter);
      setLoading(false);
    }
  }, []);

  return { center, isLoading };
};
