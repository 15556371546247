import { Box, IconButton, SvgIcon, TableCell, TableRow, Typography } from '@mui/material';
import MissingData from 'components/missing-data';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { convertDate } from 'utils/func/Date.func';
import { redesignColors } from 'resources/theme/theme.colors';
import { ResultNew, ResultStatus } from 'models/result.model';
import { ReactComponent as RightArowIcon } from 'resources/icons/right_arrow.svg';
import { ReactComponent as EditTagIcon } from 'resources/icons/edit_tag.svg';
import { ReactComponent as AddTagIcon } from 'resources/icons/add_tag.svg';
import { ReactComponent as DownloadIcon } from 'resources/icons/download.svg';
import ThreeDotsMenu from 'components/three-dots-menu';
import { resultStatusColors, resultTagColors } from '../utils/colors';
import CustomChip from 'components/custom-chip';
import api from 'api';
import { useNotification } from 'hooks';

const DesktopResultsTableNew: FC<{ result: ResultNew; setAddedTagResult: (result: ResultNew) => void }> = ({ result, setAddedTagResult }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const getThreeDotsActions = (result: ResultNew) => [
    {
      title: `${result.user_tags[0]?.id ? t('common.action.modify') : t('common.action.add')} ${t('results.list.table.tag').toLowerCase()}`,
      icon: <SvgIcon component={result.user_tags[0]?.id ? EditTagIcon : AddTagIcon} sx={{ fontSize: 24, color: redesignColors.blue.main }} />,
      onClick: () => {
        setAddedTagResult(result);
      },
    },
    {
      title: t('common.action.download'),
      disabled: !result?.report,
      icon: <SvgIcon component={DownloadIcon} sx={{ fontSize: 24, color: redesignColors.blue.main }} />,
      onClick: () => {
        api.results
          .getFile('report', result.reference, result.report)
          .then((file: Blob) => {
            const url = window.URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = result.report;
            a.click();
          })
          .catch(() => {
            notification(t('common.errorLoadDocument'), 'error');
          });
      },
    },
  ];

  const firstTag = result.user_tags[0];
  const cellSx = { borderBottom: `1px solid ${redesignColors.grey.grey4}` };
  return (
    <TableRow
      hover
      sx={{
        '& > td > p, & > td > a > p': {
          ...(result.consultation_time === null && {
            fontWeight: 'bold',
          }),
        },
        border: '0px !important',
      }}
    >
      <TableCell sx={cellSx}>
        <Typography variant='body1' sx={{ textDecoration: 'underline' }}>
          {`${convertDate(result.sampling_time, true, 'yyyy-MM-dd', 'HH:mm:ss')}` || t('common.missingData')}
        </Typography>
      </TableCell>
      <TableCell sx={cellSx}>
        <Typography variant='body1'>{result.reference || <MissingData />}</Typography>
      </TableCell>
      <TableCell sx={cellSx}>
        <Typography variant='body1'>{`${result.patient.first_name} ${result.patient.last_name}` || <MissingData />}</Typography>
      </TableCell>
      <TableCell sx={cellSx}>
        <Typography variant='body1'>{`${result.issuer?.first_name ?? ''} ${result.issuer?.last_name ?? ''}` || t('common.missingData')}</Typography>
      </TableCell>
      <TableCell sx={cellSx}>
        <Typography variant='body1' sx={{ ...(result.status === 'complete' && { color: resultStatusColors[result.status], fontWeight: '500' }) }}>
          {result.status === 'partial' ? '-' : t('results.list.table.completeLabel')}
        </Typography>
      </TableCell>
      {result.status === ResultStatus.partial && (
        <TableCell sx={cellSx}>
          <Typography variant='body1'>-</Typography>
        </TableCell>
      )}

      <TableCell sx={cellSx}>
        {firstTag && <CustomChip variant='outlined' color='secondary' label={firstTag.name} sx={{ background: firstTag.color, color: resultTagColors[firstTag.color] }} />}
      </TableCell>

      <TableCell sx={cellSx}>
        <Box display='flex' alignItems='center'>
          {result.status === 'partial' ? (
            <IconButton onClick={() => setAddedTagResult(result)}>
              <SvgIcon
                component={result.user_tags[0]?.id ? EditTagIcon : AddTagIcon}
                sx={{
                  fontSize: 28,
                  color: redesignColors.grey.grey2,
                  '&:hover': {
                    color: redesignColors.blue.main,
                  },
                }}
              />
            </IconButton>
          ) : (
            <ThreeDotsMenu actions={getThreeDotsActions(result)} />
          )}

          <ContextLink
            to='DETAIL_RESULT'
            params={{
              id: result.reference,
            }}
          >
            <IconButton>
              <SvgIcon component={RightArowIcon} sx={{ fontSize: 28, color: redesignColors.grey.grey2 }} />
            </IconButton>
          </ContextLink>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default DesktopResultsTableNew;
