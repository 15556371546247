import { Card, CardContent, Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import { useLocale, useNotification } from 'hooks';
import { Directory, DirectoryFormValues, SearchType } from 'models/directory.model';
import { Paginate } from 'models/utils.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import FindForm from './components/FindDoctorForm';
import FindHeader from './components/FindDoctorHeader';
import FindResults from './components/FindResults';

const FindDoctor: FC = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const [globalState, setGlobalState] = useState<{
    specialities: { id: number; label: string }[];
    cities: { label: string }[];
  }>({
    specialities: [],
    cities: [],
  });
  const defaultValues: DirectoryFormValues = {
    type: SearchType.PROFESSIONAL,
    city: '',
    name: '',
    speciality: -1,
  };
  const methods = useForm<DirectoryFormValues>({ defaultValues });
  const { handleSubmit, setValue, watch, getValues, reset } = methods;
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [directoriesWrapper, setDirectoriesWrapper] = useState<Directory[]>([]);
  const paginatorData: GenericPaginator = {
    total: 0,
    size: DEFAULT_PAGINATION_SIZE,
    page: 1,
  };
  const [paginator, setPaginator] = useState(paginatorData);
  const { notification } = useNotification();

  const handlePaginationChange = (pagination: GenericPaginator) => {
    setPaginator(pagination);
  };

  const getSpecialities = useCallback(() => {
    api.doctors.getSpecialities(locale, getValues('type')).then((resSpecialities) => {
      const specialitiesData = resSpecialities.map((element: { id: number; name: string }) => {
        return {
          id: element.id ?? 0,
          label: element.name ?? '',
        };
      });

      setGlobalState((globalState) => ({
        ...globalState,
        specialities: specialitiesData,
      }));
    });
  }, [getValues, locale]);

  const getCities = useCallback(() => {
    api.doctors.getCities().then((resCities) => {
      const citiesData = resCities.map((element: { name: string }) => {
        return {
          label: element.name ?? '',
        };
      });

      setGlobalState((globalState) => ({
        ...globalState,
        cities: citiesData,
      }));
    });
  }, []);

  useEffect(() => {
    getSpecialities();
    getCities();
  }, [getSpecialities, getCities]);

  const getDoctors = useCallback(
    (size: number = paginator.size, start: number = paginator.page) => {
      setSubmitting(true);
      api.doctors
        .getDoctors(size, start, getValues('city'), getValues('name'), getValues('speciality'), getValues('type'))
        .then((res: Paginate<Directory>) => {
          setDirectoriesWrapper(res.results);
          setPaginator({
            total: res.total,
            size: res.size,
            page: res.page,
          });
        })
        .catch(() => notification(t('findDoctor.notification.error.noDoctors'), 'error'))
        .finally(() => setSubmitting(false));
    },
    [getValues, notification, paginator.size, paginator.page, t],
  );

  const onSubmit: SubmitHandler<DirectoryFormValues> = () => {
    getDoctors(paginator.size, 0);
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'type' && value.type !== undefined) {
        setValue('speciality', defaultValues.speciality);
        setValue('city', defaultValues.city);
        setValue('name', defaultValues.name);
        getDoctors(paginator.size, 0);
        getSpecialities();
      }
    });
    return () => subscription.unsubscribe();
  }, [getDoctors, paginator.size, watch, setValue, defaultValues.speciality, defaultValues.city, reset, getSpecialities, defaultValues.name]);

  useEffect(() => {
    getDoctors();
  }, [getDoctors]);

  const handleResetFilters = () => {
    reset(defaultValues);
    getDoctors();
  };

  return (
    <BodyTemplate title={t('bodyTemplate.findDoctor')}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FindHeader type={getValues('type')} setType={(type) => setValue('type', type, { shouldDirty: true, shouldValidate: true })} />
                    </Grid>
                    <Grid item xs={12}>
                      <FindForm type={getValues('type')} specialities={globalState.specialities} cities={globalState.cities} isSubmitting={isSubmitting} handleResetFilters={handleResetFilters} />
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FindResults directoriesWrapper={directoriesWrapper} pagination={paginator} onPaginationChange={handlePaginationChange} />
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default FindDoctor;
