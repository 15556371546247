import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Dialog, DialogContent, DialogTitle, SvgIcon, TextField, Typography } from '@mui/material';
import { useIsMobileView, useNotification } from 'hooks';
import { ReactComponent as DoctorIcon } from 'resources/icons/doctor.svg';
import { ReactComponent as AnalysesIcon } from 'resources/icons/catalogue_analyse.svg';
import { ResultNew } from 'models/result.model';
import { FC, useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';
import { convertDate } from 'utils/func/Date.func';
import TagColorPicker from './TagColorPicker';
import { resultTagBackgrounds, resultTagColors } from '../utils/colors';
import api from 'api';
import { PatientTag } from 'models/record.model';
import CustomChip from 'components/custom-chip';

interface TagForm {
  tagName: string;
  selectedTagName: string;
  color: string;
}

type Props = {
  result: ResultNew;
  tags: PatientTag[];
  open: boolean;
  onClose: () => any;
  onSubmit: () => any;
};

const sortTags = function (first: PatientTag, second: PatientTag) {
  if (first.name < second.name) {
    return -1;
  }
  if (first.name > second.name) {
    return 1;
  }
  return 0;
};

const TagDialog: FC<Props> = ({ open, onClose, onSubmit: onSubmitProp, result, tags }) => {
  const firstTag = result.user_tags[0];
  const defaultValues = firstTag
    ? { tagName: '', color: firstTag.color, selectedTagName: firstTag.name, id: firstTag.id }
    : { tagName: '', color: resultTagBackgrounds[0], selectedTagName: '', id: '' };
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const { notification } = useNotification();
  const [submitting, setSubmitting] = useState(false);
  const { register, handleSubmit, watch, reset, setValue } = useForm<TagForm>({
    mode: 'onChange',
    defaultValues,
  });

  const { tagName, color, selectedTagName } = watch();
  const handleClose = useCallback(() => {
    onClose();
    reset(defaultValues);
  }, [reset, onClose]);

  const onSubmit: SubmitHandler<TagForm> = useCallback(
    async (formData) => {
      setSubmitting(true);
      const existedTag = firstTag?.id && firstTag?.name === formData.selectedTagName ? firstTag : tags.find((tag) => tag.name === formData.selectedTagName);
      const editedTag = formData.selectedTagName ? { ...(existedTag && { id: existedTag?.id }), name: formData.selectedTagName, color: formData.color } : undefined;

      await api.results.addPatientTag(result.reference, editedTag);
      setSubmitting(false);
      onSubmitProp();
    },
    [handleClose, notification, t],
  );

  const autocompleteOptions = useMemo(
    () => [
      { id: 'new', value: `${t('result_doctor.tag_dialog.title')} "${tagName}"`, category: 'none', color: '', disabled: !tagName },
      ...tags.sort(sortTags).map((tag) => ({ id: tag.id, value: tag.name, color: tag.color, category: 'recent', disabled: false })),
    ],
    [tagName, tags],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 'fit-content',
          borderRadius: '28px',
          p: 3,
        },
      }}
      PaperProps={{ sx: { width: { xs: '300px', md: '440px' }, maxWidth: '600px !important' } }}
    >
      <DialogTitle sx={{ position: 'relative', p: 0, fontSize: '24px', mb: 2 }}>{firstTag ? t('result_doctor.tag_dialog.edit_title') : t('result_doctor.tag_dialog.title')}</DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ mb: 1.5 }}>
          <Typography variant='body1' sx={{ pb: 2, fontWeight: '600' }}>
            {result.patient.first_name} {result.patient.last_name}
          </Typography>
          {result.issuer && (
            <Typography variant='body1' sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <SvgIcon fontSize='small' component={DoctorIcon} sx={{ mr: 1, color: redesignColors.blue.main }} />
              {result.issuer.first_name} {result.issuer.last_name}
            </Typography>
          )}
          <Typography variant='body1' sx={{ display: 'flex', alignItems: 'center', pb: 1, fontSize: '14px' }}>
            <SvgIcon fontSize='small' component={AnalysesIcon} sx={{ mr: 1, color: redesignColors.blue.main }} />
            {convertDate(result.sampling_time, true, 'yyyy-MM-dd', 'HH:mm:ss')} | {result.reference}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Autocomplete
              inputValue={tagName}
              onChange={async (e, value) => {
                if (!value) {
                  return;
                }
                const existedTag = tags.find((tag) => tag.name === tagName);
                setValue('selectedTagName', value?.id === 'new' ? tagName : value?.value, { shouldDirty: true, shouldValidate: true });

                const existedColor = existedTag ? existedTag.color : value?.color;
                const newColor = existedColor || resultTagBackgrounds[0];
                setValue('color', newColor, { shouldDirty: true, shouldValidate: true });
                setValue('tagName', '');
              }}
              getOptionLabel={(option) => option.value}
              filterOptions={(options) => options}
              groupBy={(option) => option.category}
              getOptionDisabled={(option) => !!option?.disabled}
              renderGroup={({ group, children, key }) =>
                group === 'none' ? (
                  children
                ) : (
                  <div key={key}>
                    <Typography variant='subtitle2' sx={{ padding: '12px 16px', color: redesignColors.grey.grey2, textTransform: 'uppercase' }}>
                      {group === 'recent' ? t('result_doctor.tag_dialog.recent_tags') : group}
                    </Typography>
                    {children}
                  </div>
                )
              }
              renderOption={(props, option) => (
                <li {...props} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  {option.value}
                  {option.color && <Box sx={{ width: '8px', height: '8px', background: option.color, borderRadius: '50%' }} />}
                </li>
              )}
              options={autocompleteOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...register('tagName')}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px !important',
                      backgroundColor: redesignColors.blue.blue2,
                    },
                    '& .MuiAutocomplete-endAdornment': {
                      display: 'none',
                    },
                  }}
                  variant='outlined'
                  placeholder={t('result_doctor.tag_dialog.tag_name_placeholder')}
                  multiline={false}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 15,
                  }}
                />
              )}
              fullWidth
            />

            <Box display='flex' padding='16px 0 0 0'>
              {selectedTagName && <TagColorPicker color={color} setColor={(color) => setValue('color', color, { shouldDirty: true, shouldValidate: true })} />}
              {selectedTagName && (
                <CustomChip
                  variant='outlined'
                  color='secondary'
                  label={selectedTagName}
                  onDelete={() => setValue('selectedTagName', '', { shouldDirty: true, shouldValidate: true })}
                  sx={{ ml: 2, background: color, color: resultTagColors[color] }}
                />
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton variant='text' fullWidth={isMobileView} loading={submitting} sx={{ mr: 2 }} onClick={handleClose}>
                {t('common.action.cancel')}
              </LoadingButton>
              <LoadingButton type='submit' variant='text' fullWidth={isMobileView} loading={submitting} disabled={selectedTagName ? false : !firstTag}>
                {t('common.action.save')}
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default TagDialog;
