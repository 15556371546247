import { Autocomplete, Box, Button, Grid, IconButton, SvgIcon, TextField } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { SearchType } from 'models/directory.model';
import { is } from 'ramda';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSvg } from 'resources/icons/close_red.svg';

interface FindFormProps {
  specialities: { id: number; label: string }[];
  cities: { label: string }[];
  isSubmitting: boolean;
  type: SearchType;
  handleResetFilters: () => void;
}

const FindForm: FC<FindFormProps> = ({ specialities, cities, isSubmitting, type, handleResetFilters }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const [specialityInput, setSpecialityInput] = useState('');
  const [cityInput, setCityInput] = useState('');
  const { register, reset, setValue, watch } = useFormContext();

  const nameField = watch('name');
  const isAnyFieldFilled = useMemo(() => Boolean(cityInput || specialityInput || nameField), [cityInput, nameField, specialityInput]);

  useEffect(() => {
    setSpecialityInput('');
    setCityInput('');
  }, [specialities]);

  return (
    <Grid container spacing={isMobileView ? 1 : 3}>
      <Grid item xs={12} sx={{ display: 'flex' }}>
        <Grid container spacing={isMobileView ? 1 : 2}>
          <Grid item xs={12} md={4} lg={3}>
            <Autocomplete
              id='speciality'
              sx={{ width: '100%', marginRight: '24px' }}
              disableClearable
              disablePortal
              inputValue={specialityInput}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              onInputChange={(event, value, reason) => {
                if (event && event.type === 'blur') {
                  setSpecialityInput('');
                } else if (reason !== 'reset') {
                  setSpecialityInput(value);
                }
              }}
              onChange={(_, value) => {
                setValue('speciality', value?.id ?? -1);
                setSpecialityInput(value?.label ?? '');
              }}
              options={specialities}
              renderInput={(params) => {
                return (
                  <Box sx={{ position: 'relative' }}>
                    {is(String, specialityInput) && specialityInput.length > 0 && (
                      <IconButton
                        size='small'
                        sx={{ position: 'absolute', top: '50%', right: '30px', transform: 'translateY(-50%)', zIndex: 1 }}
                        onClick={() => {
                          setSpecialityInput('');
                          setValue('speciality', -1);
                        }}
                      >
                        <CloseSvg />
                      </IconButton>
                    )}
                    <TextField
                      {...params}
                      id='speciality'
                      variant='filled'
                      label={t('findDoctor.form.speciality')}
                      name='speciality'
                      inputProps={{ ...params.inputProps, sx: { width: 'calc(100% - 24px) !important', flexGrow: '0 !important' } }}
                    />
                  </Box>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              sx={{ width: '100%', marginRight: '24px' }}
              variant='filled'
              label={type === SearchType.PROFESSIONAL ? t('findDoctor.form.name') : t('findDoctor.form.institution')}
              {...register('name')}
              InputProps={{
                endAdornment: nameField.length > 0 && (
                  <IconButton
                    size='small'
                    sx={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', zIndex: 1 }}
                    onClick={() => {
                      setValue('name', '', { shouldValidate: true, shouldDirty: true });
                    }}
                  >
                    <CloseSvg />
                  </IconButton>
                ),
              }}
              inputProps={{
                name: 'name',
                id: 'name',
                sx: { width: 'calc(100% - 32px) !important', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Autocomplete
              id='city'
              sx={{
                width: '100%',
              }}
              disableClearable
              disablePortal
              inputValue={cityInput}
              {...register('city')}
              options={cities}
              onInputChange={(event, value, reason) => {
                if (event && event.type === 'blur') {
                  setCityInput('');
                } else if (reason !== 'reset') {
                  setCityInput(value);
                }
              }}
              onChange={(_, value) => {
                setCityInput(value?.label ?? '');
              }}
              renderInput={(params) => (
                <Box sx={{ position: 'relative' }}>
                  {is(String, cityInput) && cityInput.length > 0 && (
                    <IconButton
                      size='small'
                      sx={{ position: 'absolute', top: '50%', right: '30px', transform: 'translateY(-50%)', zIndex: 1 }}
                      onClick={() => {
                        setCityInput('');
                      }}
                    >
                      <CloseSvg />
                    </IconButton>
                  )}
                  <TextField
                    {...params}
                    id='city'
                    variant='filled'
                    label={t('findDoctor.form.city')}
                    name='city'
                    inputProps={{ ...params.inputProps, sx: { width: 'calc(100% - 24px) !important', flexGrow: '0 !important' } }}
                  />
                </Box>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} display='flex' alignItems='center'>
        <Button type='submit' variant='contained' disabled={isSubmitting || !isAnyFieldFilled} sx={{ marginRight: '16px', width: '160px' }}>
          {t('common.action.search')}
        </Button>
        {isAnyFieldFilled && (
          <Button
            variant='outlined'
            sx={{ width: '160px' }}
            disabled={isSubmitting}
            onClick={() => {
              reset();
              setSpecialityInput('');
              setCityInput('');

              handleResetFilters();
            }}
          >
            {t('findDoctor.searchBar.actions.resetFilter')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default FindForm;
