import * as geolib from 'geolib';
import { CenterInfo, Position } from '../types';
import { CentersFormValues } from 'models/centers.model';
import { MaxDistance } from '../components/CentersForm';
import { getStatus } from '.';
import { Holiday } from 'models/holiday.model';
import { Geolocation } from 'hooks/useGeolocation';

export const defaultValues: CentersFormValues = {
  city: '',
  specialities: ['all'],
  distance: 0,
  schedule: 'all',
  day: 1,
  time: -1,
  PRMAccessRequired: false,
  publicParkingRequired: false,
  labomobile: false,
  pharmacy: false,
  laboratoire: false,
  wifi: false,
  foodZone: false,
  pharmacyService: false,
};

export const sortFromFarToNear = (infos: CenterInfo[], position: Position, reverse: boolean): CenterInfo[] => {
  const sorted = infos.sort((first, second) => {
    const firstDistance = geolib.getDistance(position, { latitude: first.center.attributes.field_prelevement_latitude, longitude: first.center.attributes.field_prelevement_longitude });
    const secondDistance = geolib.getDistance(position, { latitude: second.center.attributes.field_prelevement_latitude, longitude: second.center.attributes.field_prelevement_longitude });
    return reverse ? secondDistance - firstDistance : firstDistance - secondDistance;
  });

  return sorted;
};
export const distanceFilter = (info: CenterInfo, distance: number, position?: Geolocation): boolean => {
  if ([defaultValues.distance, MaxDistance].includes(distance) || !position) {
    return true;
  }
  const centerPosition = {
    lat: parseFloat(info.center.attributes.field_prelevement_latitude.replaceAll(',', '.')),
    lng: parseFloat(info.center.attributes.field_prelevement_longitude.replaceAll(',', '.')),
  };
  const isPointWithinRadius = geolib.isPointWithinRadius(
    { latitude: centerPosition.lat, longitude: centerPosition.lng },
    { latitude: position.lat, longitude: position.lng },
    distance * 1000, // radius in meters
  );

  return isPointWithinRadius; // 2km in meters
};

export const servicesFilter = (info: CenterInfo, filterData: CentersFormValues | null): boolean => {
  const { PRMAccessRequired, publicParkingRequired, wifi, foodZone, pharmacyService } = filterData || {};
  const showEmptyList = (wifi || foodZone || pharmacyService) && !publicParkingRequired && !PRMAccessRequired;
  if (showEmptyList) {
    return false;
  }
  if (!PRMAccessRequired && !publicParkingRequired) {
    return true;
  }
  const isPRM = !!PRMAccessRequired && !!info.center.attributes.field_prelevement_handicape;
  const isParking = !!publicParkingRequired && !!info.center.attributes.field_prelevement_parking;

  return isPRM || isParking;
};

export const scheduleFilter = (info: CenterInfo, schedule: string, day: number, time: number, holidays: Holiday[]): boolean => {
  switch (schedule) {
    case 'open_now':
      const status = getStatus(info.center, holidays);
      return status === 'open';
    case 'choose_day_time':
      if (day <= 0 && time <= 0) {
        return true;
      }
      const hours = info.center.attributes.field_prelevement_horaires;
      return hours.some((horaire) => {
        if (day <= 0 || time <= 0) {
          return true;
        }

        return horaire.day === day && horaire.starthours <= time && time <= horaire.endhours;
      });
    default:
      return true;
  }
};
