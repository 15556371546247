import { Marker, useGoogleMap } from '@react-google-maps/api';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { FC, useEffect } from 'react';

type Props = {
  position: { lat: number; lng: number };
  opened: boolean;
  handleOpen: () => void;
  clusterer?: MarkerClusterer;
  icon?: string;
};

const MapMarker: FC<Props> = ({ position, opened, handleOpen, clusterer, icon }) => {
  const map = useGoogleMap();

  useEffect(() => {
    if (opened && map) {
      map.panTo(position);
      map?.setZoom(16);
    }
  }, [map, opened, position]);
  return <Marker position={position} onClick={handleOpen} animation={opened ? google.maps.Animation.BOUNCE : undefined} clusterer={clusterer} icon={icon} />;
};

export default MapMarker;
