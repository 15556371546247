import EmailIcon from '@mui/icons-material/AlternateEmail';
import LocationIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Button, Grid, IconButton, Stack, SvgIcon, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { ReactComponent as ParkingIcon } from 'resources/icons/parking.svg';
import { ReactComponent as AccessabilityIcon } from 'resources/icons/accessability.svg';
import Favorite from '@mui/icons-material/Favorite';
import config from 'config';
import { format, setDay } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import ptLocale from 'date-fns/locale/pt';
import { useAuthenticated, useIsMobileView } from 'hooks';
import { ReactComponent as HolidayModeIcon } from 'resources/icons/holiday_mode.svg';
import { Center, CenterIncludedFile, CenterIncludedService, CenterIncludedUpdate } from 'models/centers.model';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { waitFormatHours } from 'utils/func/Date.func';
import { getStatus } from '../utils';
import { redesignColors } from 'resources/theme/theme.colors';
import ConfirmDialog from 'components/confirm-dialog';
import { FavoriteLab } from '../types';
import formatCenterAddress from '../utils/formarCenterAddress';
import { Holiday } from 'models/holiday.model';

type Props = {
  centerInfo: {
    center: Center;
    file: CenterIncludedFile;
    services: CenterIncludedService[];
    updates: CenterIncludedUpdate[];
  };
  holidays: Holiday[];
  favorites?: FavoriteLab[];
  addFavoriteLab?: (value: string) => Promise<void>;
  deleteFavoriteLab?: (value: string) => Promise<void>;
  hideHeader?: boolean;
};

const CenterDetails = ({ centerInfo, hideHeader, favorites, addFavoriteLab, deleteFavoriteLab, holidays }: Props) => {
  const isAuthenticated = useAuthenticated();
  const [open, setOpen] = useState(false);
  const isMobileView = useIsMobileView();
  const { t, i18n } = useTranslation();
  const locale: string = useMemo((): string => {
    const lang = i18n.language.split('-').shift();
    return lang ? lang : 'en';
  }, [i18n.language]);

  const timeStatus = useMemo(() => getStatus(centerInfo.center, holidays), [centerInfo, holidays]);
  const localeMap = useCallback((lang: string) => {
    switch (lang) {
      case 'en':
        return enLocale;
      case 'de':
        return deLocale;
      case 'pt':
        return ptLocale;
      default:
        return frLocale;
    }
  }, []);

  const isFavorite = useMemo(() => {
    const ids = favorites?.map((favorite) => favorite.lab_id);
    return ids?.includes(centerInfo.center.id);
  }, [favorites, centerInfo]);

  const handleFavoriteClick = useCallback(async () => {
    if (!isFavorite && addFavoriteLab) {
      await addFavoriteLab(centerInfo.center.id);
      return;
    }
    setOpen(true);
  }, [isFavorite]);

  const handleDeleteFavorite = async () => {
    if (deleteFavoriteLab) {
      await deleteFavoriteLab(centerInfo.center.id);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12}>
        {!hideHeader && (
          <Grid item xs={3}>
            {centerInfo.file && (
              <Box
                component={'img'}
                src={`${config.websiteBionextApiUrl}${centerInfo.file.attributes.uri.url}`}
                alt={centerInfo.center.relationships.field_prelevement_photo.data.meta.alt}
                sx={{
                  width: 'auto',
                  height: '100%',
                  maxWidth: '100%',
                  maxHeight: '189px',
                  borderRadius: 2,
                }}
              />
            )}
          </Grid>
        )}
        <Grid container spacing={0} item xs={!hideHeader || 12}>
          {!hideHeader && (
            <>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='space-between' alignItems={timeStatus === 'holiday' ? 'flex-start' : 'center'} mb={2}>
                  <Box width='100%'>
                    {timeStatus === 'holiday' && (
                      <Box mb={2} display='flex' alignItems='center' sx={{ background: redesignColors.redLight, width: '99%', borderRadius: '20px', py: 1, px: 2 }}>
                        <HolidayModeIcon style={{ marginRight: 8 }} />
                        <Typography variant='body1' fontWeight='500'>
                          {t('centers.details.schedule.holiday')}
                        </Typography>
                      </Box>
                    )}
                    <Typography variant='h2'>{centerInfo.center.attributes.title}</Typography>
                  </Box>
                  {addFavoriteLab && deleteFavoriteLab && isAuthenticated && (
                    <IconButton onClick={handleFavoriteClick}>{isFavorite ? <Favorite sx={{ color: redesignColors.red }} /> : <FavoriteBorderIcon />}</IconButton>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>{formatCenterAddress(centerInfo.center)}</Typography>
              </Grid>
            </>
          )}
          {hideHeader && (
            <Grid item xs={12} sx={{ ml: 0.5 }}>
              <Button
                variant='link'
                startIcon={<LocationIcon />}
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${centerInfo.center.attributes.title} ${formatCenterAddress(centerInfo.center)}`)}`}
              >
                {t('centers.details.location.button')}
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sx={{ ml: 0.5 }}>
            <Button variant='link' startIcon={<EmailIcon sx={{ fill: redesignColors.blue.main }} />} href={`mailto:${centerInfo.center.attributes.field_prelevement_email}`}>
              {centerInfo.center.attributes.field_prelevement_email}
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ ml: 0.5 }}>
            <Button variant='link' startIcon={<PhoneIcon sx={{ fill: redesignColors.blue.main }} />} href={`tel:${centerInfo.center.attributes.field_prelevement_telephone}`}>
              {centerInfo.center.attributes.field_prelevement_telephone}
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ ml: 0.5 }}>
            <Box sx={{ color: timeStatus === 'open' ? redesignColors.green.dark : redesignColors.red, height: 20, fontSize: 16, fontWeight: 600 }}>{t(`centers.details.schedule.${timeStatus}`)}</Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1} sx={{ paddingTop: '24px !important' }}>
        {centerInfo.updates.map((updateItem) => (
          <Grid item xs={12}>
            <Box
              width='100%'
              sx={{ background: redesignColors.orangeLight, p: 2, borderRadius: '8px', boxShadow: `-2px 0 0 0 ${redesignColors.orange}`, '&>p': { margin: 0 } }}
              dangerouslySetInnerHTML={{
                __html: updateItem.attributes.field_laboratory_message.value,
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={isMobileView ? 12 : 3}>
          <Stack direction='row' spacing={2}>
            <Typography variant='subtitle1' fontWeight='700' sx={{ pb: 0.5 }}>
              {t('centers.details.schedule.title')}
            </Typography>
          </Stack>

          <Table size='small' padding='none'>
            <TableBody sx={{ px: 2 }}>
              {centerInfo.center.attributes.field_prelevement_horaires.map((h, index) => (
                <TableRow key={index} sx={{ border: 'none !important', backgroundColor: index % 2 !== 0 ? 'inherit' : redesignColors.dialogBG }}>
                  <TableCell sx={{ p: 1, fontWeight: 500 }}>{format(setDay(new Date(), h.day), 'EEEE', { locale: localeMap(locale) })}</TableCell>
                  <TableCell sx={{ p: 1, fontWeight: 500 }}>{`${waitFormatHours(h.starthours)} - ${waitFormatHours(h.endhours)}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={isMobileView ? 12 : 3}>
          <Typography variant='subtitle1' fontWeight='700' sx={{ pb: 0.5 }}>
            {t('centers.details.specialities.title')}
          </Typography>
          {centerInfo.services.map((service) => (
            <Typography key={service.id}>{service.attributes.name}</Typography>
          ))}
        </Grid>
        {(centerInfo.center.attributes.field_prelevement_handicape || centerInfo.center.attributes.field_prelevement_parking) && (
          <Grid item xs={isMobileView ? 12 : 2}>
            <Typography variant='subtitle1' fontWeight='700' sx={{ pb: 0.5 }}>
              {t('centers.details.services.title')}
            </Typography>
            {centerInfo.center.attributes.field_prelevement_parking && (
              <Box display='flex' alignItems='center' mb={0.5}>
                <SvgIcon fontSize='medium' component={AccessabilityIcon} sx={{ mr: 0.5 }} />
                <Typography>{t('centers.details.services.parking')}</Typography>{' '}
              </Box>
            )}
            {centerInfo.center.attributes.field_prelevement_handicape && (
              <Box display='flex' alignItems='center' mb={0.5}>
                <SvgIcon fontSize='medium' component={ParkingIcon} sx={{ mr: 0.5 }} />
                <Typography>{t('centers.details.services.handicape')}</Typography>
              </Box>
            )}
          </Grid>
        )}
        {centerInfo.center.attributes.field_prelevement_info_important !== null && (
          <Grid item xs={isMobileView ? 12 : 3}>
            <Typography variant='subtitle1' fontWeight='700' sx={{ pb: 0.5 }}>
              {t('centers.details.informations.title')}
            </Typography>
            <Typography sx={{ whiteSpace: 'pre-line' }}>{centerInfo.center.attributes.field_prelevement_info_important}</Typography>
          </Grid>
        )}
      </Grid>
      <ConfirmDialog
        title={t('centers.remove_favorite_dialog.title')}
        subtitle={t('centers.remove_favorite_dialog.subtitle', { value: centerInfo.center.attributes.title })}
        submitTitle={t('common.action.remove')}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleDeleteFavorite}
      />
    </Grid>
  );
};

export default CenterDetails;
