import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, Box, Button, ButtonBase, IconButton, SvgIcon, Typography } from '@mui/material';
import { useAuthenticated, useContextRedirection, useCurrentUser, useIsDoctor, useIsMobileView, useNotification } from 'hooks';
import { FC, useCallback, useState } from 'react';
import ContextLink from 'components/_base/ContextLink';
import CustomDivider from 'components/custom-divider';
import { Person, RelationInfo } from 'models/profile.model';
import { useDispatch } from 'react-redux';
import { convertToUser, setCurrentUser } from 'store/actions/session.actions';
import { getFullName, getInitials } from 'utils/func/Person.func';
import RoutePaths from 'utils/RoutePaths';
import HeaderMenu from './HeaderMenu';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const HeaderRightBloc: FC = () => {
  const isAuthenticated = useAuthenticated();
  const currentUser = useCurrentUser();

  const isDoctor = useIsDoctor();
  const isMobile = useIsMobileView();

  const [active, setActive] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const navigate = useContextRedirection();
  const { t } = useTranslation();
  const { notification } = useNotification();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setActive(!active);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActive(!active);
  };

  const handleChangeCurrentUser = useCallback(
    (currentUser: RelationInfo | Person) => {
      dispatch(setCurrentUser(convertToUser(currentUser)));
      notification(
        `${t('notifications.authentification.profileSwicher.success', {
          replace: { name: getFullName(currentUser) },
        })}`,
        'success',
      );
      navigate(RoutePaths['HOME']);
    },
    [dispatch, navigate, notification, t],
  );

  return (
    <>
      <>
        <ContextLink to='FAQ'>
          <Button variant={isAuthenticated ? 'header_light' : 'header_dark'}>{isMobile ? '?' : t('navigation.header.faq')}</Button>
        </ContextLink>
        <CustomDivider />
      </>
      <LanguageSwitcher />

      {isAuthenticated && !isMobile ? (
        <>
          <CustomDivider />
          {currentUser && (
            <>
              <ButtonBase sx={{ display: 'flex', py: 0.5 }} onClick={handleClick}>
                <Avatar
                  sx={{
                    width: 48,
                    height: 48,
                    fontSize: '18px',
                    mr: 2,
                  }}
                >
                  {getInitials(currentUser)}
                </Avatar>
                <Box>
                  <Typography variant='body1' fontSize='18px' fontWeight='600'>
                    {getFullName(currentUser)}
                  </Typography>
                  {!isDoctor && (
                    <Typography textAlign='left' variant='body2'>
                      MYLAB ID: {currentUser?.id}
                    </Typography>
                  )}
                </Box>
                <IconButton size='small' onClick={handleClick}>
                  <SvgIcon fontSize='small' component={ExpandMoreIcon} />
                </IconButton>
              </ButtonBase>
              <HeaderMenu open={open} handleClose={handleClose} anchorEl={anchorEl} handleChangeUser={handleChangeCurrentUser} />
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default HeaderRightBloc;
