import { Grid, Typography } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDateCalendar from 'components/custom-date-calendar';
import TimeSlots, { defaultSlots } from 'components/slot-picker';
import dayjs from 'dayjs';
import { CenterInfo } from 'pages/patient/centers/types';

const getDisabledSlots = (date: Date | null, centerInfo?: CenterInfo | null) => {
  const dayOfWeek = dayjs(date).day();
  const currentDayOfWeek = centerInfo?.center?.attributes?.field_prelevement_horaires?.find((horaries) => horaries.day === dayOfWeek);

  if (!currentDayOfWeek) {
    return [];
  }
  const { endhours, starthours } = currentDayOfWeek;

  const timeStringToNumber = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 100 + minutes;
  };

  return defaultSlots.filter((slot) => {
    const timeInt = timeStringToNumber(slot);
    return timeInt < starthours || timeInt > endhours;
  });
};
const AvailaleSlot: FC<{
  handleIsActiveNextButton: (isActive?: boolean) => void;
  setDate: (date: Date | null) => void;
  centerInfo?: CenterInfo | null;
  date: Date | null;
  setShowSlots: (showSlots: boolean) => void;
  setSlot: (showSlots: string) => void;
  showSlots: boolean;
  slot: string;
}> = ({ handleIsActiveNextButton, date, setDate, centerInfo, showSlots, setShowSlots, setSlot, slot }) => {
  const { t } = useTranslation();

  const isMobileView = useIsMobileView();

  return (
    <Grid item xs={12} mb='10%'>
      {showSlots && date ? (
        <TimeSlots
          sx={{ width: isMobileView ? '220px' : '470px !important', margin: '0 auto' }}
          title={t('fertility.available_slot.avalible_slots')}
          selectedSlot={slot}
          disabledSlots={getDisabledSlots(date, centerInfo)}
          setSelectedSlot={(slot) => {
            setSlot(slot);
            handleIsActiveNextButton();
          }}
          date={date}
          handleBack={() => setShowSlots(false)}
        />
      ) : (
        <>
          <Typography sx={{}} fontSize='20px' fontWeight='600' textAlign='center'>
            {t('fertility.available_slot.select_date')}
          </Typography>
          <CustomDateCalendar
            value={date}
            onChange={(newDate) => {
              setDate(newDate);
              handleIsActiveNextButton();
            }}
            minDate={new Date()}
            shouldDisableDate={(date) => {
              const dayOfWeek = dayjs(date).day();
              const currentDayOfWeek = centerInfo?.center?.attributes?.field_prelevement_horaires?.find((horaries) => horaries.day === dayOfWeek);
              return !currentDayOfWeek;
            }}
            loading={false}
          />
        </>
      )}
    </Grid>
  );
};

export default AvailaleSlot;
