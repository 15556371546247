import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Box, Grid } from '@mui/material';
import { ResultStatus } from 'models/result.model';
import { redesignColors } from 'resources/theme/theme.colors';
import Tabs from 'components/tabs';

const ResultStatusTabs: FC<{
  results: any[];
  processResultsCounter: number;
  completeResultsCounter: number;
  selectedTab: ResultStatus;
  setSelectedTab: (status: ResultStatus) => void;
}> = ({ results, selectedTab, setSelectedTab, processResultsCounter, completeResultsCounter }) => {
  const { t } = useTranslation();
  const tabs = [
    {
      label: (
        <Box>
          {t(`result_doctor.processing`)}
          {/* <ResultsCounterDot resultsCount={processResultsCounter} /> */}
        </Box>
      ),
      value: ResultStatus.partial,
    },
    {
      label: (
        <Box>
          {t(`result_doctor.completed`)}
          {/* <ResultsCounterDot resultsCount={completeResultsCounter} /> */}
        </Box>
      ),
      value: ResultStatus.complete,
    },
  ];
  return (
    <Grid item xs={12}>
      <Tabs value={selectedTab} onChange={(value) => setSelectedTab(value)} tabs={tabs} />
    </Grid>
  );
};

const ResultsCounterDot = ({ resultsCount }: { resultsCount: number }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        right: '5px',
        top: '5px',
        background: redesignColors.red,
        color: '#FFFFFF',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '8px',
        width: '14px',
        height: '14px',
      }}
    >
      {resultsCount}
    </Box>
  );
};
export default ResultStatusTabs;
