import { Badge, Box, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, SvgIcon, Tooltip, Typography, Button, ListItemButton, BadgeProps } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconBack } from 'resources/icons/back.svg';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import ContextLink from 'components/_base/ContextLink';
import { Person, RelationInfo } from 'models/profile.model';
import { useAuthenticated, useIsDoctor, useIsMobileView, useLocale, useNotification, useContextRedirection, useIsLaboratory, useIsAdmin, useIsPatient } from 'hooks';
import { LeftDrawerItem } from 'models/utils.model';
import { Location, useLocation } from 'react-router-dom';
import { ReactComponent as ExternalLinkIcon } from 'resources/icons/external-link.svg';
import { closeLeftDrawer, openLeftDrawer } from 'store/actions/navMenu.actions';
import { convertToUser, setCurrentUser } from 'store/actions/session.actions';
import { GlobalState } from 'store/reducers';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_OPEN } from 'utils/Constants';
import RoutePaths from 'utils/RoutePaths';
import { getFullName } from 'utils/func/Person.func';
import { leftDrawerMenuDoctor } from './LeftDrawerMenuDoctor';
import { leftDrawerMenuPatient } from './LeftDrawerMenuPatient';
import { useTranslation } from 'react-i18next';
import UsersList from './UsersList';
import { leftDrawerMenuLaboratory } from './LeftDrawerMenuLaboratory';
import { leftDrawerMenuAdmin } from './LeftDrawerMenuAdmin';
import { redesignColors } from 'resources/theme/theme.colors';

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH_OPEN,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `${DRAWER_WIDTH_CLOSED}px`,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: DRAWER_WIDTH_OPEN,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const LeftDrawer: FC = () => {
  const { t } = useTranslation();
  const isOpen = useSelector((state: GlobalState) => state.navMenu.isOpen);
  const resultsUnread = useSelector((state: GlobalState) => state.session.badgesCount?.dossiers ?? 0);
  const upcomingAppointments = useSelector((state: GlobalState) => state.session.badgesCount?.appointments ?? 0);
  const eudccUnread = useSelector((state: GlobalState) => state.session.badgesCount?.eudccs ?? 0);
  const invoicesToPay = useSelector((state: GlobalState) => state.session.badgesCount?.invoices ?? 0);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const authenticateUser = useSelector((state: GlobalState) => state.session.authenticateUser);
  const { notification } = useNotification();
  const dispatch = useDispatch();
  const handleDrawerClose = useCallback(() => {
    dispatch(closeLeftDrawer());
  }, [dispatch]);

  const handleDrawerOpen = useCallback(() => {
    dispatch(openLeftDrawer());
  }, [dispatch]);

  const usePrevious = (value: Location): Location | undefined => {
    const ref = useRef<Location>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  /**
   * Close left drawer when route change on mobile view
   */
  const location: Location = useLocation();
  const prevLocation = usePrevious(location);
  const navigate = useContextRedirection();
  const isAuthenticated = useAuthenticated();
  const isMobileView = useIsMobileView();
  const isDoctor = useIsDoctor();
  const isAdmin = useIsAdmin();
  const isLaboratory = useIsLaboratory();
  const isPatient = useIsPatient();

  const locale = useLocale();
  useEffect(() => {
    if (prevLocation && location.pathname !== prevLocation.pathname) {
      handleDrawerClose();
    }
  }, [location, prevLocation, handleDrawerClose]);

  const menuItems = isAdmin ? leftDrawerMenuAdmin() : isDoctor ? leftDrawerMenuDoctor() : isLaboratory ? leftDrawerMenuLaboratory() : isPatient ? leftDrawerMenuPatient(locale) : [];

  const isCurrentPage = (menuItem: LeftDrawerItem): boolean => {
    const routeToReach = RoutePaths[menuItem.link];
    const path = typeof routeToReach === 'string' ? routeToReach : null;
    return path ? location.pathname.includes(path) : false;
  };

  const handleChangeCurrentUser = useCallback(
    (currentUser: RelationInfo | Person) => {
      dispatch(setCurrentUser(convertToUser(currentUser)));
      notification(
        `${t('notifications.authentification.profileSwicher.success', {
          replace: { name: getFullName(currentUser) },
        })}`,
        'success',
      );
      navigate(RoutePaths['HOME']);
    },
    [dispatch, navigate, notification, t],
  );

  const getCount = (menu: LeftDrawerItem) => {
    switch (menu.link) {
      case 'INVOICES_OPEN':
        return invoicesToPay;
      case 'RESULTS':
        return resultsUnread;
      case 'APPOINTMENT':
        return upcomingAppointments;
      case 'EUDCC_CERTIFICATES':
        return eudccUnread;
      default:
        return 0;
    }
  };

  return (
    <>
      {isAuthenticated && (
        <Drawer
          variant='permanent'
          open={isOpen}
          anchor='left'
          sx={{
            position: 'relative',
            zIndex: 1202,
            ...(isOpen &&
              isMobileView && {
                width: '100%',
              }),
            '& .MuiDrawer-paper': { overflow: 'auto', width: isMobileView ? '100% !important' : 'auto' },
          }}
        >
          <Box
            sx={{
              height: isOpen ? '80px' : 'auto',
              padding: (theme) => theme.spacing(2, 0),
              width: isOpen ? '90%' : '60%',
              display: 'flex',
              justifyContent: isOpen ? 'flex-end' : 'center',
              margin: '0 auto',
              borderBottom: `1px solid ${redesignColors.grey.grey4}`,
            }}
          >
            {isOpen ? (
              <IconButton onClick={handleDrawerClose}>
                <SvgIcon fontSize='small' component={IconBack} sx={{ color: redesignColors.dark }} />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleDrawerOpen}
                sx={{
                  display: { sm: 'flex' },
                  width: 44,
                  height: 44,
                }}
              >
                <MenuIcon sx={{ fontSize: 24 }} />
              </IconButton>
            )}
          </Box>
          <List
            sx={{
              py: 1,
              px: 0,
            }}
          >
            {!isDoctor && isMobileView && (
              <>
                <ListItem
                  sx={{
                    justifyContent: 'center',
                    padding: (theme) => theme.spacing(2, 4),
                    fontWeight: 'bold',
                  }}
                >
                  {currentUser && getFullName(currentUser)}
                </ListItem>
                <UsersList handleChangeUser={handleChangeCurrentUser} />
                <ListItemButton
                  sx={{
                    padding: (theme) => theme.spacing(2, 4),
                    '& a': {
                      marginLeft: '-6px',
                    },
                    '& span': {
                      fontWeight: 'bold !important',
                    },
                  }}
                >
                  <ContextLink to='PROFILE'>
                    <Button>{t('navigation.header.profile')}</Button>
                  </ContextLink>
                </ListItemButton>
                <ListItemButton
                  sx={{
                    padding: (theme) => theme.spacing(2, 4),
                    '& a': {
                      marginLeft: '-6px',
                    },
                    '& span': {
                      fontWeight: 'bold !important',
                    },
                  }}
                >
                  {!currentUser?.is_relative ? (
                    <ContextLink to='PROFILE_RELATIVE'>
                      <Button>{t('navigation.header.relative')}</Button>
                    </ContextLink>
                  ) : (
                    <ListItemText
                      onClick={authenticateUser && (() => handleChangeCurrentUser(authenticateUser))}
                      sx={{
                        display: isOpen ? 'flex' : 'none',
                        alignSelf: 'center',
                        margin: (theme) => theme.spacing(0),
                        '&>span': {
                          whiteSpace: 'break-spaces',
                          maxHeight: '50px',
                        },
                      }}
                      primary={t('navigation.header.backToMainAccount')}
                    />
                  )}
                </ListItemButton>
                <Divider sx={{ width: '90%', my: 1 }} />
              </>
            )}
            {menuItems.map((el: LeftDrawerItem, key: number) => {
              return (
                <Tooltip key={key} title={t(el.title)} placement='right' disableHoverListener={isOpen}>
                  <ListItemButton
                    sx={{
                      padding: (theme) => (isOpen ? theme.spacing(1, 3, 1, 1) : theme.spacing(1, 0)),
                      ...(isCurrentPage(el) && {
                        '& span': {
                          fontWeight: 'bold !important',
                        },
                      }),
                    }}
                    disabled={el.isActivated === false}
                  >
                    <ContextLink to={el.link} params={el.params} external={el.external ?? false} fullWidth disabled={el.isActivated === false}>
                      <Box display='flex'>
                        <Box
                          sx={{
                            display: 'flex',
                            width: 44,
                            height: 44,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: '15px',
                            marginRight: '15px !important',
                            ...(isCurrentPage(el) && {
                              backgroundColor: redesignColors.blue.main,
                              borderRadius: '8px !important',
                            }),
                          }}
                        >
                          <ListItemAvatar
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <StyledBadge
                              variant='dot'
                              badgeContent={isOpen ? 0 : getCount(el)}
                              overlap='circular'
                              sx={{
                                '& .MuiBadge-dot': {
                                  top: '-2px',
                                  right: '-2px',
                                },
                                ...(isCurrentPage(el) && {
                                  '& .blue-element': {
                                    fill: `${redesignColors.white} !important`,
                                  },
                                }),
                              }}
                            >
                              <SvgIcon
                                sx={{
                                  color: 'primary.contrastText',
                                }}
                                component={el.icon}
                              />
                            </StyledBadge>
                          </ListItemAvatar>
                        </Box>
                        <Box display='flex' alignItems='center' width='100%'>
                          <ListItemText
                            sx={{
                              display: isOpen ? 'flex' : 'none',
                              alignSelf: 'center',
                              margin: (theme) => theme.spacing(0),
                              '&>span': {
                                whiteSpace: 'break-spaces',
                                maxHeight: '50px',
                                fontWeight: '500',
                              },
                            }}
                            primary={t(el.title)}
                          />
                          {isOpen && (
                            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', width: '20px' }}>
                              <StyledBadge badgeContent={getCount(el)} sx={{ right: '0px' }} />
                            </Box>
                          )}
                          {el.external && (
                            <Box
                              sx={{
                                display: isOpen ? 'flex' : 'none',
                                flexGrow: 1,
                                justifyContent: 'flex-end',
                              }}
                            >
                              <SvgIcon
                                sx={{
                                  fontSize: 20,
                                  color: 'text.secondary',
                                  filter: 'brightness(1.4)',
                                }}
                                component={ExternalLinkIcon}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </ContextLink>
                  </ListItemButton>
                </Tooltip>
              );
            })}
            {isMobileView && (
              <>
                <Divider />
                <ListItemButton
                  sx={{
                    padding: (theme) => theme.spacing(2, 4),
                    '& a': {
                      marginLeft: '-6px',
                    },
                    '& span': {
                      fontWeight: 'bold !important',
                    },
                  }}
                >
                  <ContextLink to='LOGOUT'>
                    <Typography color='error'>{t('navigation.header.logout')}</Typography>
                  </ContextLink>
                </ListItemButton>
              </>
            )}
          </List>
        </Drawer>
      )}
    </>
  );
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    background: redesignColors.red,
    color: theme.palette.common.white,
  },
}));

export default LeftDrawer;
