import { Box, Card, CardContent, Grid, Slider, styled, Tooltip, tooltipClasses, TooltipProps, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import { Result } from 'models/result.model';
import { redesignColors } from 'resources/theme/theme.colors';
import { convertDate } from 'utils/func/Date.func';
import { calculateRatioSlider, displayLimit } from 'pages/shared/results/DetailTabPanel/DetailTabPanel.func';
import { formatToLocaleNumber } from 'utils/func/Number.func';

export interface PatientDetailsProps {
  result: Result;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: redesignColors.green.common,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: `1px solid ${redesignColors.green.common}`,
  },
}));

const LastResultCard: FC<PatientDetailsProps> = ({ result }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { ratio } = useMemo(() => {
    const ratio = calculateRatioSlider(result.limit_min || '0.0', result.limit_max, result.value);
    const color = typeof ratio === 'number' && ratio >= 0 && ratio <= 100 ? palette.graph.success : palette.graph.error;

    return { ratio, color };
  }, [result]);

  const tooltipTitle = formatToLocaleNumber(result.value) ? `${formatToLocaleNumber(result.value)} ${result.unit}` : t('results.details.tableResults.label.waitingLabel');

  return (
    <Card sx={{ border: `1px solid ${redesignColors.grey.grey4}`, width: { xs: '100%', md: '50%' } }}>
      <CardContent>
        <Grid container>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h2' mb={2} fontWeight='500'>
              {t('result.doctor.info.last_result')}
            </Typography>
            <Typography variant='body1' mb={2} color={redesignColors.grey.grey2}>
              {convertDate(result.sampling_time, false, 'yyyy-MM-dd hh:mm')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box>
              {result.limit_max && result.value && (
                <StyledTooltip
                  placement='top'
                  title={tooltipTitle}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -12],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <Box>
                    <Slider color='secondary' size='medium' sx={{ width: '100%', paddingTop: 0 }} disabled defaultValue={ratio} />
                  </Box>
                </StyledTooltip>
              )}
            </Box>
            <Box sx={{ '& p': { fontSize: '16px' } }}>{displayLimit(result.limit_min, result.limit_max, null, null)}</Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default LastResultCard;
