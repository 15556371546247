import { useNotification } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { GeolocationActionType } from 'store/actions/geolocation.actions';

export type Geolocation = { lat: number; lng: number };

type ReturnParams = {
  location: Geolocation | undefined;
  handleGeolocation: () => void;
  hasLocation: boolean;
  isLoadingGeolocation: boolean;
};

export const useGeolocation = (): ReturnParams => {
  const { notification } = useNotification();
  const { getState, dispatch } = useStore();

  const { t } = useTranslation();

  const [location, setLocation] = useState<Geolocation | undefined>(undefined);
  const [hasLocation, setHasLocation] = useState(false);
  const [isLoadingGeolocation, setLoadingGeolocation] = useState(false);

  const handleGeolocation = useCallback(() => {
    setLoadingGeolocation(true);

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const newLocation = { lat: pos.coords.latitude, lng: pos.coords.longitude };
          if (location?.lat !== newLocation.lat || location?.lng !== newLocation.lng) {
            setLocation(newLocation);
            dispatch({ type: GeolocationActionType.ADD, geolocation: newLocation });
          }

          setLoadingGeolocation(false);
        },
        (error) => {
          notification(t('centers.notification.errorGeolocation'), 'error');
          setLoadingGeolocation(false);
        },
      );
    } else {
      notification(t('centers.notification.geolocationNotActivated'), 'error');
      setLoadingGeolocation(false);
    }
  }, [notification, t]);

  useEffect(() => {
    setLoadingGeolocation(true);

    const { geolocation } = getState().geolocation;
    if (geolocation) {
      setLocation(geolocation);
    }
    setLoadingGeolocation(false);
  }, []);

  useEffect(() => {
    if (location) {
      return setHasLocation(true);
    }
    setHasLocation(false);
  }, [location]);

  return { location, handleGeolocation, hasLocation, isLoadingGeolocation };
};
