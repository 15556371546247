import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Center, FieldPrelevementHoraire } from 'models/centers.model';
import { Holiday } from 'models/holiday.model';
import { waitFormatHours } from 'utils/func/Date.func';

export const getStatus = (center: Center, holidays: Holiday[]) => {
  const holidayDateFormat = 'YYYYMMDD[T]HHmmss';

  const now = new Date();

  const isHoliday = holidays.find(({ attributes }) => {
    return now.getTime() >= dayjs(attributes.dtstart, holidayDateFormat).valueOf() && now.getTime() < dayjs(attributes.dtend, holidayDateFormat).valueOf();
  });

  if (isHoliday) {
    return 'holiday';
  }

  for (let schedule of center.attributes.field_prelevement_horaires) {
    if (now.getDay() === schedule.day) {
      const startHour = waitFormatHours(schedule.starthours).split(':');
      const stopHour = waitFormatHours(schedule.endhours).split(':');

      const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(startHour[0]), parseInt(startHour[1]), 0);
      const stop = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(stopHour[0]), parseInt(stopHour[1]), 0);
      if (now > start && now < stop) {
        return 'open';
      }
    }
  }
  return 'close';
};

export const getStatusDescription = (status: 'close' | 'open' | 'holiday', workHours: FieldPrelevementHoraire[], t: TFunction<'translation', undefined>) => {
  const now = new Date();
  const todayWorkHoursIndex = workHours.findIndex((dayWorkHours) => now.getDay() === dayWorkHours.day);
  const todayWorkHours = workHours[todayWorkHoursIndex];
  if (status === 'holiday') {
    return '';
  }
  if (status === 'open') {
    const stopHour = waitFormatHours(todayWorkHours.endhours);

    return `${t('centers.will_close')} ${stopHour}`;
  }

  const nextDayWorkHours = workHours[todayWorkHoursIndex + 1] || workHours[0];
  const startHour = waitFormatHours(nextDayWorkHours.starthours);

  return `${t('centers.will_open')} ${startHour}`;
};
