import { Box, Button, Card, CardContent, CircularProgress, Grid, SvgIcon, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useTranslation } from 'react-i18next';
import { Patient } from 'models/patients.model';
import { Selection } from 'models/record.model';
import { ReactComponent as SortIcon } from 'resources/icons/sort.svg';
import { FilterResultsDate, FilterResultsFormValues, Result, ResultGraph, ResultHistory as ResultHistoryType, ResultObject } from 'models/result.model';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { defaultValues, getFilterDateValues } from 'pages/patient/results/utils/filters';
import HistoryResultsFilterDrawer from 'pages/doctor/results/components/HistoryResultsFilterDrawer';
import GraphCardContent from '../result-info/GraphCardContent';
import { convertDate } from 'utils/func/Date.func';
import { redesignColors } from 'resources/theme/theme.colors';

export interface CustomizedState {
  patient: Patient;
  selection: Selection[];
}

const ResultHistory: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<FilterResultsFormValues | null>(null);

  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const { patient, selection } = location.state as CustomizedState;
  const referencesArray: string[] = useMemo(() => [], []);
  const [historyDataValues, setHistoryDataValues] = useState<ResultHistoryType[]>([]);
  const { t } = useTranslation();
  const historyDataValuesArray: ResultGraph[] = [];
  useEffect(() => {
    setLoading(true);
    const { date, datesRangeFrom, datesRangeTo } = filterData || defaultValues;

    selection.map((el: { ref: string; name: string }) => referencesArray.push(el.ref));
    api.results
      .getHistoryFormResults(patient.id, referencesArray)
      .then((response: ResultHistoryType[]) => {
        const filtered = response.map((item) => {
          const history = Object.values(item.history).reduce(
            (acc, cur) => {
              const isFiltered = getFilterDateValues(cur as any, date, datesRangeFrom, datesRangeTo);
              if (isFiltered) {
                return { ...acc, [cur.sampling_time]: cur };
              }
              return acc;
            },
            {} as Record<string, Result>,
          );
          return { ...item, history };
        });

        setHistoryDataValues(filtered);
      })
      .finally(() => setLoading(false));
  }, [patient.id, referencesArray, selection, filterData, defaultValues]);

  historyDataValues.forEach((el: ResultObject, key: number) => {
    const historyArray: Result[] = [];
    Object.keys(el.history).forEach((el2, key2) => {
      historyArray.push(el.history[el2]);
    });
    if (!historyArray.length) {
      return;
    }
    historyDataValuesArray.push({
      name: historyArray[0].name,
      reference: el.reference,
      history: historyArray.filter((value) => value.sampling_time !== null),
    });
  });
  const { date, datesRangeFrom, datesRangeTo } = filterData || defaultValues;

  return (
    <BodyTemplate title={`${historyDataValuesArray[0]?.name} ${t('result.doctor.info.history').toLowerCase()}`} backButton>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Card sx={{ width: { xs: '100%', md: '50%' } }}>
              <Typography variant='h2' mb={2} fontWeight='500'>
                {t('result.doctor.info.history')}
              </Typography>
              <Button
                variant='outlined'
                sx={{ mb: 2, fontSize: '14px', position: 'relative', border: `1px solid ${redesignColors.grey.grey4}` }}
                startIcon={<SvgIcon component={SortIcon} sx={{ color: redesignColors.blue.main }} />}
                onClick={() => setOpen(true)}
              >
                {t('result_doctor.history.sort_by_date')}:{' '}
                {date === FilterResultsDate.datesRange
                  ? `${convertDate(datesRangeFrom, false, 'yyyy-MM-dd')} - ${convertDate(datesRangeTo, false, 'yyyy-MM-dd')}`
                  : t(`result_doctor.filter_drawer.${filterData?.date || defaultValues.date}`)}
              </Button>
              {!!historyDataValuesArray?.length ? (
                <GraphCardContent graph={historyDataValuesArray[0]} />
              ) : (
                <Typography variant='body1' fontWeight='500' mt={2}>
                  {t('result_doctor.history.no_history_results')}
                </Typography>
              )}
            </Card>
          </Grid>
        </Grid>
      )}
      <HistoryResultsFilterDrawer open={open} onClose={() => setOpen(false)} onApply={(data) => setFilterData(data)} defaultFilters={filterData || defaultValues} onClear={() => setFilterData(null)} />
    </BodyTemplate>
  );
};

export default ResultHistory;
