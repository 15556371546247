import { Patient } from './patients.model';
import { MedicalStaff, PatientTag } from './record.model';

export interface Result {
  name: string;
  type: string;
  flag: string;
  analysis_reference: string;
  analysis_code: string;
  value: string;
  unit: string;
  limit_min: string;
  limit_max: string;
  limit_text: string;
  limit_html: string;
  priority: number;
  status: string;
  last_report_time: Date;
  external_comment: string;
  sampling_time: string;
  sample: string;
  sample_variable: string;
  validator: string;
  tabulated: boolean;
  is_abnormal: boolean;
  dossier_reference: string;
  antecedent: Result;
  result_code: string;
  analysis_method: string;
  name_hint: string;
}

export type resultTaggedEl = {
  has_abnormal_result: boolean;
  augmented_time: string;
  cc: [];
  consultation_time: null;
  has_bmr: boolean;
  issuer: MedicalStaff;
  last_report_time: string;
  patient: Patient;
  prescription: string;
  prescription_date: null;
  priority: 1;
  reference: string;
  sampling_time: string;
  status: string;
  tagged: boolean;
};

export type ResultHistory = {
  reference: string;
  name: string;
  history: Record<string, Result>;
};

export type ResultGraph = {
  name: string;
  reference: string;
  history: Result[];
};

export type ResultObject = {
  reference: string;
  history: { [key: string | number]: Result };
};

export enum ResultStatus {
  partial = 'partial',
  complete = 'complete',
}

export type ResultIssuer = { id: number; last_name: string; first_name: string };
export type ResultNew = {
  reference: string;
  has_abnormal_result: boolean;
  last_report_time: string;
  sampling_time: string;
  prescription_date: string;
  patient: { id: number; last_name: string; first_name: string };
  issuer: ResultIssuer;
  cc: any[];
  status: ResultStatus;
  priority: number;
  tagged: boolean;
  prescription: null;
  consultation_time: string;
  name: string;
  augmented_time: null;
  user_tags: PatientTag[];
  report: string;
};

export enum FilterResultsDate {
  all = 'all_time',
  today = 'today',
  lastMonth = 'last_month',
  last12Month = 'last_12_months',
  datesRange = 'choose_dates',
}
export type FilterResultsFormValues = {
  doctors: number[];
  tags: string[];
  date?: FilterResultsDate;
  datesRangeFrom?: Date;
  datesRangeTo?: Date;
};
