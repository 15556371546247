import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { FilterResultsDate, FilterResultsFormValues, ResultNew } from 'models/result.model';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const defaultValues: FilterResultsFormValues = {
  doctors: [],
  tags: [],
  date: undefined,
  datesRangeFrom: undefined,
  datesRangeTo: undefined,
};

export const getFilterDateValues = (date: FilterResultsDate, datesRangeFrom?: Date, datesRangeTo?: Date): { minDate?: any; maxDate?: any } | null => {
  const dateFormat = 'YYYY-MM-DD';
  const today = dayjs().startOf('day').format(dateFormat);

  switch (date) {
    case FilterResultsDate.today:
      return { minDate: today };
    case FilterResultsDate.lastMonth:
      return { minDate: dayjs().subtract(1, 'month').endOf('day').format(dateFormat) };
    case FilterResultsDate.last12Month:
      return { minDate: dayjs().subtract(1, 'year').endOf('day').format(dateFormat) };

    case FilterResultsDate.datesRange: {
      const maxDate = datesRangeTo && dayjs(datesRangeTo).startOf('day').format(dateFormat);
      const minDate = datesRangeFrom && dayjs(datesRangeFrom).startOf('day').format(dateFormat);
      return {
        minDate: minDate && minDate !== today ? minDate : undefined,
        maxDate: maxDate && maxDate !== today ? maxDate : undefined,
      };
    }

    default:
      return null;
  }
};
