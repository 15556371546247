import { HistoryValues } from 'models/graph.model';
import { FC } from 'react';
import dayjs from 'dayjs';
import { redesignColors } from 'resources/theme/theme.colors';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceArea, ResponsiveContainer, ComposedChart } from 'recharts';

const GraphHistory: FC<HistoryValues> = ({ historyDataValues }) => {
  const firstValue = historyDataValues[0];
  const graphValues = firstValue.history.map((history) => +history.value);
  const maxValue = Math.max(...graphValues);
  const minValue = Math.min(...graphValues);

  const data = firstValue.history
    .map((el) => ({
      uv: parseFloat(el.value),
      name: dayjs(el.sampling_time).year(),
    }))
    .sort((first, second) => first.name - second.name);
  const uniqueData = Array.from(new Map(data.map((item) => [item.name, item])).values());

  return (
    <ResponsiveContainer width={'100%'} height={300}>
      <ComposedChart data={data} margin={{ top: 25, left: -15 }} accessibilityLayer>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' ticks={uniqueData.map((item) => item.name)} stroke={redesignColors.grey.grey4} tick={{ fill: redesignColors.grey.grey2 }} />
        <YAxis stroke={redesignColors.grey.grey4} tick={{ fill: redesignColors.grey.grey2 }} label={{ value: firstValue.history[0].unit, position: 'top', offset: 12, fill: redesignColors.dark }} />
        <Tooltip formatter={(value) => [value, firstValue.name]} />
        <ReferenceArea y1={minValue} y2={maxValue} stroke={redesignColors.grey.grey4} fill={redesignColors.green.common} ifOverflow='hidden' fillOpacity={0.3} />
        <Line dataKey='uv' stroke='#82ca9d'></Line>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default GraphHistory;
