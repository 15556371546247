import { Alert, Grid, SvgIcon } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PatientInfoCard from 'pages/patient/new-appointment/components/PatientInfoCard';
import { RdvPatient } from 'models/appointment.model';
import { redesignColors } from 'resources/theme/theme.colors';
import { ReactComponent as InfoIcon } from 'resources/icons/info.svg';
import { Person } from 'models/profile.model';
import PatientFilledCard from 'pages/shared/fertility/components/PatientFilledCard';

const InformationStep: FC<{
  handleIsActiveNextButton: () => void;
  setPatientRdv: (data: RdvPatient) => void;
  patientRdv: RdvPatient | null;
  patient: Person | null;
}> = ({ handleIsActiveNextButton, setPatientRdv, patientRdv, patient }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(patient?.email || '');
  const [emailConfirm, setEmailConfirm] = useState(patient?.email || '');
  const [phone, setPhone] = useState(patient?.phone || '');

  useEffect(() => {
    const allFieldIsValid = patient
      ? phone && emailConfirm && email && emailConfirm === email
      : patientRdv?.birthname &&
        patientRdv?.gender &&
        patientRdv?.firstname &&
        patientRdv?.phone &&
        patientRdv?.verifyemail &&
        patientRdv?.email &&
        patientRdv?.verifyemail === patientRdv?.email &&
        patientRdv?.birthdate;
    if (allFieldIsValid) {
      handleIsActiveNextButton();
    }
  }, [patientRdv, patient, phone, email, emailConfirm]);

  useEffect(() => {
    setPatientRdv({ ...(patientRdv || {}), gender: 'male' } as RdvPatient);
  }, []);

  return (
    <Grid item xs={12} sm={8} lg={7} sx={{ margin: '0 auto', mt: '8px' }}>
      <Alert severity='info' sx={{ backgroundColor: redesignColors.blue.blue3, fontSize: '14px', fontWeight: 500, borderRadius: '8px', width: '100%', mb: 2 }} icon={<SvgIcon component={InfoIcon} />}>
        {t('fertility.opening.male_only')}
      </Alert>
      {patient ? (
        <PatientFilledCard patient={patient} email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} emailConfirm={emailConfirm} setEmailConfirm={setEmailConfirm} />
      ) : (
        <PatientInfoCard
          type='Patient'
          onlyMale
          values={patientRdv || undefined}
          disableStorage
          setValue={(key: string, value: string | Date) => setPatientRdv({ ...(patientRdv || {}), [key]: value } as RdvPatient)}
        />
      )}
    </Grid>
  );
};

export default InformationStep;
