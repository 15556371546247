import { DatePicker, LocalizationProvider, deDE, frFR, ptBR, enUS } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FC } from 'react';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import ptLocale from 'date-fns/locale/pt';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocale } from 'hooks';
import { SxProps, Theme } from '@mui/material';

const GenericDatePicker: FC<{
  control?: any;
  name: string;
  disabled?: boolean;
  helperText?: string;
  onValidate?: any;
  required?: boolean;
  error?: boolean;
  maxDate?: Date;
  dataFormat?: 'ISO' | 'DATE';
  label?: string;
  defaultValue?: string;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  sx?: SxProps<Theme>;
}> = ({ label, control, maxDate = new Date(), defaultValue, dataFormat = 'DATE', name, error, disabled = false, helperText, onValidate, required, variant = 'filled', sx }) => {
  const { t } = useTranslation();
  const locale = useLocale();

  const localeMap: { [key: string]: Locale } = {
    en: enLocale,
    fr: frLocale,
    de: deLocale,
    pt: ptLocale,
  };

  const localeTextMap: { [key: string]: any } = {
    en: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
    fr: frFR.components.MuiLocalizationProvider.defaultProps.localeText,
    de: deDE.components.MuiLocalizationProvider.defaultProps.localeText,
    pt: ptBR.components.MuiLocalizationProvider.defaultProps.localeText,
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
        // pattern: BIRTHDATE,
        validate: onValidate,
      }}
      defaultValue={defaultValue ? new Date(defaultValue) : null}
      render={({ field }) => (
        <LocalizationProvider adapterLocale={localeMap[locale]} dateAdapter={AdapterDateFns} localeText={localeTextMap[locale]}>
          <DatePicker
            {...field}
            sx={sx as any}
            value={field.value ? new Date(field.value) : null}
            label={label ? label : t('patients.search.fields.birth_date')}
            openTo='year'
            views={['year', 'month', 'day']}
            disabled={disabled}
            maxDate={maxDate}
            onChange={(date) => {
              if (date instanceof Date && !isNaN(date.getTime())) {
                if (dataFormat === 'ISO') {
                  field.onChange(date?.toISOString());
                } else {
                  field.onChange(date);
                }
              }
            }}
            slotProps={{ textField: { variant: variant, fullWidth: true, required: required, error: error, helperText: helperText } }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default GenericDatePicker;
