import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardContent, IconButton, SvgIcon, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContextLink from 'components/_base/ContextLink';
import { ShortenPerson } from 'models/profile.model';
import { FC } from 'react';
import { ReactComponent as EditIcon } from 'resources/icons/edit.svg';
import { getInitials } from 'utils/func/Person.func';
import ProfileData from './shared/ProfileData';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';
import { useIsMobileView } from 'hooks';
import { ReactComponent as DeleteIcon } from 'resources/icons/delete.svg';

export type ProfileCardProps = {
  title: string;
  person: ShortenPerson;
  showEditProfileButton?: boolean;
  showDeleteButton?: boolean;
};

const ProfileCard: FC<ProfileCardProps> = ({ title, person, showEditProfileButton, showDeleteButton = true }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  return (
    <Card sx={{ padding: '0 24px !important', width: '100%' }}>
      <CardContent sx={{ py: 0 }}>
        <Accordion
          defaultExpanded={showEditProfileButton}
          expanded={showEditProfileButton}
          onChange={(e) => {
            e.stopPropagation();
          }}
          sx={{ backgroundColor: '#ffffff !important' }}
        >
          <AccordionSummary expandIcon={!showEditProfileButton && <ExpandMoreIcon />} aria-controls='panel1-content' id='panel1-header' sx={{ opacity: '1 !important' }}>
            <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
              <Box display='flex' alignItems='center'>
                <Avatar
                  sx={{
                    display: { xs: 'none', md: 'inherit' },
                    width: 48,
                    height: 48,
                    fontSize: '18px',
                    color: redesignColors.dark,
                    fontWeight: 700,
                    mr: 2,
                  }}
                >
                  {getInitials(person)}
                </Avatar>
                <Typography variant='body1' fontWeight='500'>
                  {title}
                </Typography>
              </Box>
              <Box>
                {showEditProfileButton && (
                  <ContextLink to='PROFILE_UPDATE'>
                    {isMobileView ? (
                      <IconButton sx={{ mr: 1 }}>
                        <SvgIcon
                          sx={{
                            color: redesignColors.blue.main,
                          }}
                          component={EditIcon}
                        />
                      </IconButton>
                    ) : (
                      <Button
                        startIcon={
                          <SvgIcon
                            sx={{
                              color: redesignColors.blue.main,
                            }}
                            component={EditIcon}
                          />
                        }
                        variant='text'
                        sx={{ mr: 3 }}
                      >
                        {t('profile.actions.modify.data')}
                      </Button>
                    )}
                  </ContextLink>
                )}
                {showDeleteButton && (
                  <IconButton>
                    <SvgIcon
                      sx={{
                        color: `${redesignColors.red} !important`,
                        mr: '4px',
                      }}
                      component={DeleteIcon}
                    />
                    <Typography variant='button' color='red'>
                      {t('common.action.delete')}
                    </Typography>
                  </IconButton>
                )}
              </Box>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Box sx={{ width: { sx: '100%', sm: '70%' } }}>
              <ProfileData person={person} />
            </Box>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default ProfileCard;
