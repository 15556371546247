import { Geolocation } from 'hooks/useGeolocation';
import { GeolocationAction, GeolocationActionType } from 'store/actions/geolocation.actions';

type GeolocationInitialState = {
  geolocation: Geolocation | null;
};

const geolocationInitialState: GeolocationInitialState = {
  geolocation: null,
};

export default function geolocationReducer(prevState = geolocationInitialState, action: GeolocationAction) {
  switch (action.type) {
    case GeolocationActionType.ADD:
      return {
        ...prevState,
        geolocation: action.geolocation,
      };

    case GeolocationActionType.DELETE:
      return {
        ...prevState,
        geolocation: action.geolocation,
      };

    default:
      return prevState;
  }
}
