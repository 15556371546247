export function removeBase64Prefix(base64String: string) {
  const regex = /^data:[^;]+;base64,/;

  return base64String.replace(regex, '');
}

export const getBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
