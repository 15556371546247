import { Button, Grid, SvgIcon, Typography } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { SearchType } from 'models/directory.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DoctorSvg } from 'resources/icons/doctor.svg';
import { ReactComponent as CenterSvg } from 'resources/icons/center.svg';
import AllowLocationDialog from 'components/allow-location-dialog';
import { redesignColors } from 'resources/theme/theme.colors';

interface FindHeaderProps {
  type: SearchType;
  setType: (type: SearchType) => void;
}
const svgBlueElementStyles = {
  fill: `${redesignColors.white} !important`,
};

const FindHeader: FC<FindHeaderProps> = ({ type, setType }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  return (
    <Grid container spacing={isMobileView ? 1 : 2}>
      <Grid item xs={12}>
        <Typography variant='h2'>{t('findDoctor.searchBar.label')}</Typography>
      </Grid>
      <Grid item md={4} lg={3}>
        <Button
          variant={type === SearchType.PROFESSIONAL ? 'contained' : 'outlined'}
          sx={{
            px: 4,
            py: 1.5,
            height: '45px',
            width: '100%',
            borderRadius: '8px',
            position: 'relative',
            ...(type === SearchType.PROFESSIONAL && { '& .blue-element': svgBlueElementStyles }),
          }}
          onClick={() => setType(SearchType.PROFESSIONAL)}
        >
          <SvgIcon component={DoctorSvg} sx={{ position: 'absolute', left: '8px' }} />
          {t('findDoctor.searchBar.actions.professional')}
        </Button>
      </Grid>
      <Grid item md={4} lg={3}>
        <Button
          variant={type === SearchType.HEALTH_INSTITUTION ? 'contained' : 'outlined'}
          sx={{
            px: 4,
            py: 1.5,
            width: '100%',
            height: '45px',
            borderRadius: '8px',
            position: 'relative',
            ...(type === SearchType.HEALTH_INSTITUTION && { '& .blue-element': svgBlueElementStyles }),
          }}
          onClick={() => setType(SearchType.HEALTH_INSTITUTION)}
        >
          <SvgIcon component={CenterSvg} sx={{ position: 'absolute', left: '8px' }} />
          {t('findDoctor.searchBar.actions.institution')}
        </Button>
      </Grid>
      <Grid item md={4} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <AllowLocationDialog />
      </Grid>
    </Grid>
  );
};

export default FindHeader;
