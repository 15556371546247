import { Button, Card, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import api from 'api';
import { convertDate, isManualValidDate } from 'utils/func/Date.func';
import { useContextRedirection, useIsMobileView, useNotification } from 'hooks';
import RoutePaths from 'utils/RoutePaths';
import { useNavigate } from 'react-router-dom';
import GenericDatePicker from 'components/date-picker';

interface FormValues {
  token: string;
  birth_date: Date | null;
}

const defaultValues: FormValues = { token: '', birth_date: null };

const AddRelativeWithTokenForm: FC = () => {
  const isMobileView = useIsMobileView();
  const { t } = useTranslation();
  const { notification } = useNotification();
  const navigate = useContextRedirection();
  const history = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { isDirty, errors },
  } = useForm<FormValues>({ defaultValues, mode: 'all' });

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    (data) => {
      api.users
        .addRelationWithToken(data.token, convertDate(data.birth_date, false, 'yyyy-MM-dd'))
        .then(() => {
          navigate(RoutePaths['PROFILE_RELATIVE']);
          notification(t('landing_page.login.token.notification.validation.tokenRelative'), 'success');
        })
        .catch(() => {
          notification(t('landing_page.login.token.notification.error.tokenRelative'), 'success');
        });
    },
    [notification, t, navigate],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card sx={{ width: '100%', borderRadius: '0 0 8px 8px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{t('profile.relatives.add.desc_to_access_a_result')}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField variant='filled' label={t('profile.relatives.add.token_received_by')} {...register('token', { required: true })} fullWidth sx={{ width: { sx: '100%', sm: '328px' } }} />
          </Grid>
          <Grid item xs={12} sm={6} display={{ xs: 'none', sm: 'block' }} />

          <Grid item xs={12} sm={6}>
            <GenericDatePicker
              name='birthDate'
              control={control}
              label={t('profile.relatives.add.birth_date_relative')}
              onValidate={() => isManualValidDate(getValues('birth_date'))}
              dataFormat='ISO'
              sx={{ width: { sx: '100%', sm: '328px' } }}
              error={errors?.birth_date !== undefined}
            />
          </Grid>
        </Grid>
      </Card>
      <Grid item xs={12} mt={3}>
        <Button sx={{ width: isMobileView ? '100%' : 160, mr: isMobileView ? 0 : 2, mb: isMobileView ? 2 : 0 }} disabled={!isDirty} variant='contained' type='submit'>
          {t('common.action.submit')}
        </Button>
        <Button sx={{ width: isMobileView ? '100%' : 160 }} variant='outlined' fullWidth={isMobileView} type='submit' onClick={() => history(-1)}>
          {t('common.action.cancel')}
        </Button>
      </Grid>
    </form>
  );
};

export default AddRelativeWithTokenForm;
