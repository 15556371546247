import { Alert, Grid, SvgIcon } from '@mui/material';
import { ChangeEvent, createRef, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';
import { ReactComponent as InfoIcon } from 'resources/icons/info.svg';
import { useNotification } from 'hooks';
import { MAX_SIZE_FILE } from 'utils/Constants';
import { useStore } from 'react-redux';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';
import { AppointmentPatient, Patient, PatientFiles } from './types/Patient';
import PatientPrescriptionCard from './PatientPrescriptionCard';

const PrescriptionsStep: FC<{
  handleNext: () => void;
  setNextButtonActive?: (v?: boolean) => void;
  isNewAppointment?: boolean;
}> = ({ setNextButtonActive, isNewAppointment }) => {
  const { dispatch, getState } = useStore();
  const [appointmentPatients, setAppointmentPatients] = useState<AppointmentPatient[]>([]);
  const refs = useRef(getState().newAppointmentFlowReducer.patients.map(() => createRef<HTMLInputElement>()));
  const [files, setFiles] = useState<PatientFiles[]>([]);
  const { t } = useTranslation();
  const { notification } = useNotification();

  useEffect(() => {
    const storage = getState().newAppointmentFlowReducer;

    if (!storage) return;

    if (storage.prescriptions.length > 0) {
      setFiles(storage.prescriptions);
    }

    if (storage.patients.length > 0) {
      setAppointmentPatients(storage.patients.filter((patient: Patient) => !!patient.selected));
    }
  }, [setFiles, getState]);

  useEffect(() => {
    dispatch({
      type: NewAppointmentFlowActionType.SET_PRESCRIPTIONS,
      prescriptions: files,
    });
  }, [files, dispatch]);
  useEffect(() => {
    if (setNextButtonActive) {
      setNextButtonActive(false);
    }
  }, []);
  useEffect(() => {
    const allFiles = files.reduce((acc, { files }) => (files.length ? [...acc, ...files] : acc), [] as File[]);
    if (allFiles?.length) {
      setNextButtonActive?.(true);
    } else {
      setNextButtonActive?.(false);
    }
  }, [files]);

  const searchPatientFiles = (patientId: number | string, fileName: string) => {
    return files.find((f) => f.patientId === patientId && f.files.find((ff) => ff.name === fileName));
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, patientId: string | number) => {
    const file = event?.target?.files && event?.target?.files[0];

    if (!file || !(file.type === 'application/pdf' || file.type.startsWith('image/')) || file.size > MAX_SIZE_FILE) {
      notification(t('appointment.book.steps.prescriptions.notification.error.upload'), 'error');

      event.target.value = '';
      return;
    }

    const patientFiles = searchPatientFiles(patientId, file.name);

    if (patientFiles) {
      notification(t('appointment.book.steps.prescriptions.notification.error.exists', { replace: { prescription: file.name } }), 'error');

      event.target.value = '';
      return;
    }

    setFiles((prev) => {
      const patientFiles = prev.find((f) => f.patientId === patientId);

      if (patientFiles) {
        return prev.map((f) => {
          if (f.patientId === patientId) {
            return {
              patientId,
              files: [...f.files, file],
            };
          }

          return f;
        });
      }

      return [...prev, { patientId, files: [file] }];
    });
  };

  const handleUploadFile = (index: number) => {
    const ref = refs?.current && refs?.current[index];
    if (ref) {
      ref?.current?.click();
    }
  };

  return (
    <Grid item xs={12} sm={10} md={8} sx={{ margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '8px' }}>
      <Alert severity='info' sx={{ backgroundColor: redesignColors.blue.blue3, fontSize: '14px', fontWeight: 500, borderRadius: '8px' }} icon={<SvgIcon component={InfoIcon} />}>
        {t('fertility.prescriptions.upload_prescriptions')}
        <br />
        {t('fertility.prescriptions.thank_you')}
      </Alert>
      {isNewAppointment &&
        appointmentPatients &&
        appointmentPatients.map((patient, index) => (
          <PatientPrescriptionCard
            key={patient.id}
            patient={patient}
            files={files}
            refr={refs.current[index]}
            handleFileChange={handleFileChange}
            handleUploadFile={() => handleUploadFile(index)}
            setFiles={setFiles}
          />
        ))}
    </Grid>
  );
};

export default PrescriptionsStep;
