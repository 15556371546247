import { APIConfiguration, axiosBION } from 'api';
import { BaseService } from './base.service';
import { Holiday, HolidaysGetResults } from 'models/holiday.model';
import { AxiosResponse } from 'axios';

class HolidayService extends BaseService {
  static readonly AVAILABLE_LANGUAGES = ['fr', 'en', 'pt', 'de'];

  async getHolidays(): Promise<Holiday[]> {
    return axiosBION.get(`/${this.getLang()}${this.apiConfig.HOLIDAYS.GET}`).then((res: AxiosResponse<HolidaysGetResults, any>) => res.data.data);
  }
}

export default (apiConfig: APIConfiguration) => {
  return new HolidayService(apiConfig);
};
