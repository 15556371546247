import { APIConfiguration, axiosMYLAB } from 'api';
import { Analysis, Crosstab } from 'models/analysis.model';
import { Dossier } from 'models/dossier.model';
import { PdfType } from 'models/file.model';
import { FilterProps } from 'models/patients.model';
import { OfficeMedicalStaff, PatientTag, Record } from 'models/record.model';
import { Paginate } from 'models/utils.model';
import { DEFAULT_HISTORY_RESULTS_ANTECEDENT_NUMBER } from 'utils/Constants';
import { BaseService } from './base.service';
import { ResultStatus } from 'models/result.model';

type GetResultsParams = {
  size: number;
  page: number;
  patientId: number;
  sort?: string;
  tagIds?: string[];
  doctorIds?: number[];
  status?: ResultStatus;
  minDate?: string;
  maxDate?: string;
};
class ResultsService extends BaseService {
  async addPatientTag(reference: string, tag?: { name: string; color: string }) {
    const { data } = await axiosMYLAB.put(this.apiConfig.RESULTS.ADD_PATIENT_TAG(this.getLang(), reference), { tags: tag ? [tag] : [] });

    this.log('addPatientTag()');

    return data;
  }

  async getTags(): Promise<PatientTag[]> {
    const { data } = await axiosMYLAB.get<PatientTag[]>(`${this.apiConfig.RESULTS.GET_TAGS(this.getLang())}`);

    this.log(`getTags()`);

    return data;
  }
  async getDoctors(): Promise<OfficeMedicalStaff[]> {
    const { data } = await axiosMYLAB.get<OfficeMedicalStaff[]>(`${this.apiConfig.RESULTS.GET_DOCTORS(this.getLang())}`);

    this.log(`getDoctors()`);

    return data;
  }
  async getResults({ size, page, patientId, status, tagIds, doctorIds, minDate, maxDate, sort }: GetResultsParams) {
    const { data } = await axiosMYLAB.get<Paginate<Record>>(`${this.apiConfig.RESULTS.GET(this.getLang())}`, {
      params: {
        size,
        page,
        patient_id: patientId,
        status,
        tags: (tagIds || []).join(','),
        multi_medical_staff_id: (doctorIds || []).join(','),
        min_date: minDate,
        max_date: maxDate,
        sort: [sort],
      },
    });

    this.log(`getResults()`);

    return data;
  }

  async getResultByReference(reference: string) {
    const { data } = await axiosMYLAB.get<Dossier>(`${this.apiConfig.RESULTS.GET_BY_REFERENCE(this.getLang(), reference)}`);

    this.log(`getResultByReference()`);

    return data;
  }

  async getFrequentAnalysis() {
    const { data } = await axiosMYLAB.get<Analysis[]>(this.apiConfig.RESULTS.GET_FREQUENT_ANALYSIS(this.getLang()));

    this.log(`getFrequentAnalysis()`);

    return data;
  }

  async getResultsCrosstabByPatientId(patientId: number, references?: string[]) {
    const { data } = await axiosMYLAB.post<Crosstab>(this.apiConfig.RESULTS.GET_CROSSTAB_BY_PATIENT_ID(this.getLang()), { patient_id: patientId, references });

    this.log(`getResultsCrosstabByPatientId()`);

    return data;
  }

  async getHistoryFormResults(patientId: number, referencesId: string[]) {
    const { data } = await axiosMYLAB.get(this.apiConfig.RESULTS.GET_FORM_HISTORY_RESULT(this.getLang()), {
      params: {
        patient_id: patientId,
        antecedent_number: DEFAULT_HISTORY_RESULTS_ANTECEDENT_NUMBER,
        references: referencesId.join(','),
      },
    });

    this.log('getHistoryFormResults()');

    return data;
  }

  async markAllResultsRead() {
    const { data } = await axiosMYLAB.post(this.apiConfig.RESULTS.MARK_ALL_READ(this.getLang()));

    this.log('markAllResultsRead()');

    return data;
  }

  async markAResult(reference: number | string) {
    const { data } = await axiosMYLAB.put(`${this.apiConfig.RESULTS.MARK(this.getLang(), reference)}`);

    this.log('markAResult()');

    return data;
  }

  async getPdfReference(dossierId: string, filename: string) {
    const { data } = await axiosMYLAB.get(this.apiConfig.RESULTS.GET_PDF(this.getLang(), 'report', dossierId, filename), { responseType: 'blob' });

    this.log('getPdfReference()');

    return data;
  }

  async getFilteredRecords(props: FilterProps) {
    const { size, page, patientName, from, to, recordReference, cnsNumber, status, consulted, tagged, abnormal, urgent } = props;

    var url = this.apiConfig.RESULTS.SEARCH(this.getLang());

    const arrayDatas = [
      { key: 'last_name', value: patientName },
      { key: 'min_date', value: from },
      { key: 'max_date', value: to },
      { key: 'reference', value: recordReference },
      { key: 'cns', value: cnsNumber },
      { key: 'status', value: status },
      { key: 'consulted', value: consulted ? 1 : 0 },
      { key: 'tagged', value: tagged ? 1 : 0 },
      { key: 'has_abnormal_result', value: abnormal ? 1 : 0 },
      { key: 'urgent', value: urgent ? 1 : 0 },
      { key: 'size', value: size },
      { key: 'page', value: page },
    ];

    const params = arrayDatas
      .map((arrayData) => {
        if ((arrayData.value && arrayData.key !== 'consulted') || (arrayData.value === 0 && arrayData.key === 'consulted')) {
          return `${arrayData.key}=${arrayData.value}`;
        }
        return '';
      })
      .filter((param) => !!param);

    if (params.length) {
      url += `?${params.join('&')}`;
    }

    const { data } = await axiosMYLAB.get<Paginate<Record>>(url);

    this.log('getFilteredRecords()');

    return data;
  }

  async getFile(type: PdfType, dossierId: string, filename: string) {
    const { data } = await axiosMYLAB.get(this.apiConfig.RESULTS.GET_PDF(this.getLang(), type, dossierId, filename), { responseType: 'blob' });

    this.log(`getFile()`);

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new ResultsService(apiConfig);
};
