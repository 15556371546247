import { Add, Remove } from '@mui/icons-material';
import { Button, InputAdornment, TextField } from '@mui/material';
import { FC, FunctionComponent } from 'react';

interface CustomTextFieldProps {
  value: number | null;
  setValue: (value: number | null) => void;
  labelComponent: FunctionComponent;
}

const CustomTextField: FC<CustomTextFieldProps> = ({ value, setValue, labelComponent }) => {
  const handleAdd = () => {
    if (value !== null) {
      setValue(value + 1);
    } else {
      setValue(1);
    }
  };

  const handleRemove = () => {
    if (value !== null && value !== 0) {
      setValue(value - 1);
    } else {
      setValue(0);
    }
  };

  return (
    <TextField
      id='custom_text_field'
      label='Custom Text Field'
      variant='standard'
      fullWidth
      margin='normal'
      required
      type='number'
      value={value}
      InputLabelProps={{
        component: labelComponent,
      }}
      InputProps={{
        sx: {
          padding: '2px',
          height: '48px',
          textAlign: 'center',
          fontSize: '16px',
          backgroundColor: 'white',
          border: '0.5px solid grey',
          borderRadius: '8px',
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
        inputProps: {
          style: {
            textAlign: 'center',
            fontSize: '16px',
          },
        },
        startAdornment: (
          <InputAdornment position='start'>
            <Button sx={{ minWidth: '44px', height: '44px', padding: 1 }} variant='contained' onClick={() => handleRemove()} disabled={value === 0}>
              <Remove />
            </Button>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <Button sx={{ minWidth: '44px', height: '44px', padding: 1 }} variant='contained' onClick={() => handleAdd()}>
              <Add />
            </Button>
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
      onChange={(event) => setValue(event.target.value ? parseInt(event.target.value) : null)}
    />
  );
};

export default CustomTextField;
