import { Box, Button, Card, CardActions, CardContent, List, SvgIcon, Typography } from '@mui/material';
import api from 'api';
import ContextLink from 'components/_base/ContextLink';
import { isAfter, isEqual } from 'date-fns';
import { useNotification } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { convertDate } from 'utils/func/Date.func';
import { ReactComponent as RightArrowIcon } from 'resources/icons/right_arrow.svg';
import { redesignColors } from 'resources/theme/theme.colors';
import HomepageListingItems from './HomepageListingItems';
import { AppointmentResponse } from 'models/appointment.model';

const HomepageAppointments: FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<AppointmentResponse[]>([]);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const { notification } = useNotification();

  useEffect(() => {
    currentUser &&
      api.appointments
        .getAppointments(currentUser.id)
        .then((appointments) =>
          setData(
            appointments
              .filter((appointment: AppointmentResponse) => isAfter(new Date(appointment.date_time_from), new Date()) || isEqual(new Date(appointment.date_time_from), new Date()))
              .slice(0, 3),
          ),
        )
        .catch(() => notification(t('homepage.appointment.notification.error'), 'error'));
  }, [currentUser, notification, t]);

  return (
    <Card
      sx={{
        display: 'grid',
        width: '100%',
        minHeight: (theme) => theme.spacing(25),
      }}
    >
      <CardContent>
        <Typography variant='h2' fontSize='20px' fontWeight='500'>
          {t('homepage.appointment.title', { count: data ? data.length : 0 })}
        </Typography>
        <List dense sx={{ mb: 2 }}>
          {data && data.length > 0 ? (
            data.map((appointment, key: number) => {
              const patient = appointment.patients[0];
              const firstName = patient?.formatted_name ? patient?.formatted_name.split(' ')[0] : undefined;
              return (
                <ContextLink to='APPOINTMENT_DETAIL' params={{ id: appointment.id }}>
                  <HomepageListingItems
                    title={
                      <Box sx={{ cursor: 'pointer' }}>
                        {firstName && (
                          <Typography fontWeight='700' mb='2px'>
                            {firstName}
                          </Typography>
                        )}
                        <Typography fontSize='14px' variant='body2'>
                          {convertDate(appointment?.date_time_from, false, 'dd/MM/yyyy HH:mm')}
                        </Typography>
                      </Box>
                    }
                    key={key}
                  />
                </ContextLink>
              );
            })
          ) : (
            <Typography>{t('homepage.appointment.noAppointment')}</Typography>
          )}
        </List>
      </CardContent>
      <CardActions sx={{ alignItems: 'flex-end' }}>
        <Box width='100%' display='flex' justifyContent='flex-end'>
          {data && data.length > 0 && (
            <ContextLink to='APPOINTMENT'>
              <Button variant='text' endIcon={<SvgIcon component={RightArrowIcon} fill={redesignColors.blue.dark} sx={{ mr: 1, fontSize: '28px !important' }} />}>
                {t('homepage.appointment.buttonLabel')}
              </Button>
            </ContextLink>
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

export default HomepageAppointments;
