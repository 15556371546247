import { Box, Typography } from '@mui/material';
import { Result } from 'models/result.model';
import DetailTabPanelBodyIsComment from './DetailTabPanelBodyIsComment';
import DetailTabPanelBodyIsFile from './DetailTabPanelBodyIsFile';
import DetailTabPanelBodyIsGraph from './DetailTabPanelBodyIsGraph';
import DetailTabPanelBodyIsTitle from './DetailTabPanelBodyIsTitle';
import DetailTabPanelBodyIsValue from './DetailTabPanelBodyIsValue';
import DetailTabPanelBodyIsTable from './DetailTabPanelBodyIsTable';
import DetailTabPanelBodyIsNotShow from './DetailTabPanelBodyIsNotShow';
import { formatToLocaleNumber } from 'utils/func/Number.func';

export const displayLimit = (limitMin: string | null, limitMax: string | null, limitText: string | null, limitHtml: string | null, unit?: string, locale?: string) => {
  if (limitHtml) {
    return (
      <Typography
        variant='body2'
        dangerouslySetInnerHTML={{
          __html: limitHtml,
        }}
      />
    );
  }
  if (limitText) {
    return <Typography variant='body2'>{limitText}</Typography>;
  }
  if (limitMin && limitMax) {
    return (
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='body2'>{formatToLocaleNumber(limitMin)}</Typography>
        <Typography variant='body2'>{formatToLocaleNumber(limitMax)}</Typography>
      </Box>
    );
  }

  if (limitMin) {
    return (
      <Box display='flex' justifyContent='flex-end'>
        <Typography variant='body2'>{`>${formatToLocaleNumber(limitMin)}`}</Typography>
      </Box>
    );
  }

  return (
    limitMax && (
      <Box display='flex' justifyContent='flex-end'>
        <Typography variant='body2'>{`<${formatToLocaleNumber(limitMax)}`}</Typography>
      </Box>
    )
  );
};

export const calculateRatioSlider = (minValue: string, maxValue: string, value: string) => {
  const pValue = parseFloat(value.replace(/\D/g, ''));
  const pMinValue = minValue ? parseFloat(minValue.replace(/\D/g, '')) : 0.0;
  const pMaxValue = parseFloat(maxValue.replace(/\D/g, ''));

  const val = ((pValue - pMinValue) * 100.0) / (pMaxValue - pMinValue);
  if (val > 100) return 106;
  if (val < 0) return -6;
  return val;
};

export const hasUsualValues = (val: Result) => {
  return val.limit_min && val.limit_max && val.limit_text && val.limit_html;
};

export const getBodyValue = (val: Result, isHighlight: boolean, isDoctor: boolean) => {
  if (!isDoctor && val.value === 'notShow') {
    return <DetailTabPanelBodyIsNotShow />;
  }
  switch (val.type) {
    case 'title':
      return <DetailTabPanelBodyIsTitle val={val} />;
    case 'file':
      return <DetailTabPanelBodyIsFile val={val} />;
    case 'value':
      return <DetailTabPanelBodyIsValue val={val} isHighlight={isHighlight} />;
    case 'comment':
      return <DetailTabPanelBodyIsComment val={val} />;
    case 'graph':
      return <DetailTabPanelBodyIsGraph val={val} />;
    case 'table':
      return <DetailTabPanelBodyIsTable val={val} />;
    default:
      return <DetailTabPanelBodyIsValue val={val} isHighlight={isHighlight} />;
  }
};
