import { Stepper, Step, StepLabel, Stack, styled, StepConnector, stepConnectorClasses, stepLabelClasses, Breadcrumbs } from '@mui/material';
import { FC, useState } from 'react';
import ChoosePatients from '../../../components/appointment/ChoosePatients';
import ContextLink from 'components/_base/ContextLink';
import StepLayout from '../../../components/appointment/StepLayout';
import AddressComponent from '../../../components/appointment/Address';
import Services from './components/Services';
import { useTranslation } from 'react-i18next';
import CustomStepper from 'components/appointment/CustomStepper';

const StyledStepLabel = styled(StepLabel)(() => ({
  [`&.${stepLabelClasses.root}`]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: 0,
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    position: 'absolute',
    width: 'auto',
    transform: 'translateY(130%)',
  },
}));

const CustomStepperConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    position: 'relative',
    top: 11,
  },
}));

const CheckIn: FC = () => {
  const { t } = useTranslation();

  const steps = [
    {
      label: t('checkin.stepper.labels.introduction'),
      component: ChoosePatients,
      wrapStep: true,
    },
    {
      label: t('checkin.stepper.labels.address'),
      component: AddressComponent,
      wrapStep: true,
    },
    {
      label: t('checkin.stepper.labels.services'),
      component: Services,
      wrapStep: false,
      hideStepper: true,
    },
  ];

  const breadcrumbs = [
    <ContextLink to='HOME'>{t('checkin.breadcrumbs.home')}</ContextLink>,
    <ContextLink to='CHECKIN'>{t('checkin.title')}</ContextLink>,
    <ContextLink to='CHECKIN'>{t('checkin.breadcrumbs.services')}</ContextLink>,
  ];

  return <CustomStepper steps={steps} showBreadcrumb={true} breadcrumbs={breadcrumbs} />;
};

export default CheckIn;
