import { AppointmentSlot } from 'models/appointment.slots.model';
import { NewAppointmentFlowAction, NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';
import { Address as PersonAddress } from 'models/profile.model';
import { Patient } from 'components/appointment/types/Patient';

export type PatientInfo = Patient & { selected?: boolean };

export type Address = {
  address: PersonAddress | null;
  longLat: {
    long: number;
    lat: number;
  };
  additionalInfo: string;
};

type Prescription = {
  patientId: string;
  files: File[];
};

export type AppointmentFlowState = {
  adultsNumber: number;
  childrenNumber: number;
  patients: PatientInfo[];
  address: Address;
  slot: AppointmentSlot | null;
  prescriptions: Prescription[];
};

const newAppointmentFlowInitialState: AppointmentFlowState = {
  adultsNumber: 0,
  childrenNumber: 0,
  patients: [],
  address: {
    address: null,
    longLat: {
      long: 6.091168,
      lat: 50.016727,
    },
    additionalInfo: '',
  },
  slot: null,
  prescriptions: [],
};

export default function newAppointmentFlowReducer(prevState = newAppointmentFlowInitialState, action: NewAppointmentFlowAction) {
  let newPatients: PatientInfo[] = [];
  let currentIndex: number = -1;
  switch (action.type) {
    case NewAppointmentFlowActionType.REMOVE_ALL:
      return newAppointmentFlowInitialState;
    case NewAppointmentFlowActionType.SET_ADULTS_NUMBER:
      return {
        ...prevState,
        adultsNumber: action.adultsNumber,
      };
    case NewAppointmentFlowActionType.SET_CHILDREN_NUMBER:
      return {
        ...prevState,
        childrenNumber: action.childrenNumber,
      };
    case NewAppointmentFlowActionType.SET_PATIENT_INFO:
      newPatients = prevState.patients;

      if (newPatients.length === 0) {
        newPatients.push(action.patientInfo);

        return {
          ...prevState,
          patients: newPatients,
        };
      }

      let updatedPatients = [];
      currentIndex = newPatients.findIndex((patient) => patient.id === action.patientInfo.id);
      if (currentIndex === -1) {
        updatedPatients = [...prevState.patients, action.patientInfo];
      } else {
        updatedPatients = prevState.patients.map((patient, index) => (index === currentIndex ? action.patientInfo : patient));
      }
      return {
        ...prevState,
        patients: updatedPatients,
      };
    case NewAppointmentFlowActionType.REMOVE_PATIENT_INFO:
      newPatients = prevState.patients.filter((patient) => patient.id !== action.id);

      return {
        ...prevState,
        patients: newPatients,
      };
    case NewAppointmentFlowActionType.SET_ADDRESS:
      return {
        ...prevState,
        address: action.address,
      };
    case NewAppointmentFlowActionType.REMOVE_ADDRESS:
      return {
        ...prevState,
        address: newAppointmentFlowInitialState.address,
      };

    case NewAppointmentFlowActionType.SET_SLOT:
      return {
        ...prevState,
        slot: action.slot,
      };
    case NewAppointmentFlowActionType.DELETE_SLOT:
      return {
        ...prevState,
        slot: null,
      };
    case NewAppointmentFlowActionType.SET_PRESCRIPTIONS:
      return {
        ...prevState,
        prescriptions: action.prescriptions,
      };
    case NewAppointmentFlowActionType.CLEAR_NEW_APPOINTMENT_FLOW:
      return {
        ...newAppointmentFlowInitialState,
        patients: [],
        prescriptions: [],
      };
    default:
      return prevState;
  }
}
