import { AppointmentMedicalAct } from 'models/appointment.medical.act.model';
import { MedicalActsAction, MedicalActsActionType } from 'store/actions/medicalActs.actions';

type MedicalActsInitialState = {
  medicalActs: AppointmentMedicalAct[];
};

const navMenuInitialState: MedicalActsInitialState = {
  medicalActs: [],
};

export default function medicalActsReducer(prevState = navMenuInitialState, action: MedicalActsAction) {
  switch (action.type) {
    case MedicalActsActionType.ADD:
      return {
        ...prevState,
        medicalActs: action.medicalActs,
      };

    default:
      return prevState;
  }
}
