import { combineReducers } from 'redux';
import appointmentReducer from './appointmentForm.reducer';
import navMenuReducer from './navMenu.reducer';
import notificationsReducer from './notifications.reducer';
import prescriptionProfileReducer from './prescriptionProfile.reducer';
import sessionReducer from './session.reducer';
import newAppointmentFlowReducer from './newAppointmentFlow.reducer';
import geolocationReducer from './geolocation.reducer';
import medicalActsReducer from './medicalActs.reducer';
import fertilityCenterReducer from './fertilityCenter.reducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  navMenu: navMenuReducer,
  appointmentForm: appointmentReducer,
  prescriptionProfile: prescriptionProfileReducer,
  notification: notificationsReducer,
  newAppointmentFlowReducer: newAppointmentFlowReducer,
  geolocation: geolocationReducer,
  medicalActs: medicalActsReducer,
  fertilityCenter: fertilityCenterReducer,
});

export type GlobalState = ReturnType<typeof rootReducer>;

export default rootReducer;
