import { Theme } from '@emotion/react';
import { ArrowBack } from '@mui/icons-material';
import { Button, Divider, Grid, Stack, SxProps, ToggleButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useLocale } from 'hooks';
import { FC } from 'react';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/pt';

type TimeSlotsProps = {
  title: string;
  disabledSlots?: string[];
  date: Date;
  handleBack: () => void;
  setSelectedSlot: (time: string) => void;
  selectedSlot: string;
  sx?: SxProps<Theme>;
};

export const defaultSlots = [
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
];

const getOrdinal = (day: number, locale: string) => {
  if (locale === 'en') {
    if (day === 1 || day === 21 || day === 31) return `${day}st`;
    if (day === 2 || day === 22) return `${day}nd`;
    if (day === 3 || day === 23) return `${day}rd`;
    return `${day}th`;
  }
  return day; // Other languages don't use English-style ordinals
};

const formatDate = (date: Date, locale: string) => {
  const day = dayjs(date).date();
  const monthYear = dayjs(date).locale(locale).format('YYYY');

  if (locale === 'en') {
    return `${dayjs(date).locale(locale).format('MMMM')} the ${getOrdinal(day, locale)} ${monthYear}`;
  }
  return `${day} ${dayjs(date).locale(locale).format('MMMM')} ${monthYear}`;
};

const TimeSlots: FC<TimeSlotsProps> = ({ title, date, handleBack, selectedSlot, setSelectedSlot, disabledSlots, sx }) => {
  const locale = useLocale();
  return (
    <Stack
      alignSelf='center'
      spacing={4}
      justifyContent='center'
      alignItems='start'
      sx={{
        ...sx,
        width: (sx as any)?.width || {
          xs: '100%',
          sm: '80%',
          md: '50%',
        },
      }}
    >
      <Button onClick={handleBack} startIcon={<ArrowBack sx={{ fill: 'black', fontSize: '20px' }} />}>
        <Typography sx={{ fontWeight: 500, fontSize: '20px' }} variant='h3'>
          {formatDate(date, locale)}
        </Typography>
      </Button>
      <Stack spacing={1} justifyContent='center' alignItems='center' width='100%'>
        <Typography alignSelf='center' sx={{ fontWeight: 700, fontSize: '20px' }} variant='body1'>
          {title}
        </Typography>
        <Divider sx={{ mt: 0, pt: 0, width: '100%', border: '0.5px solid grey' }} />

        <Grid container spacing={1} width='100%'>
          {defaultSlots.map((slot, index) => (
            <Grid item key={index}>
              <ToggleButton
                key={slot + index}
                onChange={(e, val) => setSelectedSlot(val)}
                selected={slot === selectedSlot}
                disabled={disabledSlots?.includes(slot)}
                value={slot}
                size='medium'
                sx={(theme) => ({
                  borderRadius: '8px',
                  border: '0.5px solid grey',
                  width: '100px',
                  height: '50px',
                  backgroundColor: 'white',
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.light,
                    },
                  },
                  '&.Mui-disabled': { opacity: '0.5' },
                })}
              >
                <Typography variant='button'>{slot}</Typography>
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default TimeSlots;
