import { Box, Button, Card, CardContent, CardHeader, Grid, MenuItem, TextField, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import ContextLink from 'components/_base/ContextLink';
import FindPatientsForm from 'components/find-patients-form';
import CreatePatientForm from './CreatePatientForm';
import PatientList from 'components/patient-list';
import { useContextRedirection, useNotification } from 'hooks';
import { Patient, PatientsListRequest, PatientsListResponse } from 'models/patients.model';
import { PrescriptionProfileListResponse } from 'models/prescription.model';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { CNS_CODE, DEFAULT_PAGINATION_SIZE } from 'utils/Constants';
import { formatGender } from 'utils/func/Person.func';
import { allPropsAreTruthy } from 'utils';
import { PrescriptionRequestMaker } from 'utils/func/Prescription.func';

const Prescriptions: FC = () => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const navigate = useContextRedirection();

  const currentUser = useSelector((state: GlobalState) => state.session.authenticateUser);

  const formData: PatientsListRequest = {
    first_name: undefined,
    last_name: undefined,
    cns: undefined,
    gender: undefined,
    birth_name: undefined,
    birth_date: undefined,
  };

  const paginatorData: GenericPaginator = useMemo(
    () => ({
      total: 0,
      size: DEFAULT_PAGINATION_SIZE,
      page: 1,
    }),
    [],
  );

  const [form, setForm] = useState(formData);
  const [pdfData, setPdfData] = useState<Blob>();
  const [isDataFromMPI, setIsDataFromMPI] = useState<boolean>(false);
  const [showCreatePatientForm, setShowCreatePatientForm] = useState<boolean>(false);
  const [showCreatePatientButton, setShowCreatePatientButton] = useState<boolean>(false);
  const [prescriptionProfiles, setPrescriptionProfiles] = useState<PrescriptionProfileListResponse>([]);
  const [paginator, setPaginator] = useState(paginatorData);
  const patientListData: Patient[] | undefined = undefined;
  const [patientList, setPatientList] = useState<Patient[] | undefined>(patientListData);
  const [selectedProfile, setSelectedProfile] = useState<string>('');

  const handleFormData = (data: PatientsListRequest) => {
    setForm(data);
    const resetedPaginator = { ...paginator, page: 1 };
    setPaginator(resetedPaginator);
    requestPatientList(resetedPaginator, data);
  };

  const handlePaginatorData = (data: GenericPaginator) => {
    setPaginator(data);
    requestPatientList(data, form);
  };

  const requestPatientListMPI = useCallback(
    (data: PatientsListRequest) => {
      api.patients
        .getPatientsMPI(data)
        .then((response: any) => {
          setIsDataFromMPI(true);
          setPatientList(response);
          setPaginator({ ...paginator, total: response.length });
          if (response.length === 0) {
            setShowCreatePatientButton(true);
          }
        })
        .catch((error) => {
          if (error?.code === 400) {
            notification(t('landing_page.login.token.notification.error.incorrectToken'), 'error');
            return;
          }
          setShowCreatePatientButton(true);
          setPatientList([]);
          setPaginator(paginatorData);
        });
    },
    [notification, paginator, paginatorData, t],
  );

  const requestPatientList = useCallback(
    (paginator: GenericPaginator, form: PatientsListRequest) => {
      api.patients
        .getPatients(form, paginator.size, paginator.page)
        .then((response: PatientsListResponse) => {
          if (response.total === 0 && currentUser?.patient_creation_enabled) {
            requestPatientListMPI(form);
          } else {
            setIsDataFromMPI(false);
            setPatientList(response.results);
            setPaginator({
              total: response.total,
              size: response.size,
              page: response.page,
            });
          }
        })
        .catch(() => {
          setPatientList([]);
          notification(t('prescriptions.notification.error'), 'error');
        });
    },
    [currentUser?.patient_creation_enabled, notification, requestPatientListMPI, t],
  );

  // check before createPatient to format MPI DATA
  const formatBeforeCreatePatient = useCallback(
    (patient: Patient) => {
      return {
        first_name: patient.first_name ?? '',
        last_name: patient.last_name ?? '',
        birth_name: patient.last_name ?? '',
        birth_date: patient.birth_date ? (new Date(patient.birth_date)).toISOString() : '',
        gender: formatGender(patient.gender),
        cns: patient.cns ?? '',
        health_fund_id: CNS_CODE,
        created_from_eprescription: true,
        addresses: patient.addresses ?? {}
      };
    },
    [],
  );

  const requestCreatePatient = useCallback(
    (patient: any) => {
      const formatedPatient = formatBeforeCreatePatient(patient);
      if (allPropsAreTruthy(formatedPatient)) {
        api.patients
          .createPatient({...formatedPatient, home: patient.addresses?.home})
          .then((response) => {
            PrescriptionRequestMaker(response.id, navigate);
          })
          .catch((e) => {
            const errorMessages = ['There is more than one patient with that social security number.', 'There is more than one patient with those data.'];
            if (errorMessages.includes(e.response?.data?.message)) {
              notification(`${t('patients.create.errors.already_exist')}`, 'error', 5000);
            }
          });
      }
    },
    [formatBeforeCreatePatient, navigate, notification, t],
  );

  useEffect(() => {
    api.prescriptions
      .getEditablePrescriptionsProfilesByDoctorId({
        ms_id: currentUser!.id,
      })
      .then((response: PrescriptionProfileListResponse) => {
        setPrescriptionProfiles(response);
      })
      .catch(() => notification(t('prescriptions.profiles.notification.error'), 'error'));
  }, [currentUser, notification, t]);

  useEffect(() => {
    api.prescriptions
      .getPrescriptionFileForDoctor()
      .then((data) => {
        setPdfData(data);
      })
      .catch(() => notification(t('prescriptions.types.error'), 'error'));
  }, [notification, t]);

  const files = pdfData && new Blob([pdfData], { type: 'application/pdf' });
  const fileURL = files && URL.createObjectURL(files);

  return (
    <BodyTemplate title={t('bodyTemplate.doctor.prescriptions')}>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item xs={12} md={6}>
          {!showCreatePatientForm ? (
            <FindPatientsForm handleFormAction={handleFormData} showCreatePatientButton={showCreatePatientButton} handleShowCreatePatientForm={() => setShowCreatePatientForm(true)} halfWidth />
          ) : (
            <CreatePatientForm handleSubmitCreatePatientForm={requestCreatePatient} findPatientsFormValues={form} handleBackButton={() => setShowCreatePatientForm(false)} />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ marginBottom: (theme) => theme.spacing(4) }}>
            <CardHeader
              title={
                <Typography variant='h2' mb={2}>
                  {t('prescriptions.profiles.title')}
                </Typography>
              }
            />
            <CardContent>
              <Typography variant='body1' mb={4}>
                {t('prescriptions.profiles.subtitle')}
              </Typography>
              <Grid container item xs={12}>
                <Grid container item spacing={{ xs: 2, md: 4 }}>
                  <Grid item xs={12}>
                    <TextField select variant='filled' label={`${t('prescriptions.profiles.field.profile_name')}`} defaultValue={''} onChange={(e) => setSelectedProfile(e.target.value)} fullWidth>
                      {prescriptionProfiles.map(({ id, name }, i) => {
                        return (
                          <MenuItem value={id} key={`profile-${i}`}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '20px',
                  mt: 6,
                }}
              >
                <ContextLink to='DOCTOR_PRESCRIPTION_PROFILES_NEW'>
                  <Button type='button' variant='contained'>
                    {t('prescriptions.profiles.new_profile')}
                  </Button>
                </ContextLink>
                <ContextLink to='DOCTOR_PRESCRIPTION_PROFILES_UPDATE' params={selectedProfile !== '' ? parseInt(selectedProfile) : undefined} disabled={selectedProfile === ''}>
                  <Button type='submit' variant='contained' disabled={selectedProfile === ''}>
                    {t('common.action.modify')}
                  </Button>
                </ContextLink>
              </Box>
            </CardContent>
          </Card>
          <Card>
            <Grid container>
              <Grid item xs={6}>
                <CardHeader
                  title={
                    <Typography variant='h2' mb={2}>
                      {t('prescriptions.types.title')}
                    </Typography>
                  }
                />
                <CardContent>
                  <Typography variant='body1'>{t('prescriptions.types.subtitle')}</Typography>
                </CardContent>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <a href={fileURL} download style={{ textDecoration: 'none' }}>
                    <Button type='submit' variant='contained'>
                      {t('common.action.pdfDownload')}
                    </Button>
                  </a>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <PatientList
            patientDetailAction={{
              to: 'DOCTOR_PATIENTS_DETAILS',
            }}
            showPrescriptionAction
            patientList={patientList}
            paginatorData={paginator}
            handlePaginatorAction={handlePaginatorData}
            isDataFromMPI={isDataFromMPI}
            requestCreatePatient={requestCreatePatient}
          />
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Prescriptions;
