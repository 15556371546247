import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ContextRedirect from 'components/_base/ContextRedirect';
import PageDispatcher from 'components/_base/PageDispatcher';
import CallbackDispatcher from 'pages/callbackDispatcher';
import Appointment from 'pages/doctor/appointment';
import HomeDoctor from 'pages/doctor/homepage';
import Patients from 'pages/doctor/patients';
import PatientDetailsPage from 'pages/doctor/patients/details';
import PatientHistory from 'pages/doctor/patients/history';
import Prescriptions from 'pages/doctor/prescriptions';
import PatientsPrescription from 'pages/doctor/prescriptions/patientPrescription';
import RecapCompletion from 'pages/doctor/prescriptions/patientPrescription/recapCompletion/RecapCompletion';
import PrescriptionValidation from 'pages/doctor/prescriptions/patientPrescription/validation';
import PrescriptionsProfilePage from 'pages/doctor/prescriptions/profile/PrescriptionsProfilePage';
import Questionnaire from 'pages/shared/questionnaire';
import ResultsDoctor from 'pages/doctor/results';
import History from 'pages/doctor/results/resultsHistory';
import AppointmentPage from 'pages/patient/appointment';
import AppointmentListPage from 'pages/patient/appointment/Appointment.list';
import BionextTv from 'pages/patient/bionext-tv';
import Centers from 'pages/patient/centers';
import CentersUpdates from 'pages/patient/centers-updates';
import EUDCCCertificates from 'pages/patient/eudcc';
import EUDCCCertificateDetails from 'pages/patient/eudcc/Details';
import FindDoctor from 'pages/patient/find-doctor';
import Home from 'pages/patient/homepage';
import Invoices from 'pages/patient/invoices';
import InvoiceDetails from 'pages/patient/invoices/DetailInvoices';
import InvoicesDisconnected from 'pages/patient/invoices/InvoicesDisconnected';
import DocumentDSPDetails from 'pages/patient/my-dsp/DocumentDSPDetails';
import MyDSP from 'pages/patient/my-dsp';
import Results from 'pages/patient/results';
import Analysis from 'pages/shared/analysis';
import Error404 from 'pages/shared/exception/Error404';
import Faqpage from 'pages/shared/faq';
import Gdprpage from 'pages/shared/gdpr';
import IndexLogin from 'pages/shared/landing-page';
import News from 'pages/shared/news';
import Preregistration from 'pages/shared/preregistration';
import EmailValidation from 'pages/public/email-validation';
import Profile from 'pages/shared/profile';
import AddRelative from 'pages/shared/profile/add-relatives';
import UpdateProfile from 'pages/shared/profile/update/profile';
import ResultDetail from 'pages/shared/results';
import ResultInfo from 'pages/shared/results-new/result-info';
import SecurityLogout from 'pages/shared/security/SecurityLogout';
import PdfViewer from 'components/pdf-viewer';
import RequireAuth from 'components/_base/RequireAuth';
import RequireRole from 'components/_base/RequireRole';
import PrescriptionsSelectOMS from 'pages/doctor/prescriptions/PrescriptionsSelectOMS';
import { useTranslation } from 'react-i18next';
import { eSanteRedirectUri } from 'utils/func/eSante.func';
import TempHome from 'pages/patient/homepage/TempPatient';
import DisclaimerPage from 'pages/shared/disclaimer';
import PreregistrationQRCodeRouter from '../components/preregistration-qrcode-router';
import ContactPage from 'pages/shared/contact';
import AppointmentCancelPage from 'pages/patient/appointment.2024/Appointment.cancel.page';
import { useRole } from 'hooks';
import ResultHistory from 'pages/shared/results-new/result-history';
import PreregistrationsList from 'pages/shared/preregistration/Preregistration.list';
import AppointmentDetails from 'pages/patient/appointment/Appointment.details';
import Fertility from 'pages/shared/fertility';
import { RelativeTab } from 'pages/shared/profile/IndexProfileTabSwitcher';
import CheckIn from 'pages/patient/check-in';
import NewAppointment from 'pages/patient/new-appointment';
import Eprescriptions from 'pages/patient/e-prescriptions';
import EprescriptionsDetails from 'pages/patient/e-prescriptions/details';

const CustomRoutes: FC = () => {
  const { t } = useTranslation();
  const role = useRole();

  return (
    <Routes>
      <Route path='/' element={<Navigate to={`/${navigator.language.split('-').shift()}/`} />} />
      <Route
        path={eSanteRedirectUri}
        element={
          <RequireAuth>
            <RequireRole doctor={<CallbackDispatcher />} clerk={<CallbackDispatcher />} />
          </RequireAuth>
        }
      />
      <Route path='/:lang/' element={<PageDispatcher />}>
        <Route index element={<IndexLogin />} />
        <Route path='reset-password/:resetToken' element={<IndexLogin />} />
        <Route
          path='logout'
          element={
            <RequireAuth>
              <SecurityLogout />
            </RequireAuth>
          }
        />

        <Route path='/:lang/email-validation' element={<EmailValidation />} />
        <Route path='news' element={<News />} />
        <Route path='faq' element={<Faqpage />} />
        <Route path='gdpr' element={<Gdprpage />} />
        <Route path='contact' element={<ContactPage />} />
        <Route path='disclaimer' element={<DisclaimerPage />} />
        <Route path='check-in' element={<CheckIn />} />
        <Route
          path='home'
          element={
            <RequireAuth>
              <RequireRole patient={<Home />} temp_patient={<TempHome />} doctor={<HomeDoctor />} bionext_admin={<HomeDoctor />} laboratory={<HomeDoctor />} clerk={<HomeDoctor />} />
            </RequireAuth>
          }
        />

        <Route path='profile'>
          <Route
            index
            element={
              <RequireAuth>
                <RequireRole patient={<Profile profileType={RelativeTab.me} />} />
              </RequireAuth>
            }
          />
          <Route path='update'>
            <Route
              index
              element={
                <RequireAuth>
                  <UpdateProfile />
                </RequireAuth>
              }
            />
          </Route>
          <Route path='relative'>
            <Route
              index
              element={
                <RequireAuth>
                  <RequireRole patient={<Profile profileType={RelativeTab.relative} />} />
                </RequireAuth>
              }
            />
            <Route path='add'>
              <Route
                index
                element={
                  <RequireAuth>
                    <AddRelative />
                  </RequireAuth>
                }
              />
            </Route>
          </Route>
        </Route>

        <Route path='results'>
          <Route
            index
            element={
              <RequireAuth>
                <RequireRole patient={<Results />} doctor={<ResultsDoctor />} bionext_admin={<ResultsDoctor />} laboratory={<ResultsDoctor />} clerk={<ResultsDoctor />} />
              </RequireAuth>
            }
          />
          <Route path=':idResult'>
            <Route
              index
              element={
                <RequireAuth>
                  <ResultDetail />
                </RequireAuth>
              }
            />
            <Route
              path='info/:patientId'
              element={
                <RequireAuth>
                  <ResultInfo />
                </RequireAuth>
              }
            />
            <Route
              path='history/:patientId'
              element={
                <RequireAuth>
                  <ResultHistory />
                </RequireAuth>
              }
            />
            <Route
              path='form/:patientId'
              element={
                <RequireAuth>
                  <RequireRole doctor={<History />} bionext_admin={<History />} laboratory={<History />} clerk={<History />} />
                </RequireAuth>
              }
            />
            <Route
              path='pdf/prescription/:filename'
              element={
                <RequireAuth>
                  <PdfViewer title={t('bodyTemplate.pdfViewer.prescriptionResult')} type='prescription' />
                </RequireAuth>
              }
            />
            <Route
              path='pdf/eudcc/:filename'
              element={
                <RequireAuth>
                  <PdfViewer title={t('bodyTemplate.pdfViewer.eudccResult')} type='eudcc' />
                </RequireAuth>
              }
            />
            <Route
              path='pdf/report/:filename'
              element={
                <RequireAuth>
                  <PdfViewer title={t('bodyTemplate.pdfViewer.prescriptionResult')} type='report' />
                </RequireAuth>
              }
            />
            <Route
              path='pdf/result/:filename'
              element={
                <RequireAuth>
                  <PdfViewer title={t('bodyTemplate.pdfViewer.prescriptionResult')} type='result' />
                </RequireAuth>
              }
            />
            <Route
              path='questionnaires/:questionnaire'
              element={
                <RequireAuth>
                  <Questionnaire />
                </RequireAuth>
              }
            />
          </Route>
        </Route>

        <Route
          path='find-doctor'
          element={
            <RequireAuth>
              <FindDoctor />
            </RequireAuth>
          }
        />

        <Route path='eudcc'>
          <Route
            index
            element={
              <RequireAuth>
                <EUDCCCertificates />
              </RequireAuth>
            }
          />
          <Route
            path=':id/:file'
            element={
              <RequireAuth>
                <EUDCCCertificateDetails />
              </RequireAuth>
            }
          />
        </Route>
        <Route path='invoices'>
          <Route index element={<InvoicesDisconnected />} />
          <Route
            path=':id'
            element={
              <RequireAuth>
                <InvoiceDetails />
              </RequireAuth>
            }
          />
          <Route
            path='open'
            element={
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            }
          />
          <Route
            path='paid'
            element={
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            }
          />
        </Route>

        <Route path='appointment'>
          <Route
            index
            element={
              <RequireAuth>
                <RequireRole patient={<AppointmentListPage />} doctor={<Appointment />} clerk={<Appointment />} />
              </RequireAuth>
            }
          />
          <Route
            path=':id'
            element={
              <RequireAuth>
                <RequireRole patient={<AppointmentDetails />} />
              </RequireAuth>
            }
          />
        </Route>

        <Route path='appointment/book' element={role !== 'doctor' && role !== 'clerk' ? <NewAppointment /> : <ContextRedirect to='/appointment' />} />

        <Route path='appointment/cancel/:token' element={<AppointmentCancelPage />} />

        <Route
          path='appointment/patient/:id'
          element={
            <RequireAuth>
              <RequireRole doctor={<AppointmentPage hasComponent />} clerk={<AppointmentPage hasComponent />} />
            </RequireAuth>
          }
        />

        <Route
          path='appointment/patient/:id/:prescId'
          element={
            <RequireAuth>
              <RequireRole doctor={<AppointmentPage hasComponent />} clerk={<AppointmentPage hasComponent />} />
            </RequireAuth>
          }
        />

        <Route path='preregistration/:QRCODE' element={<PreregistrationQRCodeRouter />} />

        <Route path='preregistration'>
          <Route index element={<Preregistration />} />
        </Route>
        <Route path='fertility'>
          <Route index element={<Fertility />} />
        </Route>
        <Route
          path='preregistrations'
          element={
            <RequireAuth>
              <RequireRole patient={<PreregistrationsList />} />
            </RequireAuth>
          }
        />

        <Route path='centers' element={<Centers />} />

        <Route path='centers/updates' element={<CentersUpdates />} />

        <Route
          path='tv'
          element={
            <RequireAuth>
              <BionextTv />
            </RequireAuth>
          }
        />

        <Route path='patients'>
          <Route
            index
            element={
              <RequireAuth>
                <RequireRole doctor={<Patients />} bionext_admin={<Patients />} laboratory={<Patients />} clerk={<Patients />} />
              </RequireAuth>
            }
          />

          <Route
            path=':id'
            element={
              <RequireAuth>
                <RequireRole doctor={<PatientDetailsPage />} bionext_admin={<PatientDetailsPage />} laboratory={<PatientDetailsPage />} clerk={<PatientDetailsPage />} />
              </RequireAuth>
            }
          />
          <Route
            path=':id/crosstab'
            element={
              <RequireAuth>
                <RequireRole doctor={<PatientHistory />} bionext_admin={<PatientHistory />} laboratory={<PatientHistory />} clerk={<PatientHistory />} />
              </RequireAuth>
            }
          />
        </Route>
        <Route path='prescription/:idPrescription'>
          <Route
            index
            element={
              <RequireAuth>
                <RequireRole doctor={<PatientsPrescription />} clerk={<PatientsPrescription />} />
              </RequireAuth>
            }
          />
          <Route
            path='completion'
            element={
              <RequireAuth>
                <RecapCompletion />
              </RequireAuth>
            }
          />
          <Route
            path='validation'
            element={
              <RequireAuth>
                <RequireRole doctor={<PrescriptionValidation />} clerk={<PrescriptionValidation />} />
              </RequireAuth>
            }
          />
        </Route>
        <Route path='prescriptions'>
          <Route
            index
            element={
              <RequireAuth>
                <RequireRole doctor={<Prescriptions />} clerk={<Prescriptions />} patient={<Eprescriptions />} />
              </RequireAuth>
            }
          />
          <Route
            path=':id'
            element={
              <RequireAuth>
                <RequireRole patient={<EprescriptionsDetails />} />
              </RequireAuth>
            }
          />
          <Route
            path='profile/:id'
            element={
              <RequireAuth>
                <RequireRole doctor={<PrescriptionsProfilePage />} />
              </RequireAuth>
            }
          />
          <Route
            path='oms/:patientId'
            element={
              <RequireAuth>
                <RequireRole doctor={<PrescriptionsSelectOMS />} clerk={<PrescriptionsSelectOMS />} />
              </RequireAuth>
            }
          />
        </Route>
        <Route path='analysis' element={<Analysis />} />
        <Route path='myDSP'>
          <Route
            index
            element={
              <RequireAuth>
                <RequireRole patient={<MyDSP />} />
              </RequireAuth>
            }
          />

          <Route
            path=':uuid'
            element={
              <RequireAuth>
                <RequireRole patient={<DocumentDSPDetails />} />
              </RequireAuth>
            }
          />
        </Route>
        <Route path='404' element={<Error404 />} />
        <Route path='*' element={<Navigate to='404' />} />
      </Route>
      {/* @see #35 Allow compatibility with old URLs */}
      <Route
        path='patient/invoice/:id'
        element={
          <RequireAuth>
            <InvoiceDetails />
          </RequireAuth>
        }
      />
      {/* @see #55 Allow compatibility with token URLs (https://mylab.bionext.lu/r/UXK5FE) */}
      <Route path='r/:token' element={<Navigate to={`/${navigator.language.split('-').shift()}/`} />} />
    </Routes>
  );
};

export default CustomRoutes;
