import { Alert, Grid, SvgIcon } from '@mui/material';
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';
import { ReactComponent as InfoIcon } from 'resources/icons/info.svg';
import { RdvPatient } from 'models/appointment.model';
import { useNotification } from 'hooks';
import { MAX_SIZE_FILE } from 'utils/Constants';
import PatientPrescriptionCard from 'components/appointment/PatientPrescriptionCard';
import { PatientFiles } from 'components/appointment/types/Patient';

const PrescriptionsStep: FC<{
  handleNext: () => void;
  patientRdv: RdvPatient | null;
  setPrescriptionsFiles: Dispatch<SetStateAction<PatientFiles[]>>;
  prescriptionFiles: PatientFiles[];
  setNextButtonActive?: (v?: boolean) => void;
}> = ({ patientRdv, setNextButtonActive, prescriptionFiles, setPrescriptionsFiles }) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const { notification } = useNotification();
  useEffect(() => {
    if (setNextButtonActive && prescriptionFiles[0] && prescriptionFiles[0]?.files?.length > 0) {
      setNextButtonActive(true);
    }
  }, [prescriptionFiles]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, patientId: string | number) => {
    const file = event?.target?.files && event?.target?.files[0];

    if (!file || !(file.type === 'application/pdf' || file.type.startsWith('image/')) || file.size > MAX_SIZE_FILE) {
      notification(t('appointment.book.steps.prescriptions.notification.error.upload'), 'error');

      event.target.value = '';
      return;
    }

    const searchPatientFiles = (patientId: number | string, fileName: string) => {
      return prescriptionFiles.find((f) => f.patientId === patientId && f.files.find((ff) => ff.name === fileName));
    };
    const patientFiles = searchPatientFiles(patientId, file.name);
    if (patientFiles) {
      notification(t('appointment.book.steps.prescriptions.notification.error.exists', { replace: { prescription: file.name } }), 'error');

      event.target.value = '';
      return;
    }

    setPrescriptionsFiles((prev) => {
      const patientFiles = prev.find((f) => f.patientId === patientId);

      if (patientFiles) {
        return prev.map((f) => {
          if (f.patientId === patientId) {
            return {
              patientId,
              files: [...f.files, file],
            };
          }

          return f;
        });
      }

      return [...prev, { patientId, files: [file] }];
    });
  };

  const handleUploadFile = (index: number) => {
    const current = ref?.current;
    if (current) {
      current?.click();
    }
  };
  return (
    <Grid item xs={12} sm={10} md={8} sx={{ margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '8px' }}>
      <Alert severity='info' sx={{ backgroundColor: redesignColors.blue.blue3, fontSize: '14px', fontWeight: 500, borderRadius: '8px' }} icon={<SvgIcon component={InfoIcon} />}>
        {t('fertility.prescriptions.upload_prescriptions')}
        <br />
        {t('fertility.prescriptions.thank_you')}
      </Alert>
      {patientRdv && (
        <PatientPrescriptionCard
          patient={{
            id: (patientRdv.id as number) || '',
            firstName: patientRdv.firstname,
            lastName: patientRdv.lastname as string,
            email: patientRdv.email,
            phone: patientRdv.phone,
            gender: patientRdv.gender,
            birthDate: patientRdv.birthdate as Date,
            birthName: patientRdv.birthname,
            emailConfirm: patientRdv.email,
          }}
          files={prescriptionFiles}
          refr={ref}
          handleFileChange={handleFileChange}
          handleUploadFile={() => handleUploadFile(0)}
          setFiles={setPrescriptionsFiles}
        />
      )}
    </Grid>
  );
};

export default PrescriptionsStep;
