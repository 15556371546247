import { Box, Button, Card, CardContent, Divider, IconButton, SvgIcon, Typography } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { ReactComponent as PlusIcon } from 'resources/icons/plus.svg';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';
import { ReactComponent as DeleteIcon } from 'resources/icons/delete.svg';
import { AppointmentPatient, PatientFiles } from './types/Patient';
import ConfirmDialog from 'components/confirm-dialog';

const PatientPrescriptionCard: FC<{
  patient: AppointmentPatient;
  files: PatientFiles[];
  refr: React.RefObject<HTMLInputElement> | null;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>, patientId: string | number) => void;
  handleUploadFile: () => void;
  setFiles: (state: any) => void;
}> = ({ patient, files, refr, handleFileChange, handleUploadFile, setFiles }) => {
  const { t } = useTranslation();
  const [deletedFile, setDeletedFile] = useState<File | null>(null);

  return (
    <Card sx={{ width: '100%', mt: 2 }}>
      <CardContent sx={{ borderRadius: '8px' }}>
        <Typography variant='body1' fontWeight='700'>
          {patient.firstName} {patient.lastName}
        </Typography>
        <Divider sx={{ my: 2, width: '100%', border: `0.5px solid ${redesignColors.grey.grey4}` }} />
        {files.map(
          (patientFiles) =>
            patientFiles.patientId === patient.id &&
            patientFiles.files.map((file) => (
              <div key={file.name}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography fontWeight='500'>{file.name}</Typography>
                  <IconButton
                    onClick={() => {
                      setDeletedFile(file);
                    }}
                  >
                    <SvgIcon component={DeleteIcon} sx={{ color: redesignColors.red }} inheritViewBox fontSize='small'></SvgIcon>
                  </IconButton>
                </Box>
                <Divider sx={{ my: 2, width: '100%', border: `0.5px solid ${redesignColors.grey.grey4}` }} />
              </div>
            )),
        )}
        <input type='file' ref={refr} style={{ display: 'none' }} onChange={(event) => handleFileChange(event, patient.id)} />
        <Button sx={{ width: '100%' }} startIcon={<SvgIcon component={PlusIcon} />} variant='outlined' onClick={handleUploadFile}>
          {t('fertility.prescriptions.add_prescription')}
        </Button>
      </CardContent>
      {deletedFile && (
        <ConfirmDialog
          open
          title={t('new_appointment.delete_presctiption_dialog.title')}
          subtitle={t('new_appointment.delete_presctiption_dialog.subtitle')}
          submitTitle={t('common.action.delete')}
          onClose={() => {
            setDeletedFile(null);
          }}
          onSubmit={() => {
            setFiles((prev: PatientFiles[]) => {
              return prev.map((f) => {
                if (f.patientId === patient.id) {
                  return {
                    patientId: patient.id,
                    files: f.files.filter((ff) => ff.name !== deletedFile.name),
                  };
                }

                return f;
              });
            });
            setDeletedFile(null);
          }}
        />
      )}
    </Card>
  );
};

export default PatientPrescriptionCard;
