import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import api from 'api';
import { useCurrentUser, useIsMobileView, useNotification } from 'hooks';
import { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import EmojiRating from './EmojiRating';
import { redesignColors } from 'resources/theme/theme.colors';
import ConfirmDialog from './ConfirmDialog';
import { CreateFeedbackRequest, FeedbackCategory } from 'models/feedback.model';
import { convertDate } from 'utils/func/Date.func';
import { isBefore, isEqual } from 'date-fns';

type Props = {
  open: boolean;
  setOpen: (bool: boolean) => any;
};

type FeedbackForm = {
  category: FeedbackCategory;
  comment: string;
  appointmentId: string;
};

type FeedbackAppointment = {
  id: string;
  address: string;
  date: string;
};

const FeedbackDialog: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const currentUser = useCurrentUser();

  const [appointments, setAppointments] = useState<FeedbackAppointment[]>([]);

  const { notification } = useNotification();

  const [openDialog, setOpenDialog] = useState(false);
  const [rating, setRating] = useState<number>(4);
  const [readPrivacy, setReadPrivacy] = useState(false);
  const { register, handleSubmit, formState, watch } = useForm<FeedbackForm>({
    mode: 'onChange',
  });
  const { category, comment } = watch();

  const onSubmit: SubmitHandler<FeedbackForm> = useCallback(
    (formData) => {
      const datetime = convertDate(new Date(), false, 'yyyy-MM-dd HH:mm');
      const requestData: CreateFeedbackRequest = {
        rate: rating,
        category: formData.category,
        comment: formData.comment,
        datetime,
        center: 'Leudelange',
        contact: readPrivacy,
      };
      api.feedbacks
        .createFeedback(requestData)
        .then(() => {
          setOpenDialog(true);
        })
        .catch((error) => {
          notification(t('contact.notifications.error'), 'error');
        });
    },
    [notification, t, readPrivacy, rating],
  );

  const formatAddress = useCallback(
    (address: any) => {
      const type = address.type === 'home' ? t('appointment.list.table.address.home') : address.type === 'work' ? t('appointment.list.table.address.work') : t('appointment.list.table.address.other');
      const formattedAddress = [
        [address.number, address.street, address.zip_code].filter((elem) => elem !== null).join(', '),
        /* @ts-ignore */
        [address.city_name, t(`countries.${address.country_iso}`)].join(' '),
      ].join(' - ');
      return `(${type}) ${formattedAddress}`;
    },
    [t],
  );
  useEffect(() => {
    if (currentUser) {
      api.appointments
        .getAppointments(currentUser.id)
        .then((res: any) => {
          const appointments = res
            .filter((appointment: any) => isBefore(new Date(appointment.date_time_from), new Date()) || isEqual(new Date(appointment.date_time_from), new Date()))
            .map((appointment: any) => ({
              id: appointment.id,
              address: [
                [appointment.address.number, appointment.address.street, appointment.address.zip_code].filter((elem) => elem !== '').join(', '),
                [appointment.address.city_name].join(' '),
              ].join(' - '),
              date: convertDate(appointment?.date_time_from, false, 'dd/MM/yyyy HH:mm'),
            }));
          setAppointments(appointments);
        })
        .catch(() => notification(t('appointment.list.notification.listing.error'), 'error'));
    }
  }, [currentUser, notification, t]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        '& .MuiPaper-root': {
          minWidth: { xs: '85vw', md: '70vw', lg: '848px' },
          maxWidth: 'fit-content',
          borderRadius: '28px',
        },
      }}
    >
      <DialogTitle sx={{ position: 'relative' }}>
        <Box component='h2' sx={{ fontSize: '24px', fontWeight: '500', m: 0 }}>
          {t('feedback.title')}
        </Box>
        <Box sx={{ fontSize: '16px', fontWeight: '400', color: redesignColors.grey.grey1, mb: 1 }}>{t('feedback.description')}</Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'grid', gridTemplateRows: 'auto', gap: 2 }}>
            <Box sx={{ fontSize: '16px', fontWeight: '600', color: redesignColors.dark, textAlign: 'center' }}>{t('feedback.rating_title')}</Box>

            <EmojiRating rating={rating} setRating={setRating} />
            <FormControl variant='filled' fullWidth>
              <InputLabel id='category-label'>{t('feedback.form.category')}</InputLabel>
              <Select labelId='category-label' {...register('category', { required: true })} defaultValue={undefined} variant='filled' displayEmpty>
                <MenuItem value={FeedbackCategory.LastVisit}>{t('feedback.category.last_visit')}</MenuItem>
                <MenuItem value={FeedbackCategory.OtherContact}>{t('feedback.category.other_contact')}</MenuItem>
                <MenuItem value={FeedbackCategory.MylabApp}>{t('feedback.category.mylab_app')}</MenuItem>
                <MenuItem value={FeedbackCategory.Other}>{t('feedback.category.other')}</MenuItem>
              </Select>
            </FormControl>
            {category === FeedbackCategory.LastVisit && (
              <FormControl variant='filled' fullWidth>
                <InputLabel id='visit-label'>{t('feedback.form.visit')}</InputLabel>
                <Select
                  labelId='visit-label'
                  {...register('appointmentId', { required: true })}
                  defaultValue={undefined}
                  variant='filled'
                  displayEmpty
                  renderValue={(id) => {
                    const appointment = appointments.find((app) => app.id === id);
                    if (!appointment) {
                      return undefined;
                    }
                    return `${appointment.date} ${appointment.address}`;
                  }}
                >
                  {appointments.map((appointment) => (
                    <MenuItem value={appointment.id} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <Box>{appointment.date}</Box>
                      <Box>{appointment.address}</Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              variant='filled'
              multiline
              {...register('comment', { required: true })}
              defaultValue={undefined}
              placeholder={t('feedback.form.experience')}
              fullWidth
              sx={{
                '&>.MuiFilledInput-root': {
                  height: '100%',
                  '&>.MuiFilledInput-input': {
                    height: '100% !important',
                    overflow: 'auto !important',
                  },
                },
              }}
            />
            <FormControlLabel
              control={<Checkbox value={readPrivacy} onChange={(_, checked) => setReadPrivacy(checked)} sx={{ mx: 1 }} />}
              label={<Typography display='flex'>{t('feedback.form.contact_me')}</Typography>}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton variant='text' fullWidth={isMobileView} loading={formState.isSubmitting} sx={{ mr: 3 }} onClick={() => setOpen(false)}>
                {t('feedback.form.cancel')}
              </LoadingButton>
              <LoadingButton variant='contained' fullWidth={isMobileView} type='submit' loading={formState.isSubmitting} disabled={!category || !comment}>
                {t('feedback.form.submit')}
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
      <ConfirmDialog open={openDialog} setOpen={setOpen} />
    </Dialog>
  );
};
export default FeedbackDialog;
