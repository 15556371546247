import { Alert, Button, Box, Card, CardContent, CardHeader, Dialog, DialogContent, Grid, TextField, Tooltip, Typography, Switch } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import AddressForm from 'components/address-form';
import PhoneInput from 'components/phone-input';
import { useContextRedirection, useIsRelative, useNotification } from 'hooks';
import { Address, Person, PersonUpdate } from 'models/profile.model';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { fetchMe } from 'store/actions/session.actions';
import { convertDate } from 'utils/func/Date.func';
import RoutePaths from 'utils/RoutePaths';
import DeleteAccountModal from '../DeleteAccountModal';
import EmailUpdate from '../email';
import PasswordUpdate from '../password';
import { useTranslation } from 'react-i18next';
import ContextLink from 'components/_base/ContextLink';

const dialogSx = {
  '& .MuiPaper-root': {
    minWidth: { xs: '300px', sm: '440px' },
    maxWidth: 'fit-content',
  },
};
const UpdateProfile: FC = () => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const dispatch = useDispatch();
  const isRelative = useIsRelative();
  const navigate = useContextRedirection();
  const { register, reset, setValue, handleSubmit, formState, control } = useForm<PersonUpdate>();
  const [userData, setUserData] = useState<Person>();
  const [modalUpdatePassword, openModalUpdatePassword] = useState(false);
  const [modalUpdateEmail, openModalUpdateEmail] = useState(false);
  const [modalDeleteAccount, openModalDeleteAccount] = useState(false);
  const [workAdr, setWorkAdr] = useState<Address>();
  const [homeAdr, setHomeAdr] = useState<Address>();
  const [hasWorkAdr, setHasWorkAdr] = useState(true);
  const [deleteWorkAdr, setDeleteWorkAdr] = useState(false);
  const formData: PersonUpdate | undefined = useMemo(() => {
    if (userData && userData !== null) {
      let work = userData.addresses.work;
      let home = userData.addresses.home;
      const updatedWorkAdr = {
        city: work ? work.city ?? '' : '',
        company: work ? work.company ?? '' : '',
        complement: work ? work.complement ?? '' : '',
        country: work ? work.country ?? '' : '',
        number: work ? work.number ?? '' : '',
        country_iso: work ? work.country_iso ?? '' : '',
        postal_code: work ? work.postal_code ?? '' : '',
        street: work ? work.street ?? '' : '',
        zip_code: work ? work.zip_code ?? '' : '',
      };
      setWorkAdr(updatedWorkAdr);
      const allFieldsAreEmpty = Object.values(updatedWorkAdr).every((val) => val === '');
      setHasWorkAdr(work ? !allFieldsAreEmpty : false);
      setHomeAdr({
        city: home ? home.city ?? '' : '',
        company: home ? home.company ?? '' : '',
        complement: home ? home.complement ?? '' : '',
        country: home ? home.country ?? '' : '',
        number: home ? home.number ?? '' : '',
        country_iso: home ? home.country_iso ?? '' : '',
        postal_code: home ? home.postal_code ?? '' : '',
        street: home ? home.street ?? '' : '',
        zip_code: home ? home.zip_code ?? '' : '',
      });
      return {
        first_name: userData.first_name ?? '',
        last_name: userData.last_name ?? '',
        birth_name: userData.birth_name ?? '',
        birth_date: convertDate(userData.birth_date, false, 'yyyy-MM-dd') ?? '',
        gender: userData.gender === 'm' ? t('profile.data.male') : t('profile.data.female'),
        cns: userData.cns ?? '',
        email: userData.username ?? '',
        mobile_phone: userData.mobile_phone ?? '',
        phone: userData.phone ?? '',
        fax: userData.fax ?? '',

        addresses: {
          work: workAdr,
          home: homeAdr,
        },
      };
    }
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    if (isRelative) {
      navigate(RoutePaths['PROFILE']);
    }
  }, [isRelative, navigate]);

  useEffect(() => {
    api.users
      .getMe()
      .then((response) => setUserData(response))
      .catch(() => notification(t('profile.notification.error'), 'error'));
  }, [notification, t]);

  useEffect(() => reset(formData), [formData, reset]);

  const onSubmit: SubmitHandler<PersonUpdate> = useCallback(
    (data) => {
      data.birth_date = userData?.birth_date ?? '';
      if (data.addresses!.home === undefined) {
        data.addresses!.home = userData?.addresses.home;
      }
      if (data.addresses!.work === undefined) {
        data.addresses!.work = userData?.addresses.work;
      }
      if (deleteWorkAdr) {
        data.addresses!.work!.city = null;
        data.addresses!.work!.company = null;
        data.addresses!.work!.complement = null;
        data.addresses!.work!.country = null;
        data.addresses!.work!.number = null;
        data.addresses!.work!.country_iso = null;
        data.addresses!.work!.postal_code = null;
        data.addresses!.work!.street = null;
        data.addresses!.work!.zip_code = null;
      }
      api.users
        .updateAccount(data)
        .then(() => {
          dispatch(fetchMe());
          notification(t('profile.update.notifications.success'), 'success');
          navigate(RoutePaths['PROFILE']);
        })
        .catch(() => {
          notification(t('profile.update.notifications.error'), 'error');
        });
    },
    [dispatch, navigate, notification, t, userData, deleteWorkAdr],
  );
  return (
    <BodyTemplate title={t('profile.update.title')} backButton>
      {isRelative && (
        <Alert variant='filled' severity='warning' sx={{ mb: 2 }}>
          {t('profile.update.impossibleToUpdate')}
        </Alert>
      )}
      <Card sx={{ mb: 3 }}>
        <CardHeader sx={{ mb: 3 }} title={<Typography variant='h2'>{t('profile.update.subtitles.credentials')}</Typography>} />
        <CardContent>
          {!isRelative && (
            <>
              <Button sx={{ mr: 1 }} variant='outlined' onClick={() => openModalUpdateEmail(true)}>
                {t('profile.update.actions.modify.email')}
              </Button>
              <Button variant='outlined' sx={{ mr: 1 }} onClick={() => openModalUpdatePassword(true)}>
                {t('profile.update.actions.modify.password')}
              </Button>
            </>
          )}
          {/* TODO: Remove comments when possible to delete an user */}
          {/* <Button
            variant="danger"
            sx={{ mr: 1 }}
            onClick={() => openModalDeleteAccount(true)}
          >
            {t('profile.update.actions.delete')}
          </Button> */}
          <Dialog sx={dialogSx} open={modalUpdateEmail} onClose={() => openModalUpdateEmail(false)} fullWidth>
            <DialogContent>
              <EmailUpdate closeModal={() => openModalUpdateEmail(false)} />
            </DialogContent>
          </Dialog>
          <Dialog sx={dialogSx} open={modalUpdatePassword} onClose={() => openModalUpdatePassword(false)} fullWidth>
            <DialogContent>
              <PasswordUpdate closeModal={() => openModalUpdatePassword(false)} />
            </DialogContent>
          </Dialog>
          <Dialog open={modalDeleteAccount} onClose={() => openModalDeleteAccount(false)} fullWidth>
            <DialogContent>
              <DeleteAccountModal />
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>

      <form onSubmit={handleSubmit(onSubmit)}>
        {userData && (
          <>
            <Card sx={{ mb: 3 }}>
              <CardHeader title={<Typography variant='h2'>{t('profile.update.subtitles.personalInfo')}</Typography>} sx={{ mb: 3 }} />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} md={4}>
                      <TextField variant='filled' disabled fullWidth {...register('first_name')} label={t('profile.data.first_name')} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField variant='filled' disabled fullWidth {...register('last_name')} label={t('profile.data.last_name')} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField variant='filled' disabled fullWidth {...register('birth_name')} label={t('profile.data.birth_name')} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField variant='filled' disabled fullWidth {...register('gender')} label={t('profile.data.gender')} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField variant='filled' disabled fullWidth {...register('birth_date')} label={t('profile.data.birth_date')} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField variant='filled' disabled fullWidth {...register('cns')} label={t('profile.data.cns')} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField variant='filled' disabled fullWidth {...register('email')} label={t('profile.data.email')} />
                    </Grid>
                    <Grid item xs={12} md={8} display={{ sx: 'none' }} />
                    <Grid item xs={12} md={4}>
                      <PhoneInput
                        disabled={isRelative}
                        autoFormat
                        onChange={(e) => {
                          setValue('phone', e.toString(), { shouldDirty: true, shouldValidate: true });
                        }}
                        label={t('profile.data.phone_two')}
                        value={userData.phone}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <PhoneInput
                        disabled={isRelative}
                        autoFormat
                        onChange={(e) => {
                          setValue('mobile_phone', e.toString(), { shouldDirty: true, shouldValidate: true });
                        }}
                        label={t('profile.data.mobile_phone')}
                        value={userData.mobile_phone}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardHeader title={<Typography variant='h2'>{t('profile.update.subtitles.homeAddress')}</Typography>} sx={{ mb: 3 }} />
              <CardContent>
                <Grid container>
                  <AddressForm value={homeAdr} isWork={false} labelOnAdress={false} register={register} control={control} formState={formState} />{' '}
                </Grid>
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardHeader
                title={
                  <Box display='flex' alignItems='center'>
                    <Typography variant='h2' mr={2}>
                      {t('profile.update.subtitles.workAddress')}
                    </Typography>

                    <Switch
                      defaultChecked={hasWorkAdr}
                      onChange={(_, changed) => {
                        if (changed) {
                          setHasWorkAdr(true);
                          setDeleteWorkAdr(false);
                          return;
                        }
                        setHasWorkAdr(false);
                        setDeleteWorkAdr(true);
                      }}
                    />
                  </Box>
                }
                sx={{ mb: hasWorkAdr ? 2 : 0 }}
              />
              <CardContent>
                {hasWorkAdr && (
                  <Grid container>
                    <Grid item display='flex' xs={12} justifyContent='flex-end'></Grid>
                    <AddressForm isWork value={workAdr} labelOnAdress={false} register={register} control={control} formState={formState} />
                  </Grid>
                )}
              </CardContent>
            </Card>
            <Grid item xs={12} display='flex'>
              <Tooltip title={t('profile.update.error')} disableHoverListener={!isRelative}>
                <span>
                  <Button type='submit' sx={{ height: 42, mr: 2 }} variant='contained' disabled={isRelative || !formState.isDirty}>
                    {t('profile.update.actions.save_changes')}
                  </Button>
                </span>
              </Tooltip>
              <ContextLink to='PROFILE'>
                <Button variant='outlined'>{t('common.action.cancel')}</Button>
              </ContextLink>
            </Grid>
          </>
        )}
      </form>
    </BodyTemplate>
  );
};

export default UpdateProfile;
