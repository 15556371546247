// import { parsePhoneNumberFromString } from 'libphonenumber-js'
import MuiPhoneNumber from 'material-ui-phone-number';
import { ChangeEvent, FC } from 'react';
// import { isGSMNumber } from 'utils/func/Phone.func'

type PhoneInputType = {
  disabled?: boolean;
  onChange: (e: ChangeEvent | string) => void;
  label: string;
  value?: string;
  required?: boolean;
  helperText?: string;
  autoFormat?: boolean;
  error?: boolean;
  sx?: any;
};

const PhoneInput: FC<PhoneInputType> = (props) => {
  return (
    <MuiPhoneNumber
      defaultCountry={'lu'}
      preferredCountries={['lu', 'fr', 'be', 'de', 'pt', 'es', 'nl', 'uk', 'it']}
      disabled={props.disabled}
      autoFormat={false}
      fullWidth
      countryCodeEditable={false}
      variant='filled'
      {...props}
      disableDropdown={props.disabled}
    />
  );
};

export default PhoneInput;
