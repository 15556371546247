import { Geolocation } from 'hooks/useGeolocation';

export enum GeolocationActionType {
  ADD = 'GEOLOCATION_ADD',
  DELETE = 'GEOLOCATION_DELETE',
}

export type GeolocationAction = { type: GeolocationActionType.ADD; geolocation: Geolocation } | { type: GeolocationActionType.DELETE; geolocation: null };

export const addGeolocation = (geolocation: Geolocation): GeolocationAction => ({
  type: GeolocationActionType.ADD,
  geolocation,
});

export const removeGeolocation = (geolocation: null): GeolocationAction => ({
  type: GeolocationActionType.DELETE,
  geolocation,
});
