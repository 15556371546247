import { Button, Divider, Grid, IconButton, SvgIcon, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import GenericTable, { GenericPaginator } from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import TableContentSkeleton from 'components/table-content-skeleton';
import ContextLink from 'components/_base/ContextLink';
import { useIsMobileView } from 'hooks';
import { InvoicesResult, InvoiceStatus } from 'models/invoices.model';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconNext } from 'resources/icons/right_arrow.svg';
import { formatToLocaleNumber } from 'utils/func/Number.func';
import { redesignColors } from 'resources/theme/theme.colors';

interface InvoicesTableProps {
  toPayTab: boolean;
  invoices?: InvoicesResult[];
  pagination: GenericPaginator;
  onPaginationChange: (pagination: GenericPaginator) => void;
  isLoading: boolean;
}

enum TableSort {
  desc = 'desc',
  asc = 'asc',
}
const filterInvoicesByStatus = (invoices: InvoicesResult[], isToPayTab: boolean) => {
  const targetStatus = isToPayTab ? InvoiceStatus.OPEN : InvoiceStatus.PAID;
  return invoices.filter((invoice) => invoice.status === targetStatus);
};

const sortInvoicesByDate = (invoices: InvoicesResult[], sortDirection: TableSort) => {
  return invoices.sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return sortDirection === TableSort.desc ? dateA - dateB : dateB - dateA;
  });
};

const InvoicesTable: FC<InvoicesTableProps> = ({ toPayTab, invoices, pagination, onPaginationChange, isLoading }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const [sortByDate, setSortByDate] = useState<TableSort>(TableSort.desc);

  const tableHeaders: GenericHeader[] = useMemo(
    () => [
      { id: 'date', label: toPayTab ? 'invoices.table.date' : 'invoices.table.paid_on', sort: () => setSortByDate((prev) => (prev === TableSort.desc ? TableSort.asc : TableSort.desc)) },
      { id: 'reference', label: 'invoices.table.reference' },
      {
        id: 'amount',
        label: 'invoices.table.amount_to_pay',
        alignRight: true,
      },
      { id: 'actions', label: undefined },
    ],
    [toPayTab, sortByDate],
  );
  const cellSx = { borderBottom: `1px solid ${redesignColors.grey.grey4}` };

  return (
    <GenericTable headers={tableHeaders} pagination={pagination} setPagination={onPaginationChange}>
      {!isLoading && invoices ? (
        invoices.length ? (
          sortInvoicesByDate(filterInvoicesByStatus(invoices, toPayTab), sortByDate).map((invoice) =>
            isMobileView ? (
              <TableRow hover key={`${invoice.id}-mobile`} sx={{ border: '0px !important' }}>
                <TableCell sx={cellSx}>
                  <Grid container spacing={0} direction='row' alignItems='center' justifyContent='space-between'>
                    <Grid item>
                      <Grid container direction='column' spacing={0}>
                        <Grid item xs>
                          <Grid container direction='row' spacing={1}>
                            <Grid item>
                              <Divider orientation='vertical' sx={{ borderColor: (theme) => theme.palette.text.primary }} />
                            </Grid>
                            <Grid item xs>
                              <Typography variant='body1'>{invoice.dossier_reference}</Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography variant='body1'>{invoice.date}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <ContextLink to='INVOICE_DETAILS' params={{ id: +invoice.dossier_reference }}>
                        <IconButton>
                          <SvgIcon component={IconNext} sx={{ fontSize: 16, fill: redesignColors.blue.dark }} />
                        </IconButton>
                      </ContextLink>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow hover key={invoice.id} sx={{ border: '0px !important' }}>
                <TableCell sx={cellSx}>
                  <Typography variant='body1'>{invoice.date}</Typography>
                </TableCell>
                <TableCell sx={cellSx}>
                  <Typography variant='body1'>{invoice.reference}</Typography>
                </TableCell>
                <TableCell sx={cellSx}>
                  <Typography variant='body1' align='right' fontWeight='700'>
                    {`${formatToLocaleNumber(invoice.amount, true)} ${invoice.amount ? invoice.currency : ''}`}
                  </Typography>
                </TableCell>
                <TableCell sx={{ ...cellSx, display: 'flex', justifyContent: 'flex-end' }}>
                  <ContextLink to='INVOICE_DETAILS' params={{ id: +invoice.dossier_reference }}>
                    <Button variant='text' endIcon={<SvgIcon component={IconNext} sx={{ fontSize: 24, color: `${redesignColors.blue.dark} !important` }} />}>
                      {t('invoices.table.details')}
                    </Button>
                  </ContextLink>
                </TableCell>
              </TableRow>
            ),
          )
        ) : (
          <NoResultsTableRow colSpan={tableHeaders.length} noResultText={'common.noResult'} />
        )
      ) : (
        <TableContentSkeleton columns={tableHeaders.length} rows={4} />
      )}
    </GenericTable>
  );
};

export default InvoicesTable;
