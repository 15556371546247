import { Grid, SvgIcon, Typography } from '@mui/material';
import { FC } from 'react';

interface CardHomepageServicesProps {
  logo?: any;
  title: string;
  description?: string;
  descriptionOnMobile?: boolean;
  isLargeScreen?: boolean;
  isLandingPage?: boolean;
  image?: string;
  bigFont?: boolean;
}

const CardHomepageServices: FC<CardHomepageServicesProps> = ({ logo, image, title, description, isLargeScreen = false, isLandingPage = false, bigFont = false }) => {
  const Icon = () => {
    if (image) {
      return <img src={image} style={{ width: '96px', height: '96px' }} />;
    }
    return isLandingPage ? <SvgIcon htmlColor={'black'} component={logo} sx={{ fontSize: 96 }} /> : <SvgIcon component={logo} sx={{ fontSize: 96 }} />;
  };
  return (
    <Grid
      container
      spacing={0}
      flexWrap='nowrap'
      alignItems='left'
      flexDirection={!isLargeScreen ? { xs: 'column', md: 'initial' } : {}}
      sx={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Grid
        item
        sx={
          isLandingPage
            ? {
                display: 'flex',
                alignSelf: 'center',
              }
            : {
                display: 'flex',
                alignSelf: 'center',
                marginBottom: 1,
              }
        }
      >
        <Icon />
      </Grid>
      <Grid
        item
        textAlign={!isLargeScreen ? (!isLandingPage ? 'center' : 'initial') : 'initial'}
        my={{ xs: '8px', md: '0' }}
        sx={{
          ...(isLandingPage && {
            width: '120%',
          }),
        }}
      >
        <Typography
          variant='body2'
          sx={{
            textAlign: { xs: 'center' },
            fontWeight: 'medium',
            fontSize: bigFont ? 20 : 16,
            mb: '4px',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant='body2'
          display={isLargeScreen ? { xs: 'none', md: 'initial' } : ''}
          sx={{
            whiteSpace: 'pre-wrap',
            textAlign: 'left',
            fontSize: bigFont ? 16 : 1,
          }}
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CardHomepageServices;
