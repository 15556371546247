import { BadgesCountPatient, EmailUpdateProps, PasswordUpdateProps, Person, PersonUpdate } from 'models/profile.model';
import { AddRelativeRequest, ValidateEmailTokenRequest } from 'models/user.model';
import { APIConfiguration, axiosMYLAB } from '.';
import { BaseService } from './base.service';
import { normalizePhoneNumber } from 'utils/func/Phone.func';

class UsersService extends BaseService {
  async getMe() {
    const { data } = await axiosMYLAB.get<Person>(`${this.apiConfig.ME.GET(this.getLang())}`);

    this.log('getMe()');

    return {
      ...data,
      mobile_phone: normalizePhoneNumber(data.mobile_phone),
    };
  }

  async getBadgesCount(id: number) {
    const { data } = await axiosMYLAB.get<BadgesCountPatient>(`${this.apiConfig.ME.GET_PATIENT_DASHBOARD(this.getLang())}`, {
      params: {
        patient_id: id,
      },
    });

    this.log('getBadgesCount()');

    return data;
  }

  async updateAccount(data: PersonUpdate) {
    this.log('updateAccount()');

    return await axiosMYLAB.put(`${this.apiConfig.ME.UPDATE(this.getLang())}`, {
      phone: data.phone,
      mobile_phone: data.mobile_phone && Number.parseInt(data.mobile_phone.replace(/\s+/g, '')),
      home: data.addresses?.home,
      work: data.addresses?.work,
    });
  }

  async addRelationWithToken(token: string, birth_date: string) {
    this.log('addRelationWithToken()');

    return axiosMYLAB.post(this.apiConfig.RELATIVES.ADD_RELATION_WITH_TOKEN(this.getLang()), {
      token,
      birth_date,
    });
  }

  async addRelation(body: AddRelativeRequest) {
    this.log('addRelation()');

    return axiosMYLAB.post(this.apiConfig.RELATIVES.ADD_RELATION(this.getLang()), {
      ...body,
    });
  }

  async getGrantorsAndGrantees() {
    const { data } = await axiosMYLAB.get(`${this.apiConfig.RELATIVES.GET(this.getLang())}`);

    this.log('getGrantorsAndGrantees()');

    return data.map((item: any) => {
      item.grantee.mobile_phone = normalizePhoneNumber(item.grantee.mobile_phone);
      item.grantor.mobile_phone = normalizePhoneNumber(item.grantor.mobile_phone);
      return item;
    });
  }

  async updateEmail(updateData: EmailUpdateProps) {
    const { data } = await axiosMYLAB.put(`${this.apiConfig.ME.UPDATE_EMAIL(this.getLang())}`, {
      username: updateData.email,
    });

    this.log('updateEmail()');

    return data;
  }

  async updatePassword(updateData: PasswordUpdateProps) {
    const { data } = await axiosMYLAB.put(`${this.apiConfig.ME.UPDATE_PASSWORD(this.getLang())}`, {
      password: {
        first: updateData.password,
        second: updateData.password_confirm,
      },
    });

    this.log('updatePassword()');

    return data;
  }

  async getAllOfficeMedicalStaffByUser() {
    const { data } = await axiosMYLAB.get(this.apiConfig.ME.GET_ALL_OFFICES(this.getLang()));

    this.log(`getAllOfficeMedicalStaffByUser()`);

    return data;
  }

  async getRecordStats() {
    const { data } = await axiosMYLAB.get<{
      tagged: number;
      abnormal: number;
      urgent: number;
    }>(this.apiConfig.ME.GET_DOCTOR_DASHBOARD(this.getLang()));

    this.log('getRecordStats()');

    return data;
  }

  async validateEmailToken(postData: ValidateEmailTokenRequest) {
    const data = await axiosMYLAB.post(this.apiConfig.PUBLIC.EMAIL_TOKEN_VALIDATION(this.getLang()), {
      token: postData.token,
    });

    this.log('validateEmailToken');

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new UsersService(apiConfig);
};
