import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Modal, Stack, SvgIcon, TextField } from '@mui/material';
import { FC, useCallback, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { useContextRedirection, useNotification } from 'hooks';
import { AuthenticationRequest } from 'models/user.model';
import { tryCatch } from 'ramda';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { loginUser } from 'store/actions/session.actions';
import { ValuesTypeExtractor } from 'utils';
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { ReactComponent as IconEmail } from 'resources/icons/messagerie.svg';
import { LoadingButton } from '@mui/lab';

interface LoginDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}

const LoginDialog: FC<LoginDialogProps> = ({ isOpen, handleClose }) => {
  const { t } = useTranslation();

  const [showPassword, toggleShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    toggleShowPassword(!showPassword);
  };

  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const navigate = useContextRedirection();
  const dispatch = useDispatch();
  const { notification } = useNotification();
  const location = useLocation();

  const { register, handleSubmit } = useForm<AuthenticationRequest>();

  const onSubmit: SubmitHandler<AuthenticationRequest> = useCallback(
    async (data) => {
      setSubmitting(true);
      const { email, password } = data;

      try {
        await api.authentication.authenticate({
          username: email,
          password: password,
        });

        dispatch(loginUser());
        navigate('/appointment');
      } catch (err) {
        notification(t('notifications.authentification.failedPassword'), 'error');
      } finally {
        setSubmitting(false);
      }
    },
    [navigate, dispatch, notification, t],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '28px',
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <DialogTitle
          sx={{
            fontSize: 24,
          }}
        >
          {t('new_appointment.modal.login.title')}
        </DialogTitle>
        <DialogContent>
          <Stack
            direction='column'
            spacing={3}
            sx={{
              minWidth: '100%',
              minHeight: '100%',
              justifyContent: 'center',
              marginTop: '0px',
            }}
          >
            <Stack direction='column' spacing={2}>
              <TextField
                variant='filled'
                sx={{ backgroundColor: '#FFFFFF', padding: 0 }}
                {...register('email')}
                name={'email'}
                type={'email'}
                hiddenLabel
                fullWidth
                required
                placeholder={t('new_appointment.modal.login.email')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SvgIcon component={IconEmail} sx={{ fontSize: 24 }} />
                    </InputAdornment>
                  ),
                  autoComplete: 'email',
                  inputProps: {
                    style: {
                      padding: '12px 12px 12px 0px',
                    },
                  },
                }}
              />
              <Stack direction='column' spacing={1} alignItems='end'>
                <TextField
                  variant='filled'
                  sx={{
                    backgroundColor: '#FFFFFF',
                  }}
                  {...register('password')}
                  name={'password'}
                  type={showPassword ? 'text' : 'password'}
                  hiddenLabel
                  fullWidth
                  required
                  placeholder={t('new_appointment.modal.login.password')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SvgIcon component={Lock} sx={{ fontSize: 24 }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={handleClickShowPassword} edge='end'>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    inputProps: {
                      style: {
                        padding: '12px 12px 12px 0px',
                      },
                    },
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ marginBottom: '24px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton type='submit' loading={isSubmitting}>
            Log in
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LoginDialog;
