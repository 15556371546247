import { FC } from 'react';

export interface AppointmentStep {
  label: string;
  type: AppointmentStepType;
  component: FC<any>;
  wrapStep: boolean;
  subSteps?: string[];
  props?: { isCheckin?: boolean; isNewAppointment?: boolean };
  hideStepper?: boolean;
}

export enum AppointmentStepType {
  address = 'address',
  introduction = 'introduction',
  information = 'information',
  availableSlot = 'available_slot',
  prescriptions = 'prescriptions',
  summary = 'summary',
}
