import { Box, Button, Card, CardContent, Checkbox, Dialog, FormControlLabel, FormGroup, Grid, SvgIcon, TextField, Typography } from '@mui/material';
import PhoneInput from 'components/phone-input';
import { useIsMobileView, useNotification } from 'hooks';
import { Gender } from 'models/profile.model';
import { AddRelativeRequest } from 'models/user.model';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { ReactComponent as FemaleIcon } from 'resources/icons/female.svg';
import { ReactComponent as MaleIcon } from 'resources/icons/male.svg';
import { ReactComponent as PlusSquareIcon } from 'resources/icons/plus_square.svg';
import { ReactComponent as DeleteIcon } from 'resources/icons/delete.svg';

import { convertDate } from 'utils/func/Date.func';
import { blobToBase64 } from 'utils/func/File.func';
import { sexes } from 'models/patients.model';
import { isManualValidDate } from 'utils/func/Date.func';
import api from 'api';
import AddRelativeConfirmationDialog from './AddRelativeConfirmation.dialog';
import GenericDatePicker from 'components/date-picker';
import Files from './relativesFormFiles';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';
import { useNavigate } from 'react-router-dom';

const AddRelativeForm: FC = () => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const navigate = useNavigate();
  const addRelativeDefaultValue = {
    first_name: '',
    last_name: '',
    gender: Gender.FEMALE_FULL,
    cns: '',
    birth_date: new Date(),
    email: '',
    mobile_phone: '',
    files: [{}],
  };
  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false);
  const { register, control, handleSubmit, setValue, getValues, formState, watch } = useForm({
    defaultValues: {
      minor: false,
      agree: false,
      addRelative: [addRelativeDefaultValue],
    },
  });
  const { notification } = useNotification();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addRelative',
  });
  const { addRelative } = watch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      const request: AddRelativeRequest = {
        minor: data.minor,
        persons: data.addRelative.map((addRelative: any) => ({
          gender: addRelative.gender,
          first_name: addRelative.first_name,
          last_name: addRelative.last_name,
          birth_date: convertDate(addRelative.birth_date, false, 'yyyy-MM-dd'),
          cns: addRelative.cns,
          email: addRelative.email,
          mobile_phone: addRelative.mobile_phone,
          files: addRelative.files.filter((file: File | undefined): file is File => file?.name !== undefined).map(async (file: File) => await blobToBase64(file)),
        })),
      };
      api.users
        .addRelation(request)
        .then(() => {
          setConfirmationDialogOpened(true);
        })
        .catch(() => {
          notification(t('profile.relatives.add.form.notification.error'), 'error');
        });
    },
    [notification, t],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card sx={{ width: '100%' }}>
        <CardContent sx={{ mb: 4 }}>
          <Typography>{t('profile.relatives.add.desc_fulfill_form')}</Typography>
        </CardContent>
        <Grid container spacing={0}>
          {fields.map((field, index) => {
            return (
              <Box display='flex' key={field.id}>
                <Grid item xs={12} container spacing={1}>
                  <Grid item xs={12}>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                      <Typography variant='body1' fontWeight='700' mb={2}>
                        {t('profile.relatives.add.form.relativeInfoTitle', {
                          replace: { index: index + 1 },
                        })}
                      </Typography>
                      {index !== 0 && (
                        <Button
                          variant='text'
                          color='warning'
                          sx={{ color: redesignColors.red, textDecoration: 'none !important' }}
                          startIcon={<SvgIcon component={DeleteIcon} inheritViewBox fontSize='small' sx={{ color: `${redesignColors.red} !important` }}></SvgIcon>}
                          onClick={() => {
                            if (fields.length > 1) {
                              remove(index);
                            }
                          }}
                        >
                          {t('profile.relatives.add.form.delete_relation')}
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {[sexes[1], sexes[0]].map(({ label, value }, i) => {
                      const isSelected = addRelative[index]?.gender === value;
                      return (
                        <Button
                          startIcon={
                            <SvgIcon
                              component={value === Gender.FEMALE_FULL ? FemaleIcon : MaleIcon}
                              sx={{ fontSize: { xs: 16, sm: 24 }, color: !isSelected ? redesignColors.blue.main : redesignColors.dark }}
                            />
                          }
                          variant={isSelected ? 'contained' : 'outlined'}
                          sx={{ height: 44, mr: i === 0 ? 2 : 0, mb: 2 }}
                          onClick={() => setValue(`addRelative.${index}.gender`, value, { shouldDirty: true, shouldValidate: true })}
                        >
                          {t(label)}
                        </Button>
                      );
                    })}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label={t('profile.relatives.add.form.first_name')}
                      fullWidth
                      required
                      variant='filled'
                      {...register(`addRelative.${index}.first_name`, {
                        required: true,
                      })}
                      error={formState.errors?.addRelative?.[index]?.first_name !== undefined}
                      helperText={formState.errors?.addRelative?.[index]?.first_name ? t('preregistration.myData.form.error.invalidFirstName') : null}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label={t('profile.relatives.add.form.last_name')}
                      fullWidth
                      required
                      variant='filled'
                      {...register(`addRelative.${index}.last_name`, {
                        required: true,
                      })}
                      error={formState.errors?.addRelative?.[index]?.last_name !== undefined}
                      helperText={formState.errors?.addRelative?.[index]?.last_name ? t('preregistration.myData.form.error.invalidBirthname') : null}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} display={{ xs: 'none', sm: 'block' }} />
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField label={t('profile.relatives.add.form.cns')} fullWidth variant='filled' {...register(`addRelative.${index}.cns`)} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <GenericDatePicker
                      name={`addRelative.${index}.birth_date`}
                      control={control}
                      onValidate={() => isManualValidDate(getValues(`addRelative.${index}.birth_date`))}
                      required
                      dataFormat='ISO'
                      error={formState.errors?.addRelative?.[index]?.birth_date !== undefined}
                      helperText={t('preregistration.myData.form.helperText.birth_date')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} display={{ xs: 'none', sm: 'block' }} />
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField label={t('profile.relatives.add.form.email')} type='email' required fullWidth variant='filled' {...register(`addRelative.${index}.email`, { required: true })} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <PhoneInput
                      required
                      autoFormat
                      onChange={(e) => {
                        setValue(`addRelative.${index}.mobile_phone`, e.toString());
                      }}
                      label={t('profile.relatives.add.form.mobile_phone')}
                    />
                  </Grid>
                  <Files index={index} control={control} register={register} formState={formState} setValue={setValue} />
                </Grid>
              </Box>
            );
          })}
          <Grid item xs={12}>
            <Button sx={{ ml: -1 }} startIcon={<SvgIcon component={PlusSquareIcon} inheritViewBox></SvgIcon>} onClick={() => append(addRelativeDefaultValue)}>{`${t(
              'profile.relatives.add.form.title',
            )}`}</Button>

            <FormGroup>
              <FormControlLabel label={t('profile.relatives.add.form.checkbox.minor')} control={<Checkbox {...register('minor')} sx={{ pr: 2 }} />} />
              <FormControlLabel
                label={t('profile.relatives.add.form.checkbox.agreement')}
                sx={{ mb: 1 }}
                control={
                  <Checkbox
                    required
                    {...register('agree', {
                      required: true,
                    })}
                    sx={{ pr: 2 }}
                  />
                }
              />
              {formState.errors?.agree !== undefined && (
                <Typography variant='body2' color='error'>
                  {t('profile.error.required')}
                </Typography>
              )}
            </FormGroup>
          </Grid>
        </Grid>

        <Dialog open={confirmationDialogOpened}>
          <AddRelativeConfirmationDialog onClose={() => setConfirmationDialogOpened(false)} />
        </Dialog>
      </Card>
      <Grid item xs={12} mt={3}>
        <Button sx={{ width: isMobileView ? '100%' : 160, mr: isMobileView ? 0 : 2, mb: isMobileView ? 2 : 0 }} variant='contained' type='submit' disabled={!formState.isDirty}>
          {t('common.action.submit')}
        </Button>
        <Button sx={{ width: isMobileView ? '100%' : 160 }} variant='outlined' fullWidth={isMobileView} type='submit' onClick={() => navigate(-1)}>
          {t('common.action.cancel')}
        </Button>
      </Grid>
    </form>
  );
};
export default AddRelativeForm;
